import React, { useState, useEffect } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css';
import { NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux'
// import { logout, reset } from '../../features/auth/authSlice';
import { Person } from '@mui/icons-material/';
import '../Settings/Set.css'


// Material Design Dashoboard Menu
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Storage from '../../utils/storage';
import CheckIcon from '@mui/icons-material/Check';



// Material Design Button
import ExpandMore from '@mui/icons-material/ExpandMore';
// Material design password field
import api from "../Api/Indx"
import { logout, reset } from '../../features/auth/authSlice';







const Setting = () => {

  // handles Dashboard Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  // Handles Logout
  const dispatch = useDispatch()


  const Showcamplist = () => {

    window.location = "/campaign-list";
  }


  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    window.location = "/Login";
  }

  // Material Design input field for new passwod
  const [newpassword, setNewPassword] = React.useState("");
  const [currentpassword, setCurrentPassword] = React.useState("");
  const userinfo = JSON.parse(Storage.get('user'));
  const [sxMessage, setSxMessage] = React.useState(false);

  // Section handles Email update
  const [newemail, setNewemail] = useState("")
  const updateEmail = async () => {
    let data = {
      new_email: newemail
    }
 await api.updateEmail(data)
  }


  // Section handles New Email Confirmation
  const [emailcode, setEmailCode] = useState("")
  const confirmEmail = async () => {
    let data = {
      code: emailcode
    }
    const res = await api.confirmEmailupdate(data)

    if (res?.msg === "OK") {
      onLogout()
    }
  }



  // Handles New password
  const updatepassword = async () => {
    let data = {
      current_password: currentpassword,
      new_password: newpassword
    }

    const res = await api.accountpasswordupdate(data)

    if (res?.msg === "OK") {
      onLogout()
    }

  }



  const [profile, setProfile] = useState([]);

  const getUserProfile = async () => {

    const res = await api.userProfile()

    setProfile(res);
    // console.log(res)

  }


  // console.log(profile, "user profile")

  useEffect(() => {

    getUserProfile()

  }, []);





  const [orgname, setOrganame] = useState("")
  const [orgtype, setOrgtype] = useState("")
  const [orgsize, setOrgsize] = useState("")
  const [hq, setHq] = useState("")



  const handlesubmit = async (e) => {

    e.preventDefault();

    try {

      let data = {

        organization_name: orgname,
        organization_size: orgsize,
        organization_hq: hq,
        industry: orgtype,

      }

      localStorage.setItem("modalData", data);
      console.log(data, "organization data")
      const res = await api.onboard(data)

      if (res?.msg === "OK") {
        setSxMessage(true)
      }
      setOrganame("")
      setOrgtype("")
      setOrgsize("")
      setHq("")

    } catch (err) {

      console.log("Something went wrong")
    }


  }



  return (

    <div className='set1'>

      <div className="navparent">

        <nav className="nav1">

          <NavLink to="/dashboard"><img src="diyo.svg" className='navlogo' /></NavLink>

          <h3 className="navtitle" style={{ fontWeight: 400, fontSize: 15 }}>Dashboard</h3>

          <div className="navsidemenu">
            <span className="navtitle" onClick={handleClick} style={{ fontWeight: 400, fontSize: 15, cursor: "pointer" }}> {userinfo?.firstName}</span>
            <React.Fragment>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 1 }}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  >
                    <Avatar sx={{ width: 29, height: 32 }}>
                      <ExpandMore />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 29,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 8,
                      height: 8,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <Person style={{ color: "#5E16EB" }} /> My Profile
                </MenuItem>

                <MenuItem onClick={onLogout}>
                  <Logout style={{ color: "#5E16EB" }} fontSize="small" />
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          </div>

        </nav>


        <nav className="nav2">

          <ul className="navlist">
            <NavLink to="/dashboard" style={{ color: "rgba(68, 68, 68, 0.51)", textDecoration: "none", fontWeight: 400, fontSize: 15 }}>Home</NavLink>
            <NavLink to="/create-campaign" style={{ color: "rgba(68, 68, 68, 0.51)", textDecoration: "none", fontWeight: 400, fontSize: 15 }}>Campaigns</NavLink>

          </ul>

          <button
            
            onClick={Showcamplist}
            style={{
              borderRadius: 5,
              backgroundColor: "#F44336",
              fontFamily: 'ProductSans',
              fontSize: "12px",
              width: "150px",
              height: "30px",
              fontWeight: 400,
              border: "none",
              cursor: "pointer",
              color: "#FFF"
            }}>
            Campaign List
          </button>

        </nav>


      </div>



      <div className='SetC w-4/5 lg:w-1/2 mx-auto mb-6'>


        <div className='formcssdesign flex-col lg:flex-row'>
          <div className='fstname'>
            <span>First Name</span>

            <input
              type="text"
              name="fname"
              defaultValue={profile?.payload?.firstName}
              disabled={true}

            />
          </div>

          <div className='fstname'>
            <span>Last Name</span>
            <input
              type="text"
              name="lname"
              defaultValue={profile?.payload?.lastName}
              disabled={true}
            />

          </div>
        </div>

        <div className='formcssdesign'>
          <div className='fstemail'>
            <span>Email Address</span>
            <input
              type="email"
              name="email"
              defaultValue={profile?.payload?.email}
              disabled={true}
            />
          </div>
        </div>


        <div className='roless'>

          <div className='formcssdesign flex-col lg:flex-row'>


            <div className='fstname'>

              <span>Type of Industry</span>
              <select
               value={orgtype}
               onChange={(e) => setOrgtype(e.target.value)}
                className='fieldy'>
                <option value="Commerce">Commerce</option>
                <option value="Agriculture">Agriculture</option>
                <option value="Education">Education</option>
                <option value="Finance">Finance</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Retail">Retail</option>
                <option value="Technology">Technology</option>
                <option value="Transportation">Transportation</option>
                <option value="Real estate">Real estate</option>
                <option value="Professional services (such as legal or consulting firms)">Professional services (such as legal or consulting firms)</option>
                <option value="Nonprofit organizations">Nonprofit organizations</option>
                <option value="Hospitality and tourism">Hospitality and tourism</option>
                <option value="Construction">Construction</option>
                <option value="Government agencies">Government agencies</option>
                <option value="Media and entertainment">Media and entertainment</option>
                <option value="Consumer products">Consumer products</option>
                <option value="Energy and utilities">Energy and utilities</option>
              </select>
            </div>


            {/* <div className='fstname'>
              <span>Type of Industry</span>
              <input
                type="text"
                name="fname"
                defaultValue={profile?.payload?.profile?.onboardingData?.industry}
                onChange={(e) => setOrgtype(e.target.value)}
              />
            </div> */}

            <div className='fstname'>
              <span>Organization Name</span>
              <input
                type="text"
                name="lname"
                defaultValue={profile?.payload?.profile?.onboardingData?.organizationName}
                onChange={(e) => setOrganame(e.target.value)}
              />
            </div>
          </div>


          <div className='formcssdesign flex-col lg:flex-row'>

            <div className='fstname'>
              <span>Primary HQ Location</span>
              <input
                type="text"
                name="fname"
                defaultValue={profile?.payload?.profile?.onboardingData?.organizationHQ}
                onChange={(e) => setHq(e.target.value)}
              />
            </div>

            <div className='fstname'>
              <span>Organization Size</span>
              <input
                type="text"
                name="lname"
                defaultValue={profile?.payload?.profile?.onboardingData?.organizationSize}
                onChange={(e) => setOrgsize(e.target.value)}
              />
            </div>
          </div>
        </div>


        <div className='editinfdiv'>
          <button onClick={(e) => { handlesubmit(e) }} className="EditIn">Save information</button>
        </div>
        {sxMessage ? <div style={{color: "#000", fontSize: "12px", fontWeight: 400, fontFamily: "ProductSans", display: "flex", alignItems: "center"}}>Profile Saved<CheckIcon fontSize="small" sx={{ mr: 1, color: "#00FF00" }}/></div> : "" }

      </div>




      <div className={'flex flex-col lg:flex-row justify-center items-center gap-1 mb-6'}>

        <div className='NewsetD w-4/5 lg:w-1/4'>
          <p style={{ color: "#000", fontWeight: 600, fontFamily: "ProductSans" }} className={'text-lg'}>Update Email</p>
          <div className='fstnameN'>
            <span>New Email</span>
            <input
              type="email"
              name="email"
              onChange={(e) => setNewemail(e.target.value)}
              value={newemail}
            />
          </div>
          <div className='editinfdiv'>
            <button onClick={() => updateEmail()} className="EditIn">Update Email</button>
          </div>
        </div>


        <div className='NewsetD w-4/5 lg:w-1/4'>
          <p style={{ color: "#000", fontWeight: 600, fontFamily: "ProductSans" }} className={'text-lg'}>Confirm Email</p>
          <div className='fstnameN'>
            <span>Please input your code</span>
            <input
              type="text"
              name="text"
              onChange={(e) => setEmailCode(e.target.value)}
              value={emailcode}
            />
          </div>
          <div className='editinfdiv'>
            <button onClick={() => confirmEmail()} className="EditIn">Confirm Email</button>
          </div>
        </div>

      </div>






      <div className='SetD w-4/5 lg:w-1/2 mx-auto'>
        <p style={{ color: "#000", fontWeight: 600, fontFamily: "ProductSans" }} className={'text-lg'}>Change Password</p>


        <div className='formcssdesignPass flex-col lg:flex-row'>


          <div className='fstnamePass'>
            <span>Current Password</span>

            <input
              type="password"
              name="password"
              value={currentpassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />


          </div>


          <div className='fstnamePass'>
            <span>New Password</span>
            <input
              type="password"
              name="password"
              value={newpassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />

          </div>
        </div>
        <div className='editinfdiv'>
          <button onClick={() => updatepassword()} className="EditIn">Update Password</button>
        </div>
      </div>



    </div>

  )
}

export default Setting