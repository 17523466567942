import React, { useState, useRef } from "react";
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";
import api from "../Api/Indx"


export const Mobileconfirmpassword = () => {


  const userRef = useRef();
  const [email, setEmail] = useState('');


  const navigate = useNavigate()

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {

      let data = {}

      data = {

        email: email,

      }

       await api.triggerReset(data);
      // console.log(res?.data);

      navigate('/reset-password')
      setEmail('');


    } catch (err) {

      console.log("Erorr")


    }

  }

  return (
    <NewRootRoot>
      <Element1>
        <FlexRow>
          <FlexColumn>
            <NavLink to="/home"><DIYOLogoV src={"diyo.svg"} /></NavLink>
            <Text1>Forgot Password?</Text1>
            <Text4>Enter the Email Address you used to Register</Text4>
            <form onSubmit={handleSubmit}>
              <input
                type='email'
                placeholder="email@gmail.com"
                className="field"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                required
              />

              <input type="submit" value="Send verification eMail" className="submitButton" />
            </form>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <p style={{ fontSize: "12px", fontWeight: 400, fontFamily: "ProductSans" }}>
                <NavLink to="/login" style={{ textDecoration: "none", color: "#5E16EB", fontWeight: 400, fontFamily: "ProductSans" }}>
                  No Thanks
                </NavLink>
              </p>
            </div>

          </FlexColumn>
        </FlexRow>

      </Element1>
    </NewRootRoot>
  );
};

export default Mobileconfirmpassword;
const NewRootRoot = styled.div`
text-align: center;
background-color: #5E16EB;
min-height: 100vh;
// height: 896px;
// width: 414px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
flex: 1;
max-width: 100vmax;

margin: auto;
overflow: hidden;
`;
const Element1 = styled.div`
position: relative;
display: flex;
min-width: 30vw;
height: 85vh;
background: #FFFFFF;
box-shadow: 0px 19.6078px 39.2157px rgba(0, 0, 0, 0.1);
border-radius: 40px;
overflow-x: hidden;
overflow-y: hidden;  

`;

const FlexRow = styled.div`  
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 50px;

`;



const DIYOLogoV = styled.img`
width: 139.99px;
height: 80px;
// top: 250px;
// left: 337.31px;

`;
const Text1 = styled.div`
font-size: 20px;
font-weight: 400;
align-self: center;
margin: 0px 0px 35px 0px;
font-family:'ProductSans';
font-style: normal;
line-height: 10px;
top: calc(50% - 31px/2 - 84.5px);
display: flex;
align-items: center;
display: flex;
 
`;


const Text4 = styled.div`
  
  display: flex;
  font-size: 11px;
  font-family:'ProductSans';
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  flex-direction: column;
  align-items: start;
  justify-content: left;
  line-height: 16px;
  margin: 0px 50px 30px 0px;
  font-style: normal;
  color: #000000;
`;


const FlexColumn = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 10px;
@media (max-width: 768px){
  flex-direction: column;
} 
`;
