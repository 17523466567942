import * as React from 'react';
import { useState, useEffect, useRef, useContext, useCallback } from 'react';
import Map, { Marker, Popup, GeolocateControl, NavigationControl } from 'react-map-gl';
import MonitorIcon from '@mui/icons-material/Monitor';
import PeopleIcon from '@mui/icons-material/People';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AbortController from 'abort-controller';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapLoader from '../Map/MapLoader';
import api from '../Api/Indx';
import { CampaignContext } from '../../state';
import BACKEND from '../../utils/backend';
import StreetviewIcon from '@mui/icons-material/Streetview';
import Fab from '@mui/material/Fab';
import MapIcon from '@mui/icons-material/Map';
import Alert from '../../utils/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { formatBudget, formatNumber } from '../../utils/plugins';
import Tooltip from '@mui/material/Tooltip';
import { SearchBox } from '@mapbox/search-js-react';

function MainMap() {
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [fetchingLocationInfo, setFetchingLocationInfo] = useState(false);
  const [viewport, setViewport] = useState({
    longitude: -60.987207,
    latitude: 48.786962,
    zoom: 3,
  });
  const [adUnit, setAdUint] = useState(null);
  const [search, setSearch] = useState('');
  const mapRef = useRef();
  const controller = new AbortController();
  const {
    step,
    setStep,
    _selectedVenues,
    _setSelectedVenues,
    impressions2,
    setMinimumPayment2,
    show,
    setMamount,
    setActiveTab,
    refetchingMapData,
    setRefetchingMapData,
    personName,
    estimate,
    cost2,
    budgetRad1,
    screenvenuetypes,
    costFilters,
    incomeFilters,
    selectedVenues,
    setSelectedVenues,
    points,
    setPoints,
    setDisplay,
    dimensionsFilter,
  } = useContext(CampaignContext);

  // const {} = useContext(CampaignContext);

  const handleScreenStep = ({ newStep = 2, tab = '5' } = {}) => {
    setStep(newStep);
    setActiveTab(tab);
    localStorage.setItem('step', step);
  };

  const units = React.useMemo(() => {
    return selectedVenues.map((unit) => unit.id);
  });

  useEffect(() => {
    units.map((id) => {
      let pin = document.getElementById(id);
      if (pin) pin.style.color = '#444444';
    });

    let minimumPayment2 = 0;
    let _impressions2 = 0;
    let margin = 1.35;
    let mamount = 0;

    // calculates Recommended amount and set it to global state for campaigns with start date ONLY!
    for (let i = 0; i < selectedVenues.length; i++) {
      // Second Calculation
      let calc2 =
        Number(selectedVenues[i].planning.baseRate) * margin * (Number(_impressions2) / 1000);

      Number((minimumPayment2 += calc2));
    }

    // This is actual calculation that handles Minimu amount
    for (let i = 0; i < selectedVenues.length; i++) {
      let calc3 = Number(selectedVenues[i].planning.baseRate) * margin;
      Number((mamount += calc3));
    }

    setMamount(Math.ceil(mamount));
    setMinimumPayment2(Math.ceil(minimumPayment2));
  }, [units.length]);

  // const [image, setImage] = useState(false);
  // const [video, setVideo] = useState(false);

  // creative format payload
  // const format = [image, video];

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleVenueSelect = () => {
    const venueIds = selectedVenues.map(({ id }) => id);

    let pin = document.getElementById(adUnit.id);
    if (pin) pin.style.color = '#444444';
    if (venueIds.includes(adUnit.id)) {
      pin.style.color = '#444444';
      Alert({ type: 'Error', message: 'You can’t add the same screen twice' });
      return;
    }

    setSelectedVenues((prevState) => [...prevState, adUnit]);
    _setSelectedVenues((prevState) => [...prevState, adUnit]);
  };

  const handleVenueRemove = () => {
    const newVenues = [];
    for (let i = 0; i < selectedVenues.length; i++) {
      if (selectedVenues[i].id !== adUnit.id) {
        newVenues.push(selectedVenues[i]);
      }
    }

    setSelectedVenues(newVenues);
    _setSelectedVenues(newVenues);
  };

  const parseDimnsFilter = (dims) => {
    const [minFt, maxFt] = dims.split('-');
    return { minFt: Number(minFt), maxFt: Number(maxFt) };
  };
  const handleZoom = async () => {
    const s = mapRef.current ? mapRef.current.getMap().getBounds() : null;
    setRefetchingMapData(true);
    const { minimum, maximum } = costFilters;
    const { min_income, max_income } = incomeFilters;
    const { minFt, maxFt } = parseDimnsFilter(dimensionsFilter);
    console.log(min_income, max_income);
    try {
      const data = {
        filters: {
          min_size: minFt * 12,
          ...(maxFt > 0 && { max_size: maxFt * 12 }),
          min_base_rate: parseFloat(minimum),
          max_base_rate: parseFloat(maximum),
          // min_income: parseFloat(min_income),
          // max_income: parseFloat(max_income),
          publishers: personName,
          screen_types: screenvenuetypes,
        },

        min: {
          latitude: s._sw.lat,
          longitude: s._sw.lng,
        },
        max: {
          latitude: s._ne.lat,
          longitude: s._ne.lng,
        },
      };

      // console.log(data, "real lat and lng")

      const res = await new BACKEND().send({
        to: '/adunits/count',
        type: 'post',
        payload: data,
      });

      if (res.msg === 'no ad units found') {
        return;
      }
      setPoints(res?.payload?.Positions);

      setRefetchingMapData(false);
    } catch (err) {
      // console.log(err);
      setRefetchingMapData(false);
    }
  };

  function abortFetching() {
    console.log('Now aborting');
    // Abort.
    controller.abort();
  }

  // fetch location details on hover.
  const fetchLocationInfo = async (id) => {
    setFetchingLocationInfo(true);
    try {
      const _res = await api.getLocationInfo(id);
      setAdUint(_res.payload.adUnit);
      console.log(_res, 'setFetchingLocationInfo function');
      setFetchingLocationInfo(false);
    } catch (err) {
      console.log(err);
      setFetchingLocationInfo(false);
    }
  };

  useEffect(() => {
    handleZoom();
  }, [screenvenuetypes, costFilters, dimensionsFilter, incomeFilters]);

  const Streetnav = (lat, lng) => {
    window.open('/billboard-streetview', '_blank');
    localStorage.setItem('streetviewlat', lat);
    localStorage.setItem('streetviewlng', lng);
  };

  const onMove = useCallback((evt) => {
    setViewport(evt.viewState);
  }, []);

  const onRetrieve = useCallback(() => {
    setTimeout(async () => {
      await handleZoom();
    }, 3000);
  }, []);

  return (
    <div className='fet'>
      {refetchingMapData && (
        <div className='Maploadercs'>
          {' '}
          <MapLoader />
        </div>
      )}
      <div className='mapdiv'>
        <div className='impPrnt'>
          <div className='impcntmap2'>
            <div className={'flex justify-between items-center gap-2'}>
              <MonitorIcon fontSize='small' sx={{ color: '#5E16EB' }} />
              <p>
                Screens selected: <b>{_selectedVenues.length}</b>{' '}
              </p>
            </div>

            <p className='viewmore' onClick={() => handleScreenStep()}>
              View
            </p>
          </div>

          <div className='impcntmap mb-2' style={{ gap: 10 }}>
            <PeopleIcon fontSize='small' sx={{ color: '#5E16EB' }} />
            <p>
              Estimated reach:{' '}
              <b>{!show.styleB ? formatNumber(impressions2) : formatNumber(estimate)}</b> people
            </p>
          </div>

          {(cost2 || budgetRad1) && (
            <div className='impcntmap !flex-col'>
              <div className={'flex justify-between items-center gap-2'}>
                <AttachMoneyIcon fontSize='small' sx={{ color: '#5E16EB' }} />
                <p>
                  {show.styleB ? 'Campaign Cost' : 'Estimated Budget'}:
                  <b>
                    {show.styleB
                      ? formatBudget({ cost: cost2 })
                      : formatBudget({ cost: budgetRad1 })}
                  </b>
                </p>
              </div>
              <p className='viewmore' onClick={() => handleScreenStep({ newStep: 3 })}>
                Update
              </p>
            </div>
          )}

          <div className='impPrnt244'>
            <Fab variant='extended' sx={{ background: '#FFF' }} onClick={() => setDisplay(true)}>
              <MapIcon sx={{ mr: 1, color: '#5E16EB' }} />
              Targeting
            </Fab>
          </div>
        </div>

        <Map
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          {...viewport}
          onMove={onMove}
          style={{ height: '100vh', width: '100vw', zIndex: 9 }}
          mapStyle='mapbox://styles/mapbox/streets-v12'
          ref={mapRef}
          maxZoom={1000}
          onZoomEnd={() => handleZoom()}
          onZoomStart={() => abortFetching()}
          id={'diyoMap'}
        >
          <NavigationControl position={'bottom-left'} />
          {points.map((item, index) => (
            <Marker key={`crime-${index}`} latitude={item.Point.Lat} longitude={item.Point.Long}>
              {item.Size > 1 ? (
                <button
                  style={{
                    width: `${10 + item.size * 20}px`,
                    height: `${10 + item.size * 20}px`,
                  }}
                  className='cluster-marker'
                >
                  {item.Size}
                </button>
              ) : (
                <button
                  onMouseEnter={(e) => {
                    e.target.id = adUnit?.id;
                    setIsPopupOpen(true);
                    setSelectedPosition(item);
                    fetchLocationInfo(item.ID);
                  }}
                >
                  <FontAwesomeIcon
                    className={'custom-svg'}
                    icon={faLocationDot}
                    style={{ height: '30px', color: '#5E16EB' }}
                  />
                </button>
              )}
            </Marker>
          ))}
          {isPopupOpen ? (
            <Popup
              latitude={selectedPosition.Point.Lat}
              longitude={selectedPosition.Point.Long}
              anchor='bottom'
              maxWidth='100%'
              // anchor="top"
              dynamicPosition={false}
              onClose={() => closePopup()}
              closeButton={false}
              closeOnClick={false}
            >
              <div
                className='popup'
                onMouseLeave={() => {
                  setIsPopupOpen(false);
                }}
                onMouseEnter={() => {
                  setIsPopupOpen(true);
                }}
              >
                {fetchingLocationInfo ? (
                  <div className=''>{/* <MapLoader /> */}</div>
                ) : (
                  // Popup Card design
                  <div className='PopupCard'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Tooltip title='Image upload supported' placement='top'>
                        <FontAwesomeIcon
                          className={'custom-svg'}
                          style={{
                            color:
                              adUnit.asset.capability && adUnit.asset.capability.banner
                                ? '#5E16EB'
                                : 'grey',
                          }}
                          icon={faCamera}
                        />
                      </Tooltip>
                      <Tooltip title='Video upload supported' placement='top'>
                        <FontAwesomeIcon
                          className={'custom-svg'}
                          style={{
                            color:
                              adUnit.asset.capability && adUnit.asset.capability.video
                                ? '#5E16EB'
                                : 'grey',
                          }}
                          icon={faVideo}
                        />
                      </Tooltip>
                    </div>

                    <div className='PopupCardinnercontent'>
                      {/* Logic goes here */}

                      <p className='PopupCardinnercontent-para1'>{adUnit.venue.name}</p>
                      <p className='PopupCardinnercontent-para1'>
                        Category ~{' '}
                        {adUnit.venue.openooh_category_display.replace('_', ' ').replace('.', ' ')}{' '}
                        [Publisher: {adUnit.publisher}]
                      </p>

                      <div style={{ display: 'flex', gap: 10 }}>
                        <p
                          className='PopupCardinnercontent-para2'
                          onClick={() =>
                            Streetnav(adUnit.location.latitude, adUnit.location.longitude)
                          }
                          style={{
                            color: '#5E16EB',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          {adUnit.venue.address.replace('_', ' ').replace('.', ' ')}
                        </p>
                        <StreetviewIcon fontSize='small' sx={{ color: '#5E16EB' }} />
                      </div>
                      <a
                        target='_blank'
                        href={adUnit.asset.imageUrl || '#'}
                        className={'text-decoration-none cursor-pointer'}
                        rel={'noreferrer'}
                      >
                        <img
                          className='adunitImage'
                          src={adUnit.asset.imageUrl || 'placeholder.svg'}
                          alt={'Click to enlarge'}
                        />
                      </a>

                      <div className='ppcrdcnt'>
                        <div className='dimenaspec'>
                          <p>Diagonal length (ft):</p>
                          <h3> {Number(Math.ceil(`${adUnit.asset.size / 12}`))} ft</h3>
                        </div>

                        <div className='dimenaspec'>
                          <p>Weekly impressions:</p>
                          <h3>
                            {Number(Math.ceil(`${adUnit.measurement?.fourWeekImpression / 4}`))}
                          </h3>
                        </div>

                        <div className='dimenaspec'>
                          <p>Ad play length (s):</p>
                          <h3>{adUnit.measurement?.duration}</h3>
                        </div>
                      </div>

                      <div className='Popbuttondiv'>
                        <button onClick={handleVenueSelect} className='select'>
                          SELECT
                        </button>
                        <button onClick={() => handleVenueRemove()} className='select'>
                          DESELECT
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Popup>
          ) : null}

          <GeolocateControl position='top-left' trackUserLocation />
          <SearchBox
            accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            className={'search-box'}
            map={mapRef?.current?.getMap()}
            value={search}
            onChange={setSearch}
            onRetrieve={onRetrieve}
          />
        </Map>
      </div>
    </div>
  );
}

export default MainMap;
