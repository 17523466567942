import React, { useState, useEffect } from 'react';
import { FaAngleDoubleUp } from 'react-icons/fa';

const ScrollToTop = () => {
  const [showscrolltopbutton, setShowscrolltopbutton] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 300) {
        setShowscrolltopbutton(true);
      } else {
        setShowscrolltopbutton(false);
      }
    });
  }, []);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {showscrolltopbutton && (
        <FaAngleDoubleUp className='top-btn-button top-btn-button-style' onClick={scrollTop} />
      )}
    </div>
  );
};

export default ScrollToTop;
