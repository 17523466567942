import React, { useContext, useState } from "react";
import "../Orgmodal/ModalOrg.css"
import { CampaignContext } from '../../../state';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from "../../Api/Indx"
import CheckIcon from '@mui/icons-material/Check';




const ModalOrg = () => {

      const { orgmodal, setOrgmodal } = useContext(CampaignContext)

      const [orgname, setOrganame] = useState("")
      const [orgtype, setOrgtype] = useState("")
      const [orgsize, setOrgsize] = useState("1 - 4")
      const [hq, setHq] = useState("")
      const [sxMessage, setSxMessage] = React.useState(false);


      const handlesubmit = async (e) => {
     
            e.preventDefault();

            try{

            let data = {

                  organization_name: orgname,
                  organization_size: orgsize,
                  organization_hq: hq,
                  industry: orgtype,
                  
            }
            
            localStorage.setItem("modalData", data);

            console.log(data, "organization data")

            const res = await api.onboard(data)
            

            if(res?.msg === "OK"){

                  setSxMessage(true)
                  setOrgmodal(false)


            }

            setOrganame("")
            setOrgtype("")
            setOrgsize("")
            setHq("")


      }catch(err){

          console.log("Something went wrong")
      }


      }



      return (

            <div className={orgmodal ? `modalpopS  modal__activeS` : "modalpopS"}>

                  <form onSubmit={handlesubmit}>
                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

                              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: 20 }}>
                              <img style={{ width: "80px", height: "80px" }} src="diyo.svg" />
                              <p style={{fontWeight: 400, fontSize: "12px", fontFamily: "ProductSans"}}>Please tell us a little bit more about you and your orgnization</p>
                              {sxMessage ? <div style={{color: "#000", fontSize: "12px", fontWeight: 400, fontFamily: "ProductSans", display: "flex", alignItems: "center"}}>Profile Saved<CheckIcon fontSize="small" sx={{ mr: 1, color: "#00FF00" }}/></div> : "" }

                              </div>
                             



                              <div>
                                    <label style={{ color: "#000", fontFamily: "ProductSans", fontSize: "13px", fontWeight: 400 }}>Organization Name</label>
                                    <input
                                          type='text'
                                          value={orgname}
                                          onChange={(e) => setOrganame(e.target.value)}
                                          className='fieldy'
                                          placeholder='Organization Name'

                                    />

                              </div>


                              <div>
                                    <label style={{ color: "#000", fontFamily: "ProductSans", fontSize: "13px", fontWeight: 400 }}>Type of industry</label>
                                    <select
                                          value={orgtype}
                                          onChange={(e) => setOrgtype(e.target.value)}
                                          className='fieldy'>
                                          <option value="Commerce">Commerce</option>
                                          <option value="Agriculture">Agriculture</option>
                                          <option value="Education">Education</option>
                                          <option value="Finance">Finance</option>
                                          <option value="Healthcare">Healthcare</option>
                                          <option value="Manufacturing">Manufacturing</option>
                                          <option value="Retail">Retail</option>
                                          <option value="Technology">Technology</option>
                                          <option value="Transportation">Transportation</option>
                                          <option value="Real estate">Real estate</option>
                                          <option value="Professional services (such as legal or consulting firms)">Professional services (such as legal or consulting firms)</option>
                                          <option value="Nonprofit organizations">Nonprofit organizations</option>
                                          <option value="Hospitality and tourism">Hospitality and tourism</option>
                                          <option value="Construction">Construction</option>
                                          <option value="Government agencies">Government agencies</option>
                                          <option value="Media and entertainment">Media and entertainment</option>
                                          <option value="Consumer products">Consumer products</option>
                                          <option value="Energy and utilities">Energy and utilities</option>                                      
                                    </select>
                              </div>


                             


                              <div>
                                    <label style={{ color: "#000", fontFamily: "ProductSans", fontSize: "13px", fontWeight: 400 }}>Organization Size</label>
                                    <select
                                          defaultValue={orgsize}
                                          onChange={(e) => setOrgsize(e.target.value)}
                                          className='fieldy'>
                                          <option value="1 - 5">1 - 5</option>
                                          <option value="6 - 20">6 - 20</option>
                                          <option value="21 - 50">21 - 50</option>
                                          <option value="51 - 100">51 - 100</option>
                                          <option value="100+">100+</option>
                                    </select>
                              </div>


                              <div>
                                    <label style={{ color: "#000", fontFamily: "ProductSans", fontSize: "13px", fontWeight: 400 }}> Primary HQ </label>
                                    <input
                                          type='text'
                                          className='fieldy'
                                          value={hq}
                                          placeholder='Primary HQ Location'
                                          onChange={(e) => setHq(e.target.value)}
                                    />
                              </div>


                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 10 }}>

                                    <FormControlLabel
                                          label={<span style={{ fontWeight: 400, fontSize: "12px", display: "flex", justifyContent: "center", alignItems: "center" }}>I agree to receive news, product updates,<br />
                                                event announcements and other communications from DIYO. </span>}
                                          control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 20, color: "#5E16EB" } }} />}
                                    />
                              </div>

                              <div>

                                    <button className="Next">NEXT</button>
                              </div>


                        </div>

                  </form>
            </div>
      );
};

export default ModalOrg;