import React from 'react';
import ReactDOM from 'react-dom/client';
import './fonts/fonts.css';
import './index.css';
import App from './App';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
// import { loadStripe } from "@stripe/stripe-js";
// import {Elements} from '@stripe/react-stripe-js'
import { GoogleOAuthProvider } from '@react-oauth/google';
import CacheBuster from 'react-cache-buster';
import pkg from '../package.json';
// const REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

// const stripePromise = loadStripe(REACT_APP_STRIPE_KEY)

const root = ReactDOM.createRoot(document.getElementById('root'));
const isProduction = process.env.NODE_ENV === 'production';

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
          <CacheBuster
            currentVersion={pkg.version}
            isEnabled={isProduction} //If false, the library is disabled.
            isVerboseMode={false} //If true, the library writes verbose logs to console.
            metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
          >
            <App />
          </CacheBuster>
        </GoogleOAuthProvider>
      </Router>
    </Provider>
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
