import API_INSTANCE from './axios';
import Alert from './alert';
import axios from 'axios';

const API_BASE = process.env.REACT_APP_API_URL;
class BACKEND {
  constructor(url = API_BASE) {
    const newInstance = axios.create();
    this._API = new API_INSTANCE({ url }).create(newInstance);
  }

  send({ type = 'get', to, payload, cb, header = {}, useAlert = false }) {
    return this._API[type](to, ['get', 'delete'].includes(type) ? header : payload, header)
      .then(function (response) {
        if ([200, 201, 304].includes(response?.status)) {
          if (useAlert) {
            Alert({
              type: 'success',
              message: response?.data?.msg,
              cb: () => (cb ? cb(response?.data) : ''),
            });
          } else if (cb) cb(response?.data);

          return response?.data;
        } else if ([400].includes(response?.status)) {
          if (useAlert) {
            Alert({
              type: 'error',
              message: response?.data?.msg,
            });
          }
          return response?.data;
        }
      })
      .catch(function (e) {
        console.log(e);
        const err = 'error';
        const message = e?.msg || e?.[err] || 'Please check your internet connection';
        if (useAlert) {
          Alert({
            type: err,
            message,
          });
        }
        throw e;
      });
  }
}

export default BACKEND;
