import React, { useState } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import api from "../Api/Indx"
import CustomGoogleSignup from "../GoogleAuth/CustomGoogleSignup";






export const MobileViewSigUp = () => {


	const [firstname, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [user, setUser] = useState('');
	const [pwd, setPwd] = useState('');



	const handleSubmit = async (e) => {

		e.preventDefault();

		try {

			let data = {}

			data = {

				email: user,
				password: pwd,
				first_name: firstname,
				last_name: lastName
			}

			const res = await api.signupAccount(data);


      if (res?.msg === "Account Created"){

        window.location = "/login"
      }



			setFirstName('')
			setLastName('')
			setUser('');
			setPwd('');

		} catch (err) {

      console.log(err)

		}

	}






  return (
    <NewRootRoot>
      <Element1>
        <FlexRow>
          <FlexColumn>
          <NavLink to="/home"><DIYOLogoV src={"diyo.svg"} /></NavLink>
            {/* <Text1>Login to your DIYO Account</Text1> */}
            <Text2>Create your DIYO account and launch an outdoor ad campaign in 5 clicks!</Text2>

            <section className='form'>

              <form onSubmit={handleSubmit}>

                <div className='form-group'>
                  <input
                    type='text'
                    className='field'
                    name='first_name'
                    value={firstname}
                    onChange={(e) => setFirstName(e.target.value)}
                    placeholder='Enter your name'
                    required
                  />
                </div>

                <div className='form-group'>
                  <input
                    type="text"
                    name='last_name'
                    placeholder="LastName"
                    className="field"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    required
                  />
                </div>


                <div className='form-group'>
                  <input
                    type='email'
                    className='field'
                    name='email'
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    placeholder='Enter your email'
                    required
                  />
                </div>


                <div className='form-group'>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    className="field"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                  />
                </div>

                <div>
                  <button type="submit" className="submitButton">Submit</button>
                </div>

              </form>
            </section>

            <div className="checkbtn22">
              <hr className='hr' width="20%"
                size="1"
              />
              <p className="t3">Or</p>
              <hr className='hr' width="20%"
                size="1"
              />
            </div>

            <div className="gg">
              <div>
              <CustomGoogleSignup />
              </div>
            </div>



            <div className="el">
              <p className="psgn">
                {" "}
                Have an Account?{" "}
                <a href="/MobileViewSignIn" className="achkb">
                  Login
                </a>
              </p>
            </div>
          </FlexColumn>
        </FlexRow>
      </Element1>
    </NewRootRoot>
  );
};

export default MobileViewSigUp;

const NewRootRoot = styled.div`
text-align: center;
background-color: #5E16EB;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
font-size: calc(10px + 2vmin);
flex: 1;
max-width: 100vmax;

margin: auto;
overflow: hidden;
`;

const Element1 = styled.div`
position: relative;
display: flex;
min-width: 30vw;
height: 85vh;
background: #FFFFFF;
box-shadow: 0px 19.6078px 39.2157px rgba(0, 0, 0, 0.1);
border-radius: 40px;
overflow-x: hidden;
overflow-y: hidden;

`;

const FlexRow = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 50px;
`;

const DIYOLogoV = styled.img`
  width: 139.99px;
  height: 80px;
  // top: 250px;
  // left: 337.31px;
`;






const Text2 = styled.div`
  display: flex;
  font-size: 16px;
  font-family:'ProductSans', Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  flex-direction: column;
  align-items: start;
  justify-content: left;
  margin: 0 0 20px;
  font-style: normal;
  color: #000000;
`;


const FlexColumn = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 10px;
`;
