import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { useContext } from 'react';
import { CampaignContext } from '../../../state';

export default function ScreenDimensionsFilter() {
  const { dimensionsFilter, setDimensionsFilter } = useContext(CampaignContext);
  const [value, setValue] = React.useState(dimensionsFilter);
  const dimensions = [
    { label: 'All', value: { min: 0, max: 0 } },
    { label: '0 to 2ft', value: { min: 0, max: 2 } },
    { label: '2ft to 5ft', value: { min: 2, max: 5 } },
    { label: '10ft to 20ft', value: { min: 10, max: 20 } },
    { label: '20ft to 35ft', value: { min: 20, max: 35 } },
    { label: '35ft to 50ft', value: { min: 35, max: 50 } },
    { label: 'more than 75ft', value: { min: 75, max: 0 } },
  ];
  const getValueText = (dimns) => {
    return `${dimns.min}-${dimns.max}`;
  };
  const handleChange = (event) => {
    setValue(event.target.value);
    setDimensionsFilter(event.target.value);
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby='dimens-controlled-radio-buttons-group'
        name='dimens-radio-buttons-group'
        value={value}
        onChange={handleChange}
      >
        {dimensions.map((dimension) => (
          <FormControlLabel
            value={getValueText(dimension.value)}
            control={<Radio />}
            label={dimension.label}
            key={dimension.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
