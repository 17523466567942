import React from 'react';
import "../Privacy/Privacy.css"
import { NavLink } from "react-router-dom";




const Privacy = () => {

    
      return (

            <div className='privacy'>

                  <div className="privacynav">

                        <div className="privacynav1">

                              <NavLink to="/dashboard"><img src="DIYO.svg" className='privacynavlogo' /></NavLink>

                              <h3 className="navtitle">Privacy Policy - DIYO</h3>


                              <div className="navsidemenu">

                              </div>
                        </div>




                  </div>

                  <div className="privacyhero">

                        {/* <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>PRIVACY POLICY</p> */}

                        <p style={{ color: "#000", fontFamily: "ProductSans" }}>
                              At <b><b>DIYO</b></b> we recognize that privacy is important.
                              This Privacy Policy applies to all of the products,
                              services and websites offered by <b>DIYO</b> Inc.

                              For any questions or concerns regarding
                              this Privacy Policy or <b>DIYO</b>&apos;s treatment of personal
                              information, please feel free to contact us at contact@<b>DIYO</b>.ca

                        </p>

                        <p style={{ fontWeight: 400 }}>
                              EU-US, SWISS-US PRIVACY SHIELD, CANADA&apos;S ANTI-SPAM LEGISLATION AND
                              PERSONAL INFORMATION PROTECTION AND ELECTRONIC DOCUMENT ACT NOTICE<br />
                        </p>

                        <p>
                              We may receive Personal Data from individuals in the European Economic Area and/or Switzerland.
                              We adhere to the EU-US and the Swiss-US Privacy Shield Principles (Privacy Shield Principles),
                              with respect to the collection, use, and retention of Personal Data from the European Economic
                              Area and Switzerland. If there is any conflict between the terms in this Privacy Statement and
                              the Privacy Shield Principles, with respect to the processing of Personal Data from the European
                              Economic Area and/or Switzerland, the Privacy Shield Principles shall govern.
                              For more information about the Privacy Shield Framework, and to view Premier&apos;s certification,
                              go to https://www.privacyshield.gov/.<br />
                        </p>


                        <p>
                              In compliance with the Privacy Shield Principles, we are committed to resolving complaints about
                              our collection or use of your Personal Data. European Union and Swiss individuals with inquiries
                              or complaints regarding our compliance with the Privacy Shield Principles should first contact
                              us as discussed below in the “Contact Us” section. In certain circumstances, it may be possible
                              for you to invoke binding arbitration. <b>DIYO</b> is subject to the investigatory and enforcement
                              powers of the United States Federal Trade Commission, with respect to its adherence to the
                              Privacy Shield Principles.<br />

                              We are further committed to cooperate with European Union and Swiss data protection authorities
                              (DPAs) with respect to any unresolved Privacy Shield complaints concerning your Personal Data,
                              in the context of the employment relationship. If you do not receive timely acknowledgement of your complaint from us,
                              or if we have not addressed your complaint to your satisfaction, please contact the European Union and/or Swiss DPAs for more information or to file a complaint.
                              The services of the European Union and Swiss DPAs are provided at no cost to you.<br />

                              As discussed below, we may share your Personal Data with our service providers who are bound by law and/or contract
                              to protect your Personal Data and may only use your Personal Data in accordance with our instructions. Under certain circumstances,
                              we may remain liable for the acts of these third parties, if they subsequently process the data in a manner that is inconsistent with the Privacy Shield Principles.

                        </p>

                        <p>
                              We may need to disclose your Personal Data when requested under legal process or as otherwise required by law,
                              such as in response to a subpoena, including to meet national security and/or law enforcement
                              requirements in the United States and other countries where we operate.
                        </p>


                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>INFORMATION WE COLLECT</p>
                        <p>In order to provide our full range of services, we may collect the following types of information:</p>


                        <p>
                              <b>Information you provide –</b> When you sign up for a <b>DIYO</b> Account or other <b>DIYO</b> service or promotion
                              that requires registration, we ask you for personal information (such as your name, email address and an account password). For certain services, we may also request credit card or other payment account information which we maintain in encrypted form on secure servers. We may combine the information you submit under your account with information from other <b>DIYO</b> services or third parties in order to provide you with a better experience and to improve the quality of our services. For certain services, we may give you the opportunity to opt out of combining such information.

                        </p>



                        <p>
                              <b>Cookies –</b>When you visit <b>DIYO</b>, we send one or more cookies –
                              a small file containing a string of characters – to your computer that
                              uniquely identifies your browser. We use cookies to improve the quality
                              of our service by storing user preferences and tracking user trends.

                        </p>


                        <p>
                              <b>Log information –</b> When you access <b>DIYO</b> services, our servers automatically record information that your browser sends whenever you visit a website.
                              These server logs may include information such as your web request, Internet Protocol address, browser type, browser language,
                              the date and time of your request and one or more cookies that may uniquely identify your browser.

                        </p>


                        <p>
                              <b>User communications –</b>When you send email or other communications to <b>DIYO</b>,
                              we may retain those communications
                              in order to process your inquiries, respond to your requests and improve our services.

                        </p>



                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>DATA SOURCES</p>
                        <p>We collect Personal Data only from the Data Subject unless one of the following apply:</p>

                        <p>

                              The nature of the business purpose necessitates collection of the Personal Data from other persons or bodies.
                              The collection must be carried out under emergency circumstances in order to protect the vital interests of the Data Subject or to prevent serious loss or injury to another person.
                              If Personal Data is collected from someone other than the Data Subject, we inform the Data Subject of the collection unless one of the following apply: <br />

                              The Data Subject has received the required information by other means.
                              The information must remain confidential due to a professional secrecy obligation


                        </p>

                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>HOW WE USE THE COLLECTED INFORMATION</p>
                        <p>

                              <b>DIYO</b> only processes personal information for the purposes described in this Privacy Policy, such purposes include:<br />

                              Maintaining general business operation and administration of <b>DIYO</b> entities;
                              Providing services to <b>DIYO</b> customers;
                              Providing ongoing administration and management of customer services;
                              Developing and improving <b>DIYO</b> products and services.
                              We process Personal Data in accordance with all applicable laws and applicable contractual obligations. More specifically, we will not process Personal Data unless at least one of the following requirements are met:

                              The Data Subject has given Consent to the Processing of their Personal Data for one or more specific purposes;
                              Processing is necessary for the performance of a contract to which the Data Subject is party or in order to take steps at the request of the Data Subject prior to entering into a contract;
                              Processing is necessary for compliance with a legal obligation to which the Data Controller is subject;
                              Processing is necessary in order to protect the vital interests of the Data Subject or of another natural person;
                              Processing is necessary for the performance of a task carried out in the public interest or in the exercise of official authority vested in the Data Controller;
                              Processing is necessary for the purposes of the legitimate interests pursued by the Data Controller or by a Third Party (except where such interests are overridden by the interests or fundamental rights and freedoms of the Data Subject, in particular where the Data Subject is a child).;
                              There are some circumstances in which Personal Data may be further processed for purposes that go beyond the original purpose for which the Personal Data was collected. In these circumstances, we will address the following additional conditions to determine the fairness and transparency of any Processing beyond the original purpose for which the Personal Data was collected:

                              Any link between the purpose for which the Personal Data was collected and the reasons for intended further Processing.
                              The context in which the Personal Data has been collected, in particular regarding the relationship between Data Subject and the Data Controller.
                              The nature of the Personal Data, in particular whether Special Categories of Data are being Processed, or whether Personal Data related to criminal convictions and offences are being Processed.
                              The possible consequences of the intended further Processing for the Data Subject.
                              The existence of appropriate safeguards pertaining to further Processing, which may include Encryption, Anonymization or Pseudonymization.
                              <b>DIYO</b> processes personal information on our servers in the United States of America, Canada and in other countries. In some cases, we process personal information on a server outside your own country. We may process personal information to provide our own services. In some cases, we may process personal information on behalf of and according to the instructions of a third party, such as our advertising partners.
                              If we propose to use personal information for any purposes other than those described in this Privacy Policy and/or in the specific service privacy notices, we will offer you an effective way to opt out of the use of personal information for those other purposes. We will not collect or use sensitive information for purposes other than those described in this Privacy Policy and/or in the supplementary service privacy notices, unless we have obtained your prior consent.
                              Most browsers are initially set up to accept cookies, but you can reset your browser to refuse all cookies or to indicate when a cookie is being sent. However, some <b>DIYO</b> features and services may not function properly if your cookies are disabled.
                              You can decline to submit personal information to any of our services, in which case <b>DIYO</b> may not be able to provide those services to you.

                        </p>

                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>SPECIAL CATEGORIES OF DATA</p>
                        <p>
                              We will only Process Special Categories of Data (also known as sensitive data) where the Data Subject expressly consents to such Processing or where one of the following conditions apply:<br />
                              Any link between the purpose for which the Personal Data was collected and the reasons for intended further Processing.
                              The context in which the Personal Data has been collected, in particular regarding the relationship between Data Subject and the Data Controller.
                              The nature of the Personal Data, in particular whether Special Categories of Data are being Processed, or whether Personal Data related to criminal convictions and offences are being Processed.
                              The possible consequences of the intended further Processing for the Data Subject.
                              The existence of appropriate safeguards pertaining to further Processing, which may include Encryption, Anonymization or Pseudonymization.
                              Where Special Categories of Data are being Processed, <b>DIYO</b> will adopt additional protection measures. Each <b>DIYO</b> Entity may also adopt additional measures to address local custom or social expectation over the Processing of Special Categories of Data.

                        </p>

                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>DATA QUALITY</p>
                        <p>
                              We adopt all necessary measures to ensure that the Personal Data it collects and Processes is complete and accurate in the first instance and is updated to reflect the current situation of the Data Subject. The measures we adopted to ensure data quality include:
                              Correcting Personal Data known to be incorrect, inaccurate, incomplete, ambiguous, misleading or outdated, even if the Data Subject does not request rectification.
                              Keeping Personal Data only for the period necessary to satisfy the permitted uses or applicable statutory retention period.
                              Removing Personal Data if in violation of any of the Data Protection principles or if the Personal Data is no longer required.
                              Restricting, rather than deletion of Personal Data, insofar as:
                              A law prohibits erasure
                              Erasure would impair legitimate interests of the Data Subject.
                              The Data Subject disputes that their Personal Data is correct and it cannot be clearly ascertained whether their information is correct or incorrect.

                        </p>

                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>DATA RETENTION</p>
                        <p>
                              We do not retain Personal Data for longer than necessary in relation to the purposes for which it was originally collected, or for which it was further Processed.
                        </p>

                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>DATA PROTECTION</p>
                        <p>
                              We adopt physical, technical, and organizational measures to ensure the security of Personal Data. This includes the prevention of loss or damage, unauthorized alteration, access or Processing, and other risks to which it may be exposed by virtue of human action or the physical or natural environment. The measures we took include:<br />
                              Prevent unauthorized persons from gaining access to data processing systems in which Personal Data are Processed.
                              Prevent persons entitled to use a data processing system from accessing Personal Data beyond their needs and authorizations.
                              Ensure that Personal Data in the course of electronic transmission during transport cannot be read, copied, modified or removed without authorization.
                              Ensure that access logs are in place to establish whether, and by whom, the Personal Data was entered into, modified on or removed from a data processing system.
                              Ensure that in the case where Processing is carried out by a Data Processor, the data can be Processed only in accordance with the instructions of the Data Controller.
                              Ensure that Personal Data is protected against undesired destruction or loss.
                              Ensure that Personal Data collected for different purposes can and is Processed separately.
                              Ensure that Personal Data is not kept longer than necessary.

                        </p>


                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>INFORMATION SHARING</p>
                        <p>
                              <b>DIYO</b> only shares personal information with other companies or individuals outside of <b>DIYO</b> in the following limited circumstances:

                              We have your consent. We require opt-in consent for the sharing of any sensitive personal information.

                              We provide such information to our subsidiaries, affiliated companies or other trusted businesses or persons for the purpose of processing personal information on our behalf. We require that these parties agree to process such information based on our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.

                              We have a good faith belief that access, use, preservation or disclosure of such information is reasonably necessary to (a) satisfy any applicable law, regulation, legal process or enforceable governmental request, (b) enforce applicable Terms of Service, including investigation of potential violations thereof, (c) detect, prevent, or otherwise address fraud, security or technical issues, or (d) protect against imminent harm to the rights, property or safety of <b>DIYO</b>, its users or the public as required or permitted by law.

                              If <b>DIYO</b> becomes involved in a merger, acquisition, or any form of sale of some or all of its assets, we will provide notice before personal information is transferred and becomes subject to a different privacy policy.

                              We may share with third parties certain pieces of aggregated, non-personal information. Such information does not identify you individually.

                              Please contact us at the address below for any additional questions about the management or use of personal data.


                        </p>

                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>YOUR RIGHTS</p>
                        <p>
                              You have the rights to request:<br />

                              Information access<br />
                              Objection to Processing<br />
                              Objection to automated decision-making and profiling<br />
                              Restriction of Processing<br />
                              Data portability<br />
                              Data rectification<br />
                              Data erasure<br />
                              If you make a request relating to any of the rights listed above, we will consider each such request in accordance with all applicable Data Protection laws and regulations. No administration fee will be charged for considering and/or complying with such a request unless the request is deemed to be unnecessary or excessive in nature.

                              You are entitled to obtain, based upon a request made in writing and upon successful verification of your identity, the following information about your Personal Data:

                              The purposes of the collection, Processing, use and storage of their Personal Data.
                              The source(s) of the Personal Data, if it was not obtained from the Data Subject;
                              The categories of Personal Data stored for the Data Subject.
                              The recipients or categories of recipients to whom the Personal Data has been or may be transmitted, along with the location of those recipients.
                              The envisaged period of storage for the Personal Data or the rationale for determining the storage period.
                              The use of any automated decision-making, including Profiling.
                              The right of the Data subject to:
                              Object to Processing of their Personal Data.
                              Lodge a complaint with the Data Protection Authority.
                              Request rectification or erasure of their Personal Data.
                              Request restriction of Processing of their Personal Data.
                              A response to each request will be provided within 30 days of the receipt of the written request from the Data Subject. Appropriate verification must confirm that the requestor is the Data Subject or their authorized legal representative. Data Subjects shall have the right to require <b>DIYO</b> to correct or supplement erroneous, misleading, outdated, or incomplete Personal Data.

                              If we cannot respond fully to the request within 30 days, we will nevertheless provide the following information to you, or your authorized legal representative within the specified time:

                              An acknowledgement of receipt of the request
                              Any information located to date
                              Details of any requested information or modifications which will not be provided to the Data Subject, the reason(s) for the refusal, and any procedures available for appealing the decision
                              An estimated date by which any remaining responses will be provided
                              An estimate of any costs to be paid by the Data Subject (e.g. where the request is excessive in nature)
                              The name and contact information of the <b>DIYO</b> individual who the Data Subject should contact for follow up
                              It should be noted that situations may arise where providing the information requested by a Data Subject would disclose Personal Data about another individual. In such cases, information must be redacted or withheld as may be necessary or appropriate to protect that person&apos;s rights. <br />

                              To request access to your data, please email us contact @ <b>DIYO</b>.ca with the following information:.
                              Email*<br />
                              First Name*<br />
                              Last Name*<br />
                              What change would you like to request? *<br />


                        </p>

                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>LAW ENFORCEMENT REQUESTS AND DISCLOSURES</p>
                        <p>
                              If requested by the law enforcement agencies or judicial courts, Personal Data may be shared without the knowledge or Consent of a Data Subject. This is the case where the disclosure of the Personal Data is necessary for any of the following purposes:

                              The prevention or detection of crime
                              The apprehension or prosecution of offenders
                              The assessment or collection of a tax or duty
                              By the order of a court or by any rule of law
                              If we Process Personal Data for one of these purposes, then it may apply an exception to the Processing rules outlined in this policy but only to the extent that not doing so would be likely to prejudice the case in question.


                        </p>




                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>CHANGES TO THIS PRIVACY POLICY</p>
                        <p>
                              Please note that this Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent,
                              and we expect most such changes will be minor. Regardless, we will post any Privacy Policy changes on this page and, if the changes are significant, we will provide a more prominent notice. Each version of this Privacy Policy will be identified at the top of the page by its effective date, and we will also keep prior versions of this Privacy Policy in an archive for your review.
                              If we Process Personal Data for one of these purposes, then it may apply an exception to the Processing rules outlined in this policy but only to the extent that not doing so would be likely to prejudice the case in question.
                        </p>




                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>DEFINITIONS</p>

                        <p>
                              Following terms used in this privacy policy are defined as follows.

                              <b>Third Party:</b> An external organisation with which <b>DIYO</b> conducts business and is also authorised to, under the direct authority of <b>DIYO</b>, Process the Personal Data of <b>DIYO</b> contacts.<br />

                              <b>Personal Data:</b> Any information (including opinions and intentions) which relates to an identified or Identifiable Natural Person.<br />

                              <b> Contact:</b> Any past, current or prospective <b>DIYO</b> customer.<br />

                              <b> Identifiable Natural Person:</b> Anyone who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier, or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.<br />

                              <b>Data Controller:</b> A natural or legal person, Public Authority, Agency or other body which, alone or jointly with others, determines the purposes and means of the Processing of Personal Data.<br />

                              <b> <b>DIYO</b> Entity:</b> A <b>DIYO</b> establishment, including subsidiaries and joint ventures over which <b>DIYO</b> exercise management control.<br />

                              <b>Data Subject:</b> The identified or Identifiable Natural Person to which the data refers.<br />

                              Process, Processed, Processing: Any operation or set of operations performed on Personal Data or on sets of Personal Data, whether or not by automated means. Operations performed may include collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.<br />

                              <b>Data Protection: </b>The process of safeguarding Personal Data from unauthorised or unlawful disclosure, access, alteration, Processing, transfer or destruction.<br />

                              <b>Data Protection Authority:</b> An independent Public Authority responsible for monitoring the application of the relevant Data Protection regulation set forth in national law.<br />

                              <b>Consent:</b> Any freely given, specific, informed and unambiguous indication of the Data Subject&apos;s wishes by which he or she, by a statement or by a clear affirmative action, signifies agreement to the Processing of Personal Data relating to him or her.<br />

                              <b>Special Categories of Data:</b> Personal Data pertaining to or revealing racial or ethnic origin, political opinions, religious or philosophical beliefs, trade-union membership; data concerning health or sex life and sexual orientation; genetic data or biometric data.<br />

                              <b>Profiling:</b> Any form of automated processing of Personal Data where Personal Data is used to evaluate specific or general characteristics relating to an Identifiable Natural Person. In particular to analyse or predict certain aspects concerning that natural person&apos;s performance at work, economic situations, health, personal preferences, interests, reliability, behaviour, location or movement.<br />

                              <b>Encryption:</b> The process of converting information or data into code, to prevent unauthorised access.<br />

                              <b>Pseudonymization:</b> Data amended in such a way that no individuals can be identified from the data (whether directly or indirectly) without a “key” that allows the data to be re-identified.<br />

                              <b>Anonymization:</b> Data amended in such a way that no individuals can be identified from the data (whether directly or indirectly) by any means or by any person.<br />


                        </p>

                        <p style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>COMPLAINTS HANDLING</p>
                        <p>
                              When you make a complaint, an investigation of the complaint will be carried out to the extent that is appropriate based on the merits of the specific case. We will inform you of the progress and the outcome of the complaint within a reasonable period.
                              If the issue cannot be resolved through consultation between you and us, you may have the right to seek redress through mediation, binding arbitration, litigation, or via complaint to the Data Protection Authority within the applicable jurisdiction.
                        </p>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                              <span style={{ color: "#000", fontFamily: "ProductSans", fontSize: "16px", fontWeight: 400 }}>EFFECTIVE DATE</span>
                              <span>This policy is effective as of February 1, 2023.</span>
                        </div>


                  </div>

            </div>


      );
};

export default Privacy;