import React, { useState, useEffect, useRef } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, reset } from '../../features/auth/authSlice';
import { Person } from '@mui/icons-material/';
import logo from '../../assets/diyo.svg';
import { formatDate, formatNumber } from '../../utils/plugins';
// Material Design Dashoboard Menu
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import { formatBudget } from '../../utils/plugins';
// Material Design Button
import { DateTime } from 'luxon';
import ExpandMore from '@mui/icons-material/ExpandMore';
import './Campaignreport.css';
import Storage from '../../utils/storage';
import { useReactToPrint } from 'react-to-print';
import api from '../Api/Indx';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from 'recharts';
import Alert from '../../utils/alert';

function Campaignreport() {
  // handles Dashboard Menu
  const [loadings, setLoadings] = useState({});
  const { user } = useSelector((state) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [campaignData, setCampaignData] = React.useState({});
  const open = Boolean(anchorEl);
  const dateFormats = {
    day: 'D',
    hour: 'f',
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handles Campaign Id details
  const { campaignId } = useParams();
  const userinfo = JSON.parse(Storage.get('user'));

  // Handles Logout
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout());
    dispatch(reset());
    window.location = '/login';
  };

  //  navigate('/Set')
  const Createset = () => {
    window.location = '/my-profile';
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  // Handles Card details
  const [targetid, setTargetId] = useState([]);
  const [cmpidElements, setCmpidElements] = useState([]);
  const [campid, setCampid] = useState([]);
  const [hourReport, setHourReport] = useState([]);
  const [buttonState, setButtonState] = useState('hour');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(30);

  useEffect(() => {
    fetchCampaign(campaignId);
  }, [campaignId]);

  useEffect(() => {
    fetchReport(buttonState);
  }, [buttonState, campaignData]);

  const fetchReport = async (granularity) => {
    let report = {
      granularity,
      start_date: campaignData.startDate,
      end_date: campaignData.endDate || '2029-02-03T16:42:41+00:00',
    };
    const res = await api.getReport(campaignId, report);
    const dataPoints = res?.payload?.report.dataPoints || [];
    setCmpidElements(res.payload.campaign);
    // console.log(cmpidElements, "cmpidElements")
    setTargetId(res.payload.campaign.targets);

    // sort the dataPoints by key in ascending order
    const sortedDataPoints = dataPoints.sort((a, b) => {
      return new Date(a.key) - new Date(b.key);
    });

    setCurrentPage(currentPage);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setHourReport(sortedDataPoints.slice(startIndex, endIndex));
  };

  const fetchCampaign = async (id) => {
    try {
      const res = await api.getCampaign(id);
      setCampaignData({ ...res.payload.campaign });
    } catch (e) {
      console.error(e);
    }
  };
  const refundCampaign = async (e, campaign) => {
    e.preventDefault();
    try {
      setLoadings({ ...loadings, refunding: true });
      await api.refundCampaign({ id: campaign.id });
      Alert({ type: 'success', message: 'Campaign refund successful.' });
      await fetchReport(buttonState);
    } catch (e) {
      Alert({ type: 'error', message: (e && e.msg) || 'Unable to complete request' });
    } finally {
      setLoadings({ ...loadings, refunding: false });
    }
  };

  const expendCampaignBudget = async (e, campaign) => {
    e.preventDefault();
    try {
      setLoadings({ ...loadings, extending: true });
      await api.expendCampaignBudget({ id: campaign.id });
      Alert({ type: 'success', message: 'Campaign extension successful.' });
      await fetchReport(buttonState);
    } catch (e) {
      Alert({ type: 'error', message: (e && e.msg) || 'Unable to complete request' });
    } finally {
      setLoadings({ ...loadings, extending: false });
    }
  };

  const requestRefundCampaign = async (e, campaign) => {
    e.preventDefault();
    try {
      setLoadings({ ...loadings, requesting: true });
      await api.requestRefundCampaign({ id: campaign.id });
      Alert({ type: 'success', message: 'Refund request sent.' });
    } catch (e) {
      Alert({ type: 'error', message: (e && e.msg) || 'Unable to complete request' });
    } finally {
      setLoadings({ ...loadings, requesting: false });
    }
  };

  const fetchcampid = async () => {
    let datacamp = {
      adunit_ids: targetid,
    };

    const _res = await api.listAdunits(datacamp);
    setCampid([..._res.payload.adUnits]);
    // console.log(campid, "targetsss");
  };

  useEffect(() => {
    if (targetid.length > 0) {
      fetchcampid();
    }
  }, [targetid]);

  const Crecamp = () => {
    window.location = '/create-campaign';
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40, // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30, // this is needed to tell the amount of px that should be visible.
    },
  };

  // BreadCrumb
  function handleClickBrd(event) {
    event.preventDefault();
    window.location = '/campaign-list';
  }

  const CustomTooltip = ({ payload, active }) => {
    if (active && payload) {
      return (
        <div className='bg-white border border-[#ccc] p-3 whitespace-nowrap'>
          <p className='label'>{DateTime.fromISO(payload[0].payload.key).toFormat('fff')}</p>
          <p className='key text-[#8884d8]'>Impressions: {payload[0].payload.impressions}</p>
          <p className='key text-[#8884d8]'>Plays: {payload[0].payload.plays}</p>
        </div>
      );
    }

    return null;
  };

  const showActionButtons = (campaign) => {
    if (campaign.endDate == null) return false;
    const currentTime = DateTime.now();
    const dateTimeWithZone = DateTime.fromISO(campaign.endDate);
    const { hours } = currentTime.diff(dateTimeWithZone, ['hours']).toObject();
    return hours > 4 && campaign.paymentStatus === 'PAID';
  };

  return (
    <div className='campReportt' ref={componentRef}>
      <div className='navparent'>
        <nav className='nav1'>
          <NavLink to='/dashboard'>
            <img src={logo} className='navlogo' />
          </NavLink>

          <h3 className='navtitle'>Dashboard</h3>

          <div className='navsidemenu'>
            <span className='navtitleName' onClick={handleClick}>
              {' '}
              {userinfo?.firstName}
            </span>
            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Tooltip title='Account settings'>
                  <IconButton
                    onClick={handleClick}
                    size='small'
                    sx={{ ml: 1 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 29, height: 32 }}>
                      <ExpandMore />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 29,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 8,
                      height: 8,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={Createset}>
                  <Person style={{ color: '#5E16EB' }} /> My Profile
                </MenuItem>

                <MenuItem onClick={onLogout}>
                  <Logout style={{ color: '#5E16EB' }} fontSize='small' />
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          </div>
        </nav>

        <nav className='nav2'>
          <ul className='navlist'>
            <NavLink
              to='/dashboard'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
            >
              Home
            </NavLink>
            <NavLink
              to='/campaign-list'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
            >
              Campaign List
            </NavLink>
          </ul>

          <button onClick={Crecamp} className='cmplstCmp'>
            Create campaign
          </button>
        </nav>
      </div>

      <div className='reportcamp'>
        <div role='presentation' onClick={handleClickBrd} className='asetCbt2'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link
              underline='hover'
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: '#5E16EB',
                fontFamily: 'ProductSans',
                textDecoration: 'none',
                cursor: 'pointer',
              }}
            >
              <ArrowBackIosIcon sx={{ mr: 0.5 }} fontSize='inherit' />
              Campaign List
            </Link>
          </Breadcrumbs>
        </div>

        <div className='asetCbt'>
          <div className='asetinfocard flex flex-col gap-2'>
            <p>
              Campaign Name: <span>{cmpidElements.name}</span>
            </p>

            {user.kind === 'INTERNAL' && campaignData && (
              <p>
                Account Email: <span>{campaignData?.accountInfo?.email}</span>
              </p>
            )}
            <p>
              Campaign Start Date: <span>{formatDate({ date: cmpidElements?.startDate })}</span>{' '}
            </p>
            {cmpidElements?.endDate && (
              <p>
                Campaign End Date: <span>{formatDate({ date: cmpidElements?.endDate })}</span>{' '}
              </p>
            )}
            <p>
              Campaign Status: <span>{cmpidElements.status}</span>
            </p>
            <p style={{ cursor: 'pointer', color: '#5E16EB' }} onClick={handlePrint}>
              Download invoice
            </p>
            <>
              {showActionButtons(cmpidElements) && (
                <div className={'flex items-center flex-wrap gap-2'}>
                  <>
                    <button
                      onClick={(e) => expendCampaignBudget(e, cmpidElements)}
                      className={'text-sm bg-[#F44336] border text-white rounded-lg px-3 py-2'}
                    >
                      Extend Campaign
                    </button>
                    <button
                      onClick={(e) => requestRefundCampaign(e, cmpidElements)}
                      className={'text-sm bg-[#F44336] border text-white rounded-lg px-3 py-2'}
                    >
                      Request Refund
                    </button>
                  </>
                  <>
                    {user.kind === 'INTERNAL' && (
                      <button
                        onClick={(e) => refundCampaign(e, cmpidElements)}
                        className={'text-sm bg-[#F44336] border text-white rounded-lg px-3 py-2'}
                        disabled={loadings?.refunding}
                      >
                        {loadings?.refunding ? 'Processing...' : 'Refund Campaign'}
                      </button>
                    )}
                  </>
                </div>
              )}
            </>
          </div>

          <div className='campaignview-buttons'>
            <button
              className={buttonState === 'hour' ? 'active' : ''}
              onClick={() => setButtonState('hour')}
            >
              Hourly
            </button>
            <button
              className={buttonState === 'day' ? 'active' : ''}
              onClick={() => setButtonState('day')}
            >
              Daily
            </button>
            {/*<button*/}
            {/*  className={buttonState === 'week' ? 'active' : ''}*/}
            {/*  onClick={() => setButtonState('week')}*/}
            {/*>*/}
            {/*  Weekly*/}
            {/*</button>*/}
          </div>
        </div>

        <div className='grapdiv'>
          <div className='liedown'>
            <div className='impcounts'>
              <p>Impressions/Reach</p>
              <p>{formatNumber(campaignData?.billing?.impressions)}</p>
            </div>

            <div className='impcounts'>
              <p>Actual $USD Spent</p>
              <p>
                {formatBudget({
                  cost: campaignData?.billing?.spend,
                  currency: campaignData?.billing?.currency,
                })}
              </p>
            </div>

            <div className='impcounts'>
              <p>Ad plays</p>
              <p>{formatNumber(campaignData?.billing?.adPlays)}</p>
            </div>
          </div>
          <ResponsiveContainer width={'100%'} height={250} display='flex' alignItems='flex-start'>
            <AreaChart
              width='100%'
              height='fit-content'
              data={hourReport}
              margin={{ top: 10, right: 0, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id='colorUv' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor='#8884d8' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#8884d8' stopOpacity={0} />
                </linearGradient>
                <linearGradient id='colorPv' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor='#9E73F3' stopOpacity={0.8} />
                  <stop offset='95%' stopColor='#9E73F3' stopOpacity={0} />
                </linearGradient>
              </defs>
              <YAxis />
              <XAxis
                dataKey='key'
                tickFormatter={(key) => {
                  return (
                    (DateTime.fromISO(key).isValid &&
                      DateTime.fromISO(key).toFormat(dateFormats[buttonState])) ||
                    'No Data available'
                  );
                }}
              />
              <CartesianGrid strokeDasharray='3 3' />
              <Tooltip content={<CustomTooltip />} />
              <Area
                type='monotone'
                dataKey='impressions'
                stroke='#8884d8'
                fillOpacity={1}
                fill='url(#colorUv)'
              />
              {/* <Area type="monotone" dataKey="impressions" stroke="#5E16EB" fillOpacity={1} fill="url(#colorPv)" /> */}
            </AreaChart>
          </ResponsiveContainer>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }} className={'pb-6'}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              marginTop: '20px',
              marginLeft: '50px',
            }}
          >
            <p style={{ fontFamily: 'ProductSans', color: '#5E16EB' }}>Selected Screen Details</p>
          </div>

          <Carousel partialVisible={true} responsive={responsive} className='Carousel'>
            {campid.map((item) => (
              <div className='cardReport flex flex-col lg:flex-row gap-4 justify-between' key={item.id}>
                <div>
                  <div className='SdetColImage'>
                    <img
                      className='asstimg'
                      src={item?.asset?.imageUrl || '/placeholder.svg'}
                      alt={'screen image'}
                    />
                  </div>
                </div>
                <div>
                  <div
                    className='cardReportParag'
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <p style={{ color: '#5E16EB', fontFamily: 'ProductSans' }}>Address: </p>{' '}
                    <span style={{ color: '#000', fontFamily: 'ProductSans' }}>
                      {item?.venue?.address}
                    </span>
                  </div>
                  <div
                    className='cardReportParag'
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <p style={{ color: '#5E16EB', fontFamily: 'ProductSans' }}>Category: </p>{' '}
                    <span style={{ color: '#000', fontFamily: 'ProductSans' }}>
                      {item?.asset?.category}
                    </span>
                  </div>

                  {/* <div className="cardReportParag" style={{ display: "flex", alignItems: "center", gap: 5, cursor: "pointer" }} onClick={() => Streetnav(item.location?.latitude, item.location?.longitude)}> */}
                  {/* <p style={{ color: "#5E16EB", fontFamily: "ProductSans", }}>Address: </p> <span style={{ color: "#000", fontFamily: "ProductSans" }}>{item?.venue?.address}</span> */}
                  {/* <p  onClick={() => Streetnav(item.location?.latitude, item.location?.longitude)}>Address:  <span className="impspan">Street View</span></p> */}
                  {/* </div> */}

                  <div
                    className='cardReportParag'
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <p style={{ color: '#5E16EB', fontFamily: 'ProductSans' }}>Weekly reach: </p>{' '}
                    <span style={{ color: '#000', fontFamily: 'ProductSans' }}>
                      {item?.measurement?.fourWeekImpression}
                    </span>
                  </div>

                  <div
                    className='cardReportParag'
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <p style={{ color: '#5E16EB', fontFamily: 'ProductSans' }}>Publisher: </p>{' '}
                    <span style={{ color: '#000', fontFamily: 'ProductSans' }}>
                      {item?.publisher}
                    </span>
                  </div>

                  <div
                    className='cardReportParag'
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <p style={{ color: '#5E16EB', fontFamily: 'ProductSans' }}>Resolution: </p>{' '}
                    <span style={{ color: '#000', fontFamily: 'ProductSans' }}>
                      {item?.slot?.width}/{item?.slot?.height}
                    </span>
                  </div>

                  <div
                    className='cardReportParag'
                    style={{ display: 'flex', alignItems: 'center', gap: 5 }}
                  >
                    <p style={{ color: '#5E16EB', fontFamily: 'ProductSans' }}>
                      Ad play duration:{' '}
                    </p>{' '}
                    <span style={{ color: '#000', fontFamily: 'ProductSans' }}>
                      {item?.measurement?.duration}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
}

export default Campaignreport;
