import React from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import { NavLink } from "react-router-dom";
import "../Desktop/Campaign.css"




const Terms = () => {


      return (



            <div className='faqiii'>

                  <div className="navparent">

                        <nav className="nav1">

                              <NavLink to="/dashboard"><img src="diyo.svg" className='navlogo' /></NavLink>

                              
                              <h3 className="navtitle">Terms and conditions - DIYO</h3>


                              <div className="priacynavsidemenu">

                              </div>

                        </nav>


                  </div>

                  <div className="faqlist">


                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <h2 style={{ color: "#000", fontFamily: "ProductSans" }}>
                                    
                              </h2>
                        </div>



                        <p style={{ fontFamily: "ProductSans" }}>

                              This Agreement as defined herein has the effect to legally bind the individual or entity which is named under a given Account (“User”, “You”, or “Your”) and DIYO Inc. having a place of business at 8 The Green Ste R, Dover, DE 19901  (“DIYO”).  You and DIYO shall each be referred to herein as a “Party” and collectively as the “Parties”.
                              By using the service, accessing the Website, and requesting an Account, the User accepts and agrees to the terms and conditions of this Agreement and the content and the operation of DIYO services.


                        </p>


                        <p style={{ marginTop: 20 }}>

                              <b>DEFINITIONS </b> <br />

                              “Account” means the online account provided by DIYO to enable User’s access to and use of the Website.  An Account may be requested as detailed under Section 6 below.
                              “Advertiser” means the advertiser or the client for whom an Agency is the legally authorized agent regarding an applicable Campaign.
                              “Advertising Opportunity” means the opportunity to display a Creative on a Screen as bid on and potentially purchased using the DSP.
                              “Agreement” means these terms and conditions, any Campaign(s), DIYO’s Privacy Policy and DIYO’s email confirmations.
                              “API” means application program interface.
                              “Audience Targetting” means mobile audience data such as age group, household income, life stage, interest and activities which allows User to profile venues in which Screens are found.
                              “Campaign” means an organized course of action to promote a product or service in which User specifies Advertiser’s identity, specific industry and the Campaign’s name. This is the top level of DIYO’s structure.  The structure is as follows: Dashboard → Campaign → Creative.  Campaigns and Creatives exist within a Campaign and exist in a 1:1 relationship where one unique creative is mapped to one unique Campaign.
                              “Confidential Information” means (i) all information marked as “Confidential”, “Proprietary”, or with a similar legend by the disclosing party (“Discloser”) when given to the receiving party (“Recipient”); and (ii) information and data provided by the Discloser, which under the circumstances surrounding the disclosure, should be reasonably deemed confidential or proprietary.
                              “CPM” means the cost per thousand advertising impressions, an industry standard measure for selling advertisements.
                              “Creative” means any material, artwork, copy, or text, whether video or photo provided by User to be delivered on the Screens in respect to a given Campaign.
                              “DSP” means the Demand Side Platform software and associated services made available to User through the Website for the purpose of purchasing Advertising Opportunities.
                              “Intellectual Property Rights” means any invention, discovery, idea, process, patent, patent application, trademark, services mark, logo, brand, industrial design, work of authorship, copyrightable document or object, development, concept, trade secret, or technical or commercial know-how whether registered or unregistered, including, without limitation, any hardware, software, price lists, specifications, or technical data used by DIYO or regarding the Website, DSP, or related components.
                              “Campaign” means an ordering document that allows User to specify a given Campaign into different advertising channels based on the audience targeting, the budget and the duration.  For a given Campaign, Users can create one or multiple Campaigns for each of their audience segments and assign each Campaign a different Creative, budget and schedule.  “Representative” means, as to an entity and/or its Affiliate(s), any director, officer, employee, consultant, contractor, agent, and/or attorney.
                              “SSP” means Sell Side Platform software and associated services made available to Sellers for the purposes of selling ad opportunities.
                              “Screens” or “Billboards” means Digital Out-of-Home advertising products specified in a Campaign that are not necessarily owned, operated, or controlled by DIYO, but on which DIYO has a contractual right to deliver advertisements.
                              “Third Party” means an entity or person that is not a party to this Agreement; for purposes of clarity, User, DIYO, Agency, Advertiser, and any Affiliates or Representatives of the foregoing are not Third Parties.
                              “Website” means the direct buying platform for digital media buyers hosted on DIYO’s proprietary website (<a style={{ textDecoration: "none", fontWeight: 600 }} href="http://diyo.ca">DIYO.ca</a>).
                        </p>

                        <p style={{ marginTop: 20 }}>

                              <b>AUTORISATION TO USE ELECTRONIC FILES </b> <br />

                              You hereby authorize the exchange of documents and information between You and DIYO through the Website and by email.
                              You agree that all the agreements, policies, notices, information and other communications made available by DIYO on
                              its Website or send to You electronically meet the applicable legal requirements.<br /><br />

                              ii. TERM
                              This Agreement is in effect as of the date You receive Your registration confirmation by email and ends on the date DIYO confirms that said
                              registration is terminated in accordance with Section 14 of this Agreement (“Term”).<br /><br />

                              iii. LICENCE
                              Subject to this Agreement, DIYO grants User a limited, revocable, non-exclusive, non-transferable, non-assignable,
                              non-sublicensable right to access and use the Website during
                              the Term, in accordance with the terms and conditions set forth herein.



                        </p>


                        <p style={{ marginTop: 20 }}>


                              <b>USER’S OBLIGATIONS</b> <br />
                              The Website and its content may be used solely to the extent detailed in this Agreement.  You shall not use the Website for any purpose other than as set forth herein and You shall not misuse the Website in any manner whatsoever.
                              Without limiting the foregoing, User agrees not to access or use the Website:<br /><br />

                              1. for any purpose other than to buy Advertising Opportunities or plan the purchase of Advertising Opportunities through the Website.<br />
                              2. in such manner that could damage, interrupt the services, overload, or otherwise compromise the proper use of the Website;<br />
                              3. to disrupt the security or otherwise make abusive use of the Website, nor of any of its services, resources, servers and networks or of any of its affiliated or related websites;<br />
                              4. to disturb the use by Third Parties of the Website or any of its affiliated or related websites;<br />
                              5. to download, post, display, or otherwise transmit onto the Website any virus or other file or computer program that might be damaging, harmful or disruptive;<br />
                              6. to make the use of a robot, automatic software or of any manual process in order to control or to copy of any of the Website’s content, except as expressly contemplated herein;<br />
                              7. to transmit through the Website emails, chain letters, spam or other types of non-solicited communications;<br />
                              8. to use DIYO’s or any Third Party’s trademarks, marketing materials or other intellectual property rights or content except as specifically set forth herein;<br />
                              9. to reverse engineer, decompile or disassemble the Website, the DSP or any components thereof or create any derivative works of the same;<br />
                              10. to use the Website or DSP in a manner that violates any applicable governmental laws, rules or regulations or any intellectual property rights, privacy rights or any policies of any Third Party;<br />
                              11. to try to obtain non-authorized access to the Website or to certain portions of the Website to which access is limited.  Moreover, the User agrees that it alone is responsible for any and all actions and communication undertaken or transmitted, in its name or on its behalf, and agrees to fully respect and abide by any and all applicable legislation regarding its use and activities related to the Website;<br />
                              12. to use Your Account in any means which is intended to result in complaints, claims, fees, fines, penalties or any other liability for DIYO;<br />
                              13. to copy, license, sublicense, sell, resell, rent, lease, transfer, assign, distribute or otherwise commercially exploit or make available the Website, the DSP or any components thereof except as expressly contemplated herein;<br />
                              14. to use any anonymising proxy to access the Website;<br />
                              15. to use a credit card to make a payment to Your Account which is not authorized by said credit card holder;<br />
                              16. to act in any way that could disable, over-burden or impair DIYO’s servers or the proper operation of the Website; and<br />
                              17. to use the Website with the intent to gain competitive intelligence or to perform market research for the purpose of creating a product or service similar in nature or design to the Website.

                        </p>


                        <p style={{ marginTop: 20 }}>

                              <b>REGISTRATION AND ORDERS</b> <br />

                              <b>a.</b> Registration.  In order to access and use the Website, User must request
                              an Account following the procedure set forth on the Website.
                              User shall be considered registered on the Website when User receives registration
                              confirmation from DIYO.  User may use the Website until User voluntarily unregisters or until DIYO cancels User’s registration.
                              Any individual who accesses and uses the Website must be at least 18 years old or the age of majority in its jurisdiction, whichever is greater.
                              If an individual does not meet the minimum age requirement, such individual may not make use of the Website.<br />

                              <b>b.</b> Requesting an Account.  By requesting an Account, You warrant to DIYO that:<br />

                              1. You and any individual that uses Your Account, have the full power, authority and legal capacity to enter into this Agreement;<br />
                              2. the execution of this Agreement and performance of Your obligations under this Agreement do not violate any other right or obligation resulting from other agreements to which You are a party.<br /><br />

                              <b>c.</b> Confirmation of information provided.  You authorize DIYO, either directly or through its Third-Party agents,
                              to make any investigation DIYO considers necessary to validate Your identity.  Such investigation may include,
                              without limiting the generality of the foregoing, enquiring for further information regarding the identification and contact information You have provided, ordering a credit report and / or verifying the information you provide through Third Party assistance.
                              You are responsible for: <br />
                              1. providing DIYO with correct and accurate information;
                              2. ensuring the confidentiality of Your Account, including Your login and password; and
                              3. operations that are conducted under Your Account.<br /><br />

                              <b>d.</b>Unauthorized access.  You must immediately notify DIYO if You believe a Third Party has accessed Your Account without authorization.
                              Such notices to DIYO shall be sent to: contact @<a style={{ textDecoration: "none", fontWeight: 600 }} href="http://diyo.ca">diyo.ca</a>. <br /><br />

                              <b>e.</b> Campaign.  Prior to placing a Campaign through the Website, the User must be registered and create a Campaign.<br /><br />


                              From time to time, User may place one or more Campaigns for which DIYO will deliver Creatives to Screen(s) for User’s benefit.
                              Information contained on the Website constitutes an invitation to treat.  No such information constitutes or should be deemed as an offer by DIYO.  When User submits a Campaign, User makes an offer to Sellers to purchase the Advertising Opportunities specified in the Campaign in accordance with the purchase prices and other applicable fees, and terms, of this Agreement.
                              The acceptance of a Campaign by DIYO is confirmed by means of an email sent to User at the time such Campaign has reached its starting date.<br /><br />


                        </p>


                        <p style={{ marginTop: 20 }}>

                              <b>CREATIVE PLACEMENT AND POSITIONING</b> <br />

                              <b>a.</b>  Targeted audience Campaigns.  The targeting data such as but not limited to audience segments made available to the User within a Campaign are an indicative measurement based on historical figures and projections.  DIYO endeavours to deliver each Creative in accordance with the specifications of its given Campaign, provided however that User acknowledges and agrees that DIYO has no control over the context of the Screen such as the audience who may view the Screens and the Creatives and DIYO cannot guarantee the reach of audience segments or targeted contexts.<br />

                              <b>b.</b> Inventory availability; acceptance.  An estimate of the available Advertising Opportunities will be updated in real time according to the Campaign specifications. The availability of specified Screens may be limited from time to time.  You acknowledge and agree that upon purchase of an Advertising Opportunity, DIYO will use commercially reasonable efforts to deliver the appropriate Creative to Screens within the scope of the applicable Campaign.  DIYO may modify, move or remove Screens from its inventory at its sole discretion without prior notice.  The Screens are provided “as is” and “as available”, and DIYO does not guarantee that during delivery of a Creative under a Campaign (i) the Screens will be accessible or visible at all times and (ii) the display of the Creatives on the Screens will be uninterrupted or error-free.<br />

                              <b>c.</b> Website modification.  DIYO reserves the right to modify this Agreement, the Website and any of its content at any time in its sole discretion.<br />

                              <b>d.</b>Technical specifications.  DIYO will submit or otherwise make electronically accessible to User final technical specifications for Creatives, as agreed upon by the Parties.<br />

                              <b>e.</b>Screen ownership.  User acknowledges and agrees that Screens are owned and or operated by Sellers and DIYO is not responsible for keeping them in good working order or ensuring their continued function and proper display of Creatives.<br /><br /><br />

                        </p>


                        <p style={{ marginTop: 20 }}>

                              <b>REPORTING</b> <br />

                              <b>a.</b>Creative compliance.  User shall submit Creatives to DIYO and DIYO reserves the right, in its sole discretion, to reject or remove from the Screens any Creative that does not comply with any applicable law, regulation or other judicial or administrative order.  In addition, DIYO reserves the right to reject or remove from the Screens any Creative which brings or may tend to bring disparagement, ridicule, or scorn upon DIYO or any of its Affiliates, provided that if DIYO has reviewed and approved such Creative prior to its display on the Screens, DIYO will not immediately remove such Creative before making commercially reasonable efforts to obtain mutually acceptable alternative Creative from User.<br />

                              <b>b.</b>Viruses.  User must ensure that the Creatives are free of computer viruses.  The User shall use commercially available protection programs for this purpose and in accordance with current standards.<br />

                              <b>c.</b>Notification for non-compliant Creative.  Upon receipt of a Creative, DIYO will use commercially reasonable efforts to notify User of non-compliance within one (1) Business Day of receipt.<br />

                              <b>d.</b>Damaged Creative.  If a Creative provided by User is damaged, not in accordance with DIYO’s specifications, or otherwise unacceptable, DIYO will use commercially reasonable efforts to notify User within one (1) Business Day of receipt.<br />

                              <b>e.</b>Creative modification.  User hereby acknowledges that DIYO may resize Creatives without prior notification in order to match a Screen’s specifications.<br />

                              <b>f.</b> Content not retained.  User acknowledges and agrees that DIYO may not retain the Creatives after the end date of a Campaign or upon the expiry or termination of this Agreement.<br />

                              <b>g.</b>Use of Creatives.  You hereby acknowledge and agree that the Creatives You submit to DIYO may be represented and / or used in DIYO’s promotional material.<br />


                        </p>

                        <p style={{ marginTop: 20 }}>


                              <b>MANAGED SERVICES</b> <br />

                              If authorized by the User, DIYO will perform managed services on User’s behalf and
                              within User’s account and will set up and manage User’s Campaigns in accordance with User’s budget and criteria.
                              Should User wish to make use of DIYO’s managed services,
                              User must contact DIYO via live chat and the Parties can then discuss the associated fees,
                              budget and Campaign objectives.<br /><br />
                        </p>


                        <p style={{ marginTop: 20 }}>
                              <b>PRICES</b> <br />
                              Campaign pricing is based on a CPM model. Prices shown will include DIYO’s media cost, data fees, profit margin, as a single, bundled value.
                              DIYO does not guarantee that a Creative has in fact been fully viewed and can only assess and project impressions based on its statistical model.
                              Unless otherwise agreed upon, all prices will be in USD. For credit cards with a foreign reference currency, the conversion rate will be determined by the financial institution that issued such credit card.
                              Although DIYO endeavours to provide a precise description of its products’ prices, a risk of error remains.  DIYO reserves the right to correct its prices and any other erroneous information found on the Website.  If a price is in fact erroneous, DIYO reserves the right to cancel User’s Campaign or
                              Account and will communicate with User in order to either place a new Campaign or to proceed to a new registration in accordance with the amended price(s). <br /><br />

                        </p>



                        <p style={{ marginTop: 20 }}>

                              <b>PAYMENT AND PAYMENT LIABILITY</b> <br />
                              The payment methods are established at DIYO’s sole discretion.
                              In order to request an Account, User must provide DIYO with valid credit card information which will be used by DIYO to process automated payments powered by Stripe ©.
                              User’s credit card information is not retained by DIYO other than for identification purposes and is treated as personal information under DIYO’s Privacy Policy, provided however that it may be treated otherwise by Stripe ©, its affiliates, its service providers and other Third Parties.  You may find the applicable Privacy Policy and Stripe’s © terms and conditions at: https://stripe.com/ca/legal
                              Invoicing and payment will be automatically processed at the point of a campaign being created and credit card details being obtained by the User. If a campaign has a set budget, the campaign will continue to run indefinitely from the start time/date until the set budget has been exhausted.
                              If the campaign has a set start date/time and an end date/time, the campaign will run until the end date/time has been reached. If the user has paid more than what was invoiced through the campaign, the User’s credit card will be refunded the delta amount between paid and invoiced.
                              In the event a payment is refused, blocked, or otherwise not processed in accordance with the terms of this Agreement, DIYO will notify the User and further reserves the right to suspend, cancel or terminate User’s Account in accordance with Section 14 of this Agreement.
                              If DIYO is unable to fulfill its obligations pursuant to this Agreement, during one or more display periods, User hereby acknowledges that DIYO’s liability shall be limited to the substitution of an equivalent period as determined by DIYO. Furthermore, if DIYO is unable to offer locations which were initially offered or chosen by User during one or more display periods, User hereby acknowledges that DIYO’s
                              liability shall be limited to the substitution of such location during one or more of the display periods, as determined by DIYO, while complying, upon availability and feasibility, with any special instructions.<br /><br />
                        </p>


                        <p style={{ marginTop: 20 }}>
                              <b>Taxes.</b> <br />
                              All Fees are exclusive of any taxes, levies, duties or similar governmental assessments of any nature including, value-added, sales, use or withholding taxes, assessable by any jurisdiction whatsoever (collectively, “Taxes”).  User is responsible
                              for self-assessment of and self-remittance of any and all Taxes associated with this Agreement to the applicable collecting agency or party.<br /><br />
                        </p>


                        <p style={{ marginTop: 20 }}>

                              <b>SUSPENSION, CANCELLATION AND TERMINATION</b> <br />
                              <b>a.</b>Either Party may terminate this Agreement: (a) by providing the other Party with no less than thirty (30) days’ prior written notice; (b) immediately upon notice if the other Party materially breaches any of its obligations hereunder; or (c) immediately upon notice in the event of the suspension of business, insolvency, institution of bankruptcy or liquidation proceedings by or against the other Party.  Any outstanding Campaigns, Accounts or Campaigns under this Agreement shall also terminate effective upon the date of termination of this Agreement, provided that any guaranteed Campaigns shall terminate on the date the Campaign is satisfied, if later than the termination date of this Agreement. <br />


                              <b>b.</b>Suspension or termination of Account.  Notwithstanding the provisions of Section 14 (a), DIYO reserves the right to suspend or terminate Your Account immediately if it has reason to believe that You have not complied with this Agreement.<br />


                              <b>c.</b> Effect of Account termination.  If Your Account is terminated for any reason, this Agreement is terminated and Your right to use and access the Website is immediately revoked.  In such case, at DIYO’s request, You must immediately destroy all copies of materials and reports downloaded from the Website that are in Your possession or control, and immediately cease accessing the Website.<br />

                              <b>d.</b>Consequences of expiration or termination of the Agreement.  Expiration or termination of the Agreement shall not release the Parties from any liability or obligation which, at the time of such expiration or termination, has already accrued or which thereafter may accrue with respect to any act or omission before termination, or from any obligation which is expressly stated in this Agreement to survive termination.<br />

                        </p>


                        <p style={{ marginTop: 20 }}>
                              <b>INTELLECTUAL PROPERTY RIGHTS</b> <br />
                              <b>a.</b>All right, title and interest in and to the Website, DSP and related components, including all intellectual property rights therein, are and will remain with DIYO. You have no right, license or authorization with respect to any of the Website, DSP or related components.
                              All other rights in and to the Website, DSP and related components are expressly reserved by DIYO.<br />

                              <b>b.</b>Reports. You may exclusively use the reports made available through the Website for Your internal business use only, subject to clearly identifying on each copy the appropriate mentions and notices pertaining to DIYO’s trademarks. This Agreement grants You the right to consult, display, print and download the reports made available on the Website, for Your internal business use only, provided however that such reports will remain unaltered, unmodified, and safe of any manipulations other than those made by DIYO. For further clarity, internal use refers to analysis, justification and reporting purposes. You will not attribute, transfer, assign or license such reports to a Third Party. The text, images and data found on the Website and downloaded, copied, saved or printed therefrom remain subject to the present Agreement. It is Your obligation to ensure that any and all notices pertaining to DIYO trademarks appear and remain visible on any and all information taken from DIYO’s Website. In order to obtain further information regarding the authorization to use, reproduce, display or publish any and all information taken from the Website, You may communicate with DIYO via its online chat.
                              Except as otherwise set forth herein, under no circumstances might any provision of the present Agreement be construed as conferring any license or any other right pertaining to DIYO’s trademarks and copyrights.
                              Reports cannot be used for commercial and/or profit driven activities. Under no circumstances shall User receive a payment or other compensation, monetary or otherwise, in exchange for reports made available through the Website.<br />

                              <b>c.</b> Licence to DIYO. You grant DIYO a licence to sublicense, use and reproduce Your Creatives for the purpose of performing its obligations under this Agreement.
                              You warrant and represent that: <br /><br />

                              1. Your Creatives do not infringe any intellectual property rights;<br />
                              2. You are the rightful owner of the Creative or have the right to use it to the extent provided by this Agreement;<br />
                              3. In the event that any of Your Creatives are owned by a Third Party, You have obtained the necessary permission for DIYO to sublicense, use and reproduce such Creative for the purpose of performing its obligations under this Agreement.<br /><br />
                        </p>



                        <p style={{ marginTop: 20 }}>
                              <b>SOCIAL MEDIA</b> <br />
                              <b>a.</b>The following conditions apply when You access a social media page, an account, a website, a network or any application created by DIYO (hereinafter collectively referred to as the “Social Media”).
                              a. General statement. When using Social Media pages, and submitting comments, pictures, videos or any other element, You accept to be bound by and to comply with this Agreement as well as to the third party social media providers’ terms and conditions.
                              You also agree that DIYO may copy, edit, publish, translate and / or distribute Your comments, pictures, videos or other content that You have submitted on social media with no consideration owed to You.
                              b. Conditions governing the Use of Social Media.
                              Certain comments and other displayed information on the Social Media pages may not represent DIYO’s opinions and general policy and You hereby accept not to publish or submit any information, publication or any element of any kind on DIYO’s Social Media pages which may fall under the following non-exhaustive categories:<br /><br />

                              1. Any defamatory content;<br />
                              2. Offensive;<br />
                              3. False or counterfeit;<br />
                              4. Obscene;<br />
                              5. Misleading;<br />
                              6. Illegal;<br />
                              7. Which might violate in any manner the rights of a third party;<br />
                              8. Any content which includes an advertisement;<br />
                              9. Promotional documents or any type of undesirable solicitation for other users, persons or entities;<br />
                              10. Any and all content of which the origin and / or authenticity is forged or falsified;<br />
                              11. Any and all personal or financial information concerning User or any other person; and<br />
                              12. Any information which DIYO judges to be in contravention of the present Agreement.<br /><br />

                              Any information, message, link, or element which DIYO considers being part of any above mentioned category will be immediately deleted with no liability whatsoever towards DIYO.
                              Furthermore, DIYO shall not be responsible for viewing and screening the information, messages, links, or elements published or posted on its social media.
                              You hereby agree to hold harmless and indemnify DIYO from any liability in regard to the entities which hold or operate the websites which provide Social Media platforms or pages, in particular for any and all losses or damages,
                              arising directly or indirectly from any measure or decision taken by You or by another person regarding the use of said websites which provide Social Media platforms or pages.
                        </p>




                        <p style={{ marginTop: 20 }}>
                              <b>NON-DISCLOSURE, DATA OWNERSHIP, PRIVACY AND LAWS</b> <br />
                              DIYO is committed to protecting Your privacy. Only authorized employees, agents, Affiliates, or Third Parties on a need-to-know basis may use any information collected from User.<br />

                              <b>a.</b> Obligations.<br />
                              Recipients will protect confidential Information in the same manner that it protects its own information of a similar nature, but in no event with less than reasonable care. Recipient shall not disclose Confidential Information to anyone except an employee, agent, Affiliate, employee of an Affiliate or Third Party who has a need to know same pursuant to this Agreement, and who is bound by confidentiality and non-use obligations at least as protective of Confidential Information as are those in this Agreement. Recipients will not use Discloser’s Confidential Information other than in order to perform its obligations under this Agreement or as may be otherwise specifically permitted by the discloser in writing.
                              Recipient agrees to promptly notify discloser if it appears that the Discloser’s Confidential Information has been compromised, or if it is required by law to disclose the Confidential Information and upon discloser’s request, immediately return or destroy the Confidential Information and all documents and materials containing or embodying the Confidential Information which are in its possession or control.<br />

                              <b>b.</b> Exceptions. Notwithstanding anything contained herein to the contrary, and except for information about an identifiable individual, the term “Confidential Information” will not include information which: (i) was previously known to Recipient; (ii) was or becomes generally available to the public through no fault of Recipient;
                              (iii) was rightfully in Recipient’s possession free of any obligation of confidentiality at, or prior to, the time it was communicated to Recipient by Discloser; (iv) was developed by employees or agents of Recipient independently of, and without reference to, Confidential Information; or (v) was communicated by Discloser to an unaffiliated Third Party free of any obligation of confidentiality.
                              Notwithstanding the foregoing, the Recipient may disclose Confidential Information of the Discloser in response to a valid order by a court or other governmental body, as otherwise required by law, or as necessary to establish the rights of either party under this Agreement; provided, however,
                              that both Discloser and Recipient will stipulate to any orders necessary to protect such information from public disclosure.<br />


                              <b>c.</b>Privacy Policies. User and DIYO will post on their respective websites or otherwise make available to the other Party their privacy policies and adhere to their privacy policies which will abide to applicable laws.<br />


                              <b>d.</b>Survival of obligations. This Section 17 survives expiry or termination of this Agreement.<br />



                        </p>





                        <p style={{ marginTop: 20 }}>
                              <b>INDEMNIFICATION</b> <br />
                              The User agrees to indemnify and hold DIYO and its officers, directors, employees and agents and its and their respective successors, heirs and assigns (the “DIYO Parties”) harmless from and against any and all losses, damages, liabilities, costs and expenses (including reasonable attorneys’ fees) resulting from any third party claim arising out of or in connection with User’s breach of any representation, warranty or obligation set forth in this Agreement or any Campaign. User will provide DIYO with prompt notice of any claim and at User’s expense,
                              provide information and assistance reasonably necessary to defend such claim. User will not enter into any settlement or compromise that would result in any liability to any DIYO Party without DIYO’s prior written consent.



                        </p>




                        <p style={{ marginTop: 20 }}>
                              <b>FORCE MAJEURE</b> <br />
                              Excluding payment obligations, neither Party will be liable for delay or default in the performance of its respective obligations under this Agreement if such delay or default is caused by conditions beyond its reasonable control, including but not limited to, fire, flood, accident, earthquakes, telecommunications (including internet, mobile and other technologies) network failures, electrical outages, acts of God, or labour disputes (“Force Majeure”).<br /><br />

                        </p>



                        <p style={{ marginTop: 20 }}>
                              <b>DISCLAIMER: LIMITATION OF LIABILITY</b> <br />

                              EXCEPT AS EXPLICITLY SET FORTH HEREIN, THE WEBSITE,
                              DSP AND ANY RELATED SERVICES ARE PROVIDED “AS IS” AND DIYO
                              HEREBY DISCLAIMS ALL WARRANTIES, WHETHER EXPRESS, IMPLIED,
                              STATUTORY OR OTHER, AND DIYO SPECIFICALLY DISCLAIMS ALL IMPLIED
                              WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                              TITLE AND NON-INFRINGEMENT, AND ALL WARRANTIES ARISING FROM COURSE
                              OF DEALING, USAGE OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING,
                              DIYO MAKES NO WARRANTY OF ANY KIND THAT THE SERVICES OR PROVIDER MATERIALS,
                              OR ANY PRODUCTS OR RESULTS OF THE USE THEREOF, WILL MEET YOUR OR ANY
                              OTHER PERSON’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE
                              ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE,
                              SYSTEM OR OTHER SERVICES OR BE SECURE, ACCURATE, COMPLETE, FREE
                              OF HARMFUL CODE OR ERROR FREE. ALL THIRD-PARTY MATERIALS ARE
                              PROVIDED “AS IS” AND ANY REPRESENTATION OR WARRANTY OF OR
                              CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY BETWEEN YOU AND
                              THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD PARTY MATERIALS.
                              IN NO EVENT WILL DIYO, ITS AFFILIATES OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU FOR ANY LOST PROFITS OR ANY SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE OR OTHER INDIRECT DAMAGES OF ANY NATURE, FOR ANY REASON, WHETHER BASED ON BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE AND WHETHER OR NOT EITHER HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
                              <br /><br />
                        </p>




                        <p style={{ marginTop: 20 }}>
                              <b>MISCELLANEOUS</b> <br />
                              <b>a.</b>
                              Assigning Rights. User may not resell, assign or transfer any of its rights or
                              obligations hereunder, and any attempt to resell, assign or transfer such
                              rights or obligations without DIYO’s prior written approval will be null
                              and void. DIYO may assign this Agreement without the prior written consent
                              of User. All terms and provisions of this Agreement will be binding upon and
                              inure to the benefit of the Parties hereto and their respective permitted transferees,
                              successors and assigns.<br />


                              <b>b.</b>

                              Entirety of the Agreement. This Agreement constitutes the entire Agreement between You and DIYO
                              and covers Your use of the Website and the transactions processed through the Website.
                              This Agreement replaces any and all other previous agreements, whether electronic,
                              verbal or written, in regard to the matters covered in the present Agreement, and it
                              can only be modified by writing in accordance with this Agreement. DIYO reserves the right,
                              in its sole discretion, to update, change or replace the terms of this Agreement or any part
                              thereof at any time, without prior notice.

                              <br />

                              <b>c.</b>Governing Law.
                              This Agreement is exclusively governed by and shall be interpreted under the laws of the
                              state of Delaware. The Parties agree that the courts of the judicial district of Delaware,
                              shall have exclusive jurisdiction relating to any dispute arising out of this Agreement or
                              directly or indirectly relating to this Agreement.

                              <br />



                              <b>d.</b>Absence of Waiver. The fact that DIYO does not assert certain rights or provisions
                              under the present Agreement or does not take action in regard to Your failure or the failure
                              of a Third Party to fully abide by the present Agreement does not mean and should not be
                              interpreted as DIYO’s renunciation of its rights to enforce the provisions of the present
                              Agreement in the future.<br />

                              <b>e.</b>Notice. All notices, requests or other communications required or permitted to be
                              given or made by or under this Agreement shall be given or made by email, registered mail,
                              facsimile transmission or messenger and shall be deemed to have been given or made, if by
                              registered mail, three (3) days after the postage date, and if by email, facsimile transmission
                              or by messenger, on the date that they are given or made.
                              Any such notice to User shall be made to the attention and sent to the address provided for under a given Account.
                              All notices to DIYO shall be sent to contact @<a style={{ textDecoration: "none", fontWeight: 600 }} href="http://diyo.ca">diyo.ca</a>.<br />

                              <b>f.</b>Headings. The present Agreement contains various sections for the sole purpose to facilitate their consultation and their comprehension
                               and have no incidence whatsoever on their interpretation.
                               Each clause contained herein is interpreted in light of the others so that each is given the meaning derived from this Agreement as a whole.
                                If any provision herein is held to be unenforceable, the remaining provisions shall remain in full force and effect.
                                 All rights and remedies hereunder are cumulative.<br/>

                              <b>g.</b>Application. User hereby agrees to be bound by the present Agreement and acknowledges that it has taken full cognisance of its rights
                              and obligations contained herein. In the event of any inconsistency or conflict between the terms and conditions, the following order of precedence applies:<br />

                              1. DIYO’s email confirmation(s), if any;<br/>
                              2. the Campaign;<br/>
                              3. these terms and conditions; and<br/>
                              4. DIYO’s Privacy Policy<br/>

                              

                        </p>

                  </div>


            </div>



      );
};

export default Terms;