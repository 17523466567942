import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import './Register.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import api from './Api/Indx';
import CustomGoogleSignup from './GoogleAuth/CustomGoogleSignup';
import LazyLoad from 'react-lazy-load';
import { Input } from 'antd';

function Register() {
  const userRef = useRef();

  const [firstname, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [user, setUser] = useState('');
  const [pwd, setPwd] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    let data = {};
    data = {
      email: user,
      password: pwd,
      first_name: firstname,
      last_name: lastName,
    };

    try {
      const res = await api.signupAccount(data);

      if (res?.msg === 'Account Created') {
        window.location = '/login';
      }

      setFirstName('');
      setLastName('');
      setUser('');
      setPwd('');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LazyLoad>
      <div className='RootContainer'>
        <div className='form-container'>
          <div className='formLeft'>
            <div className='diyodiv'>
              <img src='diyo.svg' className='LogoV' />
            </div>

            <p className='Text text-center'>
              Create your DIYO account and launch an outdoor ad campaign in 5 clicks!
            </p>
            {/* <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p> */}

            <form className='regform' onSubmit={handleSubmit}>
              <div>
                <Input
                  type='text'
                  className='fieldii'
                  placeholder='First name'
                  id='FirstName'
                  ref={userRef}
                  autoComplete='off'
                  onChange={(e) => setFirstName(e.target.value)}
                  value={firstname}
                  required
                />
              </div>

              <div>
                <Input
                  type='text'
                  className='fieldii'
                  placeholder='Last name'
                  id='LastName'
                  ref={userRef}
                  autoComplete='off'
                  onChange={(e) => setLastName(e.target.value)}
                  value={lastName}
                  required
                />
              </div>

              <div>
                <Input
                  type='email'
                  className='fieldii'
                  placeholder='Email address'
                  id='username'
                  ref={userRef}
                  autoComplete='off'
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
                />
              </div>

              <div>
                <Input.Password
                  type='password'
                  placeholder='Password'
                  className='fieldii'
                  id='password'
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
                />
              </div>

              <div style={{ display: 'flex' }}>
                <FormControlLabel
                  label={
                    <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <span style={{ fontWeight: 400, fontSize: '10px', display: 'flex' }}>
                        I agree to the{' '}
                      </span>{' '}
                      <span style={{ fontWeight: 400, fontSize: '10px', display: 'flex' }}>
                        <NavLink
                          style={{ textDecoration: 'none' }}
                          to='/terms-and-conditions'
                          target={'_blank'}
                        >
                          {' '}
                          terms & conditions
                        </NavLink>
                      </span>
                    </div>
                  }
                  control={
                    <Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 12, color: '#5E16EB' } }} />
                  }
                />
              </div>

              <button type='submit' className='submitButtonii'>
                Create Account
              </button>
            </form>

            <div className='checkbtn222'>
              <hr className='hr' width='30%' size='1' />
              <p className='t2'>Or</p>
              <hr className='hr' width='30%' size='1' />
            </div>

            <div className='gg' style={{ marginBottom: '10px' }}>
              <CustomGoogleSignup />
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'ProductSans' }}>
                {' '}
                Have an Account?{' '}
                <NavLink to='/login' style={{ textDecoration: 'none' }}>
                  <a
                    style={{
                      textDecoration: 'none',
                      color: '#5E16EB',
                      fontWeight: 400,
                      fontFamily: 'ProductSans',
                    }}
                  >
                    Login
                  </a>
                </NavLink>
              </p>
            </div>
          </div>

          <div className='formRight'>
            <img src={'sgg.svg'} />
          </div>
        </div>
      </div>
    </LazyLoad>
  );
}

export default Register;
