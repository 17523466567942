import React from 'react';
import "./Panview.css"
import ReactStreetview from 'react-streetview';



const Panview = () => {

      // const { cordinate, setCordinate} = useContext(CampaignContext);

      let streetviewlat = Number(localStorage.getItem("streetviewlat"))
      let streetviewlng = Number(localStorage.getItem("streetviewlng"))

      


     const googleMapsApiKey = 'AIzaSyAYoH5wgfdVDT-i_cr43ViNhPzQxcVuxf0'

//      


      // console.log(streetviewlat, streetviewlng, "from pan")
      
      const streetViewPanoramaOptions = {
          
            position: {lat:  
                  streetviewlat, lng: 
                  streetviewlng},
            pov: {heading: 100, pitch: 0},
            zoom: 1
      };
  
  
      return (
            <div style={{
                  width: "100vw",
                  height: '100vh',
                  backgroundColor: '#eeeeee'
            }}>
                  <ReactStreetview
                        apiKey={googleMapsApiKey}
                        streetViewPanoramaOptions={streetViewPanoramaOptions}
                  />
            </div>
      );
};

export default Panview

