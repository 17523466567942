import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

export const CampaignContext = createContext();

function CampaignContextApi(props) {
  const [selectedTimezone, setSelectedTimezone] = useState('-05:00');
  const [display, setDisplay] = useState(true);
  const [step, setStep] = useState(0);
  const [name, setName] = useState('');
  const [openF, setOpenF] = useState(false);
  const [cmpid, setCmpid] = useState('');
  const [show, setShow] = useState({ styleA: true, styleB: false });
  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [refetchingMapData, setRefetchingMapData] = useState(false);
  const [sf, setSf] = useState(true);
  const [mapup, setMapup] = useState(false);
  const [ldb, setLdb] = useState(true);
  const [residentalbs, setResidentalbs] = useState(true);
  const [obs, setObs] = useState(true);
  const [retailbs, setRetailbs] = useState(true);
  const [wct, setWct] = useState(true);
  const [screentypes, setScreentypes] = useState({});
  const [personName, setPersonName] = React.useState([]);
  const [adstarget, setAdstarget] = useState([]);
  const [showimpression, setShowimpression] = useState(' ');
  const [startDateRad1, setStartDateRad1] = useState('');
  const [startTimeRad1, setStartTimeRad1] = useState('');
  const [startDateRad2, setStartDateRad2] = useState('');
  const [endDateRad2, setEndDateRad2] = useState('');
  const [startTimeRad2, setStartTimeRad2] = useState('');
  const [endTimeRad2, setEndTimeRad2] = useState('');
  const [points, setPoints] = useState([]);

  const [budgetRad1, setBudgetsRad1] = useState(0);
  const [budgetRad2, setBudgetsRad2] = useState('');
  const [newimp, setNewimp] = useState('');
  const [activeStep, setActiveStep] = React.useState(0);

  const [campid, setCampid] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState('');
  const [budgets, setBudgets] = useState('');
  const [budget, setBudget] = useState(1);
  const [budgets2, setBudgets2] = useState('');
  // const [creatives, setCreatives] = useState("");
  const [_selectedVenues, _setSelectedVenues] = useState([]);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [orgmodal, setOrgmodal] = useState(false);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursay] = useState(false);
  const [friday, setFriday] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [impressions, setImpressions] = useState(0);
  const [minimumPayment, setMinimumPayment] = useState(0);
  const [impressions2, setImpressions2] = useState(0);
  const [margin, setMargin] = useState(1.35);
  const [minimumPayment2, setMinimumPayment2] = useState(0);
  const [mamount, setMamount] = useState(0);
  const [estimate, setEstimate] = useState(0);
  const [cost2, setCost2] = useState(0);
  const [screenvenuetypes, setScreenVenueTypes] = useState([]);
  const [selectedVenues, setSelectedVenues] = useState([]);
  const [costFilters, setCostFilters] = useState({ minimum: 0, maximum: 1000 });
  const [incomeFilters, setIncomeFilters] = useState({ min_income: 0, max_income: null });
  const [impperdollar, setImpperdollar] = useState(0);
  const [publishers, setPublishers] = useState([]);
  const [clientSecret, setClientSecret] = useState('');
  const [wl, setWl] = useState('');
  const [payref, setPayref] = useState('');
  const [cordinate, setCordinate] = useState();
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [dimensionsFilter, setDimensionsFilter] = useState('0-0');
  const [selectedParting, setSelectedParting] = useState({});

  // creatives

  const ContextProviderValues = {
    selectedTimezone,
    setSelectedTimezone,
    display,
    setDisplay,
    selectedVenues,
    setSelectedVenues,
    openF,
    setOpenF,
    cordinate,
    setCordinate,
    mapup,
    setMapup,
    clientSecret,
    setClientSecret,
    payref,
    setPayref,
    step,
    wl,
    setWl,
    setStep,
    cmpid,
    setCmpid,
    show,
    setShow,
    margin,
    setMargin,
    name,
    setName,
    startDate,
    setStartDate,
    startTime,
    setStartTime,
    endDate,
    setEndDate,
    endTime,
    setEndTime,
    budgets,
    setBudgets,
    budgets2,
    setBudgets2,
    _selectedVenues,
    _setSelectedVenues,
    isConfirmModalOpen,
    setIsConfirmModalOpen,
    orgmodal,
    setOrgmodal,
    //
    monday,
    setMonday,
    tuesday,
    setTuesday,
    wednesday,
    setWednesday,
    thursday,
    setThursay,
    friday,
    setFriday,
    activeTab,
    setActiveTab,
    activeStep,
    setActiveStep,
    impressions,
    setImpressions,
    minimumPayment,
    setMinimumPayment,
    impressions2,
    setImpressions2,
    minimumPayment2,
    setMinimumPayment2,
    startTimeRad1,
    setStartTimeRad1,
    startDateRad1,
    setStartDateRad1,
    budgetRad1,
    setBudgetsRad1,
    budgetRad2,
    setBudgetsRad2,
    startDateRad2,
    setStartDateRad2,
    endDateRad2,
    setEndDateRad2,
    startTimeRad2,
    setStartTimeRad2,
    endTimeRad2,
    setEndTimeRad2,
    mamount,
    setMamount,
    newimp,
    setNewimp,
    budget,
    setBudget,
    showimpression,
    setShowimpression,
    campid,
    setCampid,
    estimate,
    setEstimate,
    cost2,
    setCost2,
    sf,
    setSf,
    ldb,
    setLdb,
    residentalbs,
    setResidentalbs,
    obs,
    setObs,
    retailbs,
    setRetailbs,
    wct,
    setWct,
    screentypes,
    setScreentypes,
    publishers,
    setPublishers,
    personName,
    setPersonName,
    refetchingMapData,
    setRefetchingMapData,
    points,
    setPoints,
    adstarget,
    setAdstarget,
    impperdollar,
    setImpperdollar,
    screenvenuetypes,
    setScreenVenueTypes,
    costFilters,
    setCostFilters,
    incomeFilters,
    setIncomeFilters,
    selectedCampaign,
    setSelectedCampaign,
    dimensionsFilter,
    setDimensionsFilter,
    selectedParting,
    setSelectedParting
  };

  return (
    <CampaignContext.Provider value={ContextProviderValues}>
      {props.children}
    </CampaignContext.Provider>
  );
}

CampaignContextApi.propTypes = {
  children: PropTypes.node.isRequired, // add this line to validate the children prop
};

export default CampaignContextApi;
