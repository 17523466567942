import React, { useEffect, useContext, useState } from 'react';
import './Campaign.css';
import Storage from '../../utils/storage';
import useMediaQuery from '../useMediaQuery';

import 'react-accessible-accordion/dist/fancy-example.css';
import CampaignForm from './CampaignForms/VerticalLinearStepper';
import { logout, reset } from '../../features/auth/authSlice';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MainMap from '../Map/MainMap';
import addUnitsApi from '../Api/Indx';

// Material Design Dashoboard Menu
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { Person } from '@mui/icons-material/';
import { ExpandMore } from '@mui/icons-material';
import { CampaignContext } from '../../state';

// GRID LAYOUT

// import Paper from "@mui/material/Paper";
// import { styled } from "@mui/material/styles";
import '../Map/FloatingAcion.css';
import { FaTimes } from 'react-icons/fa';
import Fab from '@mui/material/Fab';
import MapIcon from '@mui/icons-material/Map';

function Campaigns() {
  // const [_points, _setPoints] = useState([]);
  // const [loading, setLoading] = useState(false);

  const {
    personName,
    points,
    setPoints,
    screenvenuetypes,
    selectedVenues,
    setSelectedVenues,
    display,
    setDisplay
  } = useContext(CampaignContext);
  const [blockerWarningHidden, setBlockerWarningHidden] = useState(
    !!Storage.get('blockerWarningHidden'),
  );

  const getMapInfo = async () => {
    // setLoading(true);

    try {
      const _data = {
        filters: {
          publishers: personName,
          screen_types: screenvenuetypes,
        },
        min: {
          latitude: 8.293134936967334,
          longitude: -137.05575020807603,
        },
        max: {
          latitude: 57.75047620456547,
          longitude: -5.104046203339891,
        },
      };

      const data = await addUnitsApi.getAddUnits(_data);

      setPoints(data.payload?.Positions);

      // setLoading(false);
    } catch (err) {
      // setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    getMapInfo();
  }, []);

  // handles Dashboard Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handles Logout

  const dispatch = useDispatch();
  // const { user } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(reset());
    dispatch(logout());

    window.location = '/login';
  };

  const Createset = () => {
    //  navigate('/Set')
    window.location = '/my-profile';
  };


  const userinfo = JSON.parse(Storage.get('user'));
  const hideBlockerWarning = (e) => {
    e.preventDefault();
    setBlockerWarningHidden(true);
    Storage.set('blockerWarningHidden', true);
  };
  // useMediaQuery

  const matches = useMediaQuery('md');

  return (
    <div className='map1'>
      <div className='navparent'>
        <nav className='nav1'>
          <NavLink to='/dashboard'>
            <img src='/diyo.svg' className='navlogo' alt={'logo'} />
          </NavLink>

          <h3 className='navtitle'>Dashboard</h3>

          <div className='navsidemenu'>
            <span className='navtitleName'> {userinfo?.firstName}</span>

            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Tooltip title='Account settings'>
                  <IconButton
                    onClick={handleClick}
                    size='small'
                    sx={{ ml: 1 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 29, height: 32 }}>
                      <ExpandMore />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 29,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 8,
                      height: 8,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={Createset}>
                  <Person style={{ color: '#5E16EB' }} /> My Profile
                </MenuItem>

                <MenuItem onClick={onLogout}>
                  <Logout style={{ color: '#5E16EB' }} fontSize='small' />
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          </div>
        </nav>

        <nav className='nav2'>
          <ul className='navlist'>
            <NavLink
              to='/dashboard'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
            >
              Home
            </NavLink>
            <NavLink
              to='/campaign-list'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
            >
              Campaign List
            </NavLink>
          </ul>

        </nav>
        {!blockerWarningHidden && (
          <div className={'text-center'}>
            <div className='add-blocker__warning bg-white py-2 px-4 gap-4 rounded inline-flex justify-center border border-gray mx-auto'>
              <p>
                If you don&apos;t see any map markers when you zoom in, please consider disabling
                any ad blockers and refreshing the page.
              </p>
              <button className='nav-btn nav-close-btn' onClick={hideBlockerWarning}>
                <FaTimes style={{ color: '#F44336' }} />
              </button>
            </div>
          </div>
        )}
      </div>

      {matches && (
        <div className='grid-container'>
          <div className='formside'>
            <CampaignForm selectedVenues={selectedVenues} />
          </div>

          <div className='mapside'>
            {/* <MainMap
							points={points}
							_points={_points}
							setPoints={setPoints}
							selectedVenues={selectedVenues}
							setSelectedVenues={setSelectedVenues}
						/> */}

            <MainMap
              points={points}
              setPoints={setPoints}
              selectedVenues={selectedVenues}
              setSelectedVenues={setSelectedVenues}
            />
          </div>
        </div>
      )}

      {!matches && (
        <div className='grid-container'>
          {display && (
            <>
              <div className='formside' style={{ visibility: 'visible' }}>
                <CampaignForm selectedVenues={selectedVenues} />
              </div>
              <div className='FloatingButton'>
                <Box sx={{ '& > :not(style)': { m: 1 } }} className='float'>
                  <Fab
                    variant='extended'
                    sx={{ background: '#FFF' }}
                    onClick={() => setDisplay(!display)}
                  >
                    <MapIcon sx={{ mr: 1, color: '#5E16EB' }} />
                    Map
                  </Fab>
                </Box>
              </div>
            </>
          )}

          {!display && (
            <div className='mapside' style={{ visibility: 'visible' }}>
              {/* <MainMap
								points={points}
								_points={_points}
								setPoints={setPoints}
								selectedVenues={selectedVenues}
								setSelectedVenues={setSelectedVenues}
							/> */}

              <MainMap
                points={points}
                setPoints={setPoints}
                selectedVenues={selectedVenues}
                setSelectedVenues={setSelectedVenues}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Campaigns;
