import React, { useRef } from 'react';
import './Landingpage.css';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Storage from '../../utils/storage';
import InstagramIcon from '@mui/icons-material/Instagram';
import ScrollToTop from '../ScrollToTop';
import { NavLink } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';

import 'swiper/css';
function Landingpage() {
  // const API_BASE = process.env.REACT_APP_API_URL;

  const HowItWorks = useRef(null);
  const WhyOh = useRef(null);
  const Blog = useRef(null);

  const scrollToSection = (elementRef) => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // const myStyle = {
  //   background:
  //     'linear-gradient(226.65deg, #5E16EB 3.67%, rgba(255, 255, 255, 0) 97.99%, rgba(255, 255, 255, 0) 26.61%, #FFFFFF 109.68%)',
  // };

  // const myStyle2 = {
  //   background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 26.61%, #FFFFFF 109.68%)',
  // };

  const location = () => {
    localStorage.getItem('user')
      ? (window.location = '/dashboard')
      : (window.location = '/create-account');
  };

  const Crecamp = () => {
    window.location = '/create-campaign';
  };

  const LoginH = () => {
    window.location = '/login';
  };

  const Privacy = () => {
    window.open('/privacy-policy', '_blank');
  };

  const Terms = () => {
    window.open('/terms-and-conditions', '_blank');
  };

  const userinfo = JSON.parse(Storage.get('user'));

  return (
    <div className='flex flex-col overflow-x-hidden w-full h-screen home-container relative'>
      <section className={'section-one z-10'}>
        <div className='cvp'>
          <div className='Landingnavparenth'>
            <nav className='landingnavh'>
              <img src='diyo.svg' className='navlogoHome' />

              <li onClick={() => scrollToSection(HowItWorks)}>How it works</li>
              <li onClick={() => scrollToSection(WhyOh)}>Why OOH is better </li>
              <li>
                <NavLink to='/faq' target={'_blank'}>
                  FAQ
                </NavLink>
              </li>
              <li>
                <a href='https://blog.diyo.ca' target={'_blank'} rel={'noreferrer'}>
                  Blog
                </a>
              </li>

              {localStorage.getItem('user') ? (
                <div className='wlcmbckdiv'>
                  <p>
                    Welcome back, <span>{userinfo?.firstName}</span>
                  </p>
                  <button onClick={() => Crecamp()} className='crtcampbutton'>
                    Create campaign
                  </button>
                </div>
              ) : (
                <button onClick={() => LoginH()} className='crtcampbutton'>
                  Log In
                </button>
              )}
            </nav>
          </div>

          <div className='cvct min-h-[90vh]'>
            <div className='lftct'>
              <div>
                <button className='watch' onClick={() => scrollToSection(HowItWorks)}>
                  Watch our 1 minute demo
                </button>
                <p className='h1cp'>
                  Drive sales &<br /> conversions in the real world
                </p>
                <p className='pcp'>
                  Launch impactful outdoor billboard campaigns anywhere in the world in less than 60
                  seconds
                </p>
              </div>

              <div className='cvpbarw'>
                <button type='button' className='btnloginL' onClick={location}>
                  Launch my campaign <ArrowRightAltIcon />
                </button>
              </div>
            </div>
            <div className='overflow-hidden hidden md:block w-1/2 h-[90vh]'>
              <Swiper
                direction={'vertical'}
                slidesPerView={3}
                loop={true}
                className='mySwiper'
                spaceBetween={20}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                modules={[Autoplay]}
              >
                <SwiperSlide className={'image-slide'}>
                  <img src='/diyo-landing-card.png' className='mappy rounded-lg mb-4' />
                </SwiperSlide>
                <SwiperSlide className={'image-slide'}>
                  <img src='/diyo-landing-card-2.jpg' className='mappy rounded-lg mb-4' />
                </SwiperSlide>
                <SwiperSlide className={'image-slide'}>
                  <img src='/diyo-landing-card.png' className='mappy rounded-lg mb-4' />
                </SwiperSlide>
                <SwiperSlide className={'image-slide'}>
                  <img src='/diyo-landing-card-2.jpg' className='mappy rounded-lg mb-4' />
                </SwiperSlide>
                <SwiperSlide className={'image-slide'}>
                  <img src='/diyo-landing-card.png' className='mappy  rounded-lg mb-4' />
                </SwiperSlide>
                <SwiperSlide className={'image-slide'}>
                  <img src='/diyo-landing-card-2.jpg' className='mappy  rounded-lg mb-4' />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <div className='section4' ref={HowItWorks}>
          <div className='puppydiv'>
            <p className='pupy'>
              We&apos;ve made it ridiculously easy to <br /> launch an outdoor campaign
            </p>
          </div>

          <div className='outdoor'>
            <div className='outdoorsteps lg:min-w-[490px]'>
              <div className='cardStp'>
                <div className='FirstCard'>
                  <div className='cardNumbersDiv'>
                    <p>1</p>
                  </div>
                  <div className='cardNText'>
                    <p>Target your Audience</p>
                    <span>
                      Select out-of-home digital billboards yourself from our interactive map. Or
                      use DIYO&apos;s categories to target by audience!
                    </span>
                  </div>
                </div>
                <img src='/AR1.svg' />
              </div>

              <div className='cardStp2'>
                <img src='/AR2.svg' />
                <div className='SecondCard'>
                  <div className='cardNumbersDiv'>
                    <p>2</p>
                  </div>

                  <div className='cardNText'>
                    <p>Set your own budget</p>
                    <span>
                      If you have a budget in mind, your campaign can <br />
                      run until it hits that number. Or You can select a <br />
                      start and end time for your campaign.
                    </span>
                  </div>
                </div>
              </div>

              <div className='ThirdCard'>
                <div className='cardNumbersDiv'>
                  <p>3</p>
                </div>
                <div className='cardNText'>
                  <p>Upload your creative!</p>
                  <span>Upload your image/video and watch it go live in the real world!</span>
                </div>
              </div>
            </div>

            <div className='cv4map'>
              <video controls autoPlay>
                <source src='landing_page.mp4' type='video/mp4' />
              </video>
            </div>
          </div>
        </div>
      </section>

      <div className='cv5' ref={WhyOh}>
        <p className='out-of-home'>
          Out-of-home advertising is cheaper <br />
          and more impactful than traditional online ads
        </p>

        <div className='htdi'>
          {/* section A */}
          <div className='sectionA'>
            <div
              className='sectionATitle'
              style={{
                background:
                  'linear-gradient(95.56deg, #5E16EB 16.54%, rgba(94, 22, 235, 0.6) 89.68%)',
              }}
            >
              <p>DIYO (Do-It-Yourself)</p>
            </div>

            <div className='sectionABlocks'>
              <div className='sectionABlocksContent'>
                <p
                  className='blocksizes'
                  style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}
                >
                  REACH
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  Reach anyone, anywhere.
                </span>
              </div>
            </div>

            <div className='sectionABlocks'>
              <div className='sectionABlocksContent'>
                <p style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  IMPACT
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  Can’t skip an outdoor in-your face Ad!
                </span>
              </div>
            </div>

            <div className='sectionABlocks'>
              <div className='sectionABlocksContent'>
                <p style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  RECALL
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  70% recall rate after seeing an ad in the real world
                </span>
              </div>
            </div>

            <div className='sectionABlocks'>
              <div className='sectionABlocksContent'>
                <p style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  COSTS
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  Average cost per 1000 impressions: $6.50
                </span>
              </div>
            </div>

            <div className='sectionABlocks'>
              <div className='sectionABlocksContent'>
                <p style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  EASY TO USE
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  Launch an outdoor ad campaign in less than 60 seconds with DIYO
                </span>
              </div>
            </div>
          </div>

          {/* Nutral section */}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignSelf: 'center',
              padding: '1%',
            }}
          >
            <hr style={{ display: 'flex', flexDirection: 'column', width: '1%' }} size='180' />
            <div
              style={{
                with: '30%',
                height: 'fit-content',
                background: '#FCFCFC',
                padding: '10px',
                borderStyle: 'ridge',
                borderRadius: '10px',
              }}
            >
              <p>VS</p>
            </div>

            <hr style={{ display: 'flex', flexDirection: 'column', width: '1%' }} size='180' />
          </div>

          {/* section B */}

          <div className='sectionB'>
            <div
              className='sectionBTitle'
              style={{ background: 'linear-gradient(257.7deg, #F44336 41.05%, #FF9E66 82.9%)' }}
            >
              <p>Instagram/ Facebook</p>
            </div>

            <div className='sectionBBlocks'>
              <div className='sectionBBlocksContent'>
                <p style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  REACH
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  Only people that use social media
                </span>
              </div>
            </div>

            <div className='sectionBBlocks'>
              <div className='sectionBBlocksContent'>
                <p style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  IMPACT
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  Your ad will be lost in a sea of other ads
                </span>
              </div>
            </div>

            <div className='sectionBBlocks'>
              <div className='sectionBBlocksContent'>
                <p style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  RECALL
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  People are less than 20% likely to remember your ad.
                </span>
              </div>
            </div>

            <div className='sectionBBlocks'>
              <div className='sectionBBlocksContent'>
                <p style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  COSTS
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  Average cost per 1000 impressions: $12
                </span>
              </div>
            </div>

            <div className='sectionBBlocks'>
              <div className='sectionBBlocksContent'>
                <p style={{ color: '#5E16EB', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  EASY TO USE
                </p>
                <span style={{ fontWeight: 400, fontFamily: 'ProductSans' }}>
                  Have you tried setting up a campaign on facebook? you’ll want to rip your hair
                  out!
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className='cv5lst' id='cv5lst'>
          <p className='OOH'>Ready to harness the power of OOH?</p>
          <p className='OOH'>Launch your campaign in minutes and see </p>
          <p className='OOH'>your brand in the streets! </p>

          <div className='cv5lstbtn'>
            <button onClick={location} className='cv5lstbutton' style={{}}>
              {' '}
              Get Started
            </button>
          </div>
        </div>
      </div>

      <div className='footer' ref={Blog}>
        <img src='diyo.svg' className='fterimg' />
        <div className='footicn'>
          <div className='privacybutton'>
            <a href='http://instagram.com/diyoutdoor' target={'_blank'} rel={'noreferrer'}>
              <InstagramIcon sx={{ mr: 1, color: '#5e16eb' }} className='footicnimg' />
            </a>

            <p onClick={() => Privacy()} style={{ fontWeight: 600, cursor: 'pointer' }}>
              Privacy policy
            </p>
            <p onClick={() => Terms()} style={{ fontWeight: 600, cursor: 'pointer' }}>
              Terms and Conditions
            </p>
          </div>
          <div className='privacybutton'>
            <p style={{ fontWeight: 400 }}>DIYO Inc</p>
          </div>
        </div>
      </div>

      <div className='foot'>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          <img src='diyo.svg' />
          <p onClick={() => Privacy()} style={{ fontWeight: 400, cursor: 'pointer' }}>
            Privacy policy
          </p>
          <p onClick={() => Terms()} style={{ fontWeight: 400, cursor: 'pointer' }}>
            Terms and Conditions
          </p>
        </div>

        <div className='privacybutton'>
          <p style={{ fontWeight: 400, display: 'flex', alignItems: 'flex-end' }}>DIYO Inc</p>
        </div>
      </div>

      <ScrollToTop />
    </div>
  );
}

export default Landingpage;
