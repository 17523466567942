/* eslint-disable */
import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../../Api/Indx';
import Box from '@mui/material/Box';
import Button from '@material-ui/core/Button';
import { CampaignContext } from '../../../state';
import { Close } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import VideoUpload from '../../videoUpload';
function FifthStep() {
  const [display, setDisplay] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [creatives, setCreatives] = useState([]);
  const [loading, setLoading] = useState(false);
  const { campaignId } = useParams();
  const dayPartings = ['morning', 'afternoon', 'evening', 'overnight'];

  const {
    step,
    setStep,
    name,
    budget,
    setBudget,
    _selectedVenues,
    selectedVenues,
    selectedTimezone,
    setIsConfirmModalOpen,
    startTimeRad1,
    startDateRad1,
    setCmpid,
    budgetRad1,
    startDateRad2,
    endDateRad2,
    startTimeRad2,
    endTimeRad2,
    show,
    cost2,
    setClientSecret,
    selectedCampaign,
    selectedParting,
  } = useContext(CampaignContext);

  const navigate = useNavigate();

  const [sxMessage, setSxMessage] = React.useState(null);
  const [selectedResolutions, setSelectedResolutions] = useState({});
  const [files, setFiles] = useState([]);
  if (!show.styleB) {
    setBudget(Number(budgetRad1));
  } else if (!show.styleA) {
    setBudget(Number(cost2));
  }

  const getImageDimns = async ({ file }) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async function (e) {
        //Initiate the JavaScript Image object.
        const image = new Image();

        //Set the Base64 string return from FileReader as source.
        image.src = e.target.result;

        //Validate the File Height and Width.
        image.onload = await function () {
          const height = this.height;
          const width = this.width;

          resolve({ width, height });
        };
      };
    });
  };

  // CREATE CAMPAIGN

  const creativesNotValid = () => {
    Object.values(selectedResolutions).forEach((resolution) => {
      if (!resolution.valid) {
        return false;
      }
    });
    return true;
  };

  const createCamp = async (e) => {
    e.preventDefault();

    if (_selectedVenues.length < 1) {
      setErrMsg('Please select at least 1 screen before proceeding');
      return;
    }

    if (creatives.length === 0 || !creativesNotValid()) {
      setErrMsg(
        `Ensure all uploaded images resolution matches the resolution of the screen(s) you've selected`,
      );
      return;
    }

    try {
      const targets = [];
      for (let i = 0; i < _selectedVenues.length; i++) {
        targets.push(_selectedVenues[i].id);
      }

      let data = {};
      let schedulePeriods = {};
      dayPartings.forEach((dayParting) => {
        schedulePeriods[dayParting] = selectedParting[dayParting] || false;
      });
      console.log(schedulePeriods);

      if (!show.styleB) {
        data = {
          name,
          start_date: `${startDateRad1}T${startTimeRad1}${selectedTimezone}`,
          targets,
          creatives,
          budget: budget,
          schedule_periods: schedulePeriods,
        };

        // localStorage.setItem("Duration", `${startDateRad1} / ${startTimeRad1}`);
      } else if (!show.styleA) {
        data = {
          name,
          start_date: `${startDateRad2}T${startTimeRad2}${selectedTimezone}`,
          end_date: `${endDateRad2}T${endTimeRad2}${selectedTimezone}`,
          targets,
          creatives,
          budget: budget,
          schedule_periods: schedulePeriods,
        };
      }

      const res = await api.createCampaign({
        ...data,
        timezone_offset: Number(selectedTimezone.split(':')[0]),
      });
      navigate('/campaign-list');
      setCmpid(res?.payload?.campaign?.id);
      localStorage.setItem('cmpid', res?.payload?.campaign?.id);
      setBudget(res?.payload?.campaign?.billing?.budget);

      const data2 = {
        amount: Number(budget),
        description: 'Payment ',
      };
      const client_Secret = await api.createStripePaymentIntent(data2);
      let {
        payload: { clientSecret },
      } = client_Secret;
      setClientSecret(clientSecret);
      clientSecret && setIsConfirmModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  };

  const updateCamp = async (e) => {
    e.preventDefault();

    try {
      const targets = [];
      for (let i = 0; i < _selectedVenues.length; i++) {
        targets.push(_selectedVenues[i].id);
      }

      let data = {};

      if (!show.styleB) {
        data = {
          name,
          start_date: `${startDateRad1}T${startTimeRad1}${selectedTimezone}`,
          targets,
          creatives,
          budget: budget,
        };

        // localStorage.setItem("Duration", `${startDateRad1} / ${startTimeRad1}`);
      } else if (!show.styleA) {
        data = {
          name,
          start_date: `${startDateRad2}T${startTimeRad2}${selectedTimezone}`,
          end_date: `${endDateRad2}T${endTimeRad2}${selectedTimezone}`,
          targets,
          creatives,
          budget: budget,
        };
      }
      const res = await api.updateCampaign({
        id: campaignId,
        data: {
          ...data,
          timezone_offset: Number(selectedTimezone.split(':')[0]),
        },
      });

      navigate('/campaign-list');
      setCmpid(res?.payload?.campaign?.id);
      localStorage.setItem('cmpid', res?.payload?.campaign?.id);
      setBudget(res?.payload?.campaign?.billing?.budget);

      const data2 = {
        amount: Number(budget),
        description: 'Payment ',
      };
      const client_Secret = await api.createStripePaymentIntent(data2);
      let {
        payload: { clientSecret },
      } = client_Secret;
      setClientSecret(clientSecret);
      clientSecret && setIsConfirmModalOpen(true);
    } catch (err) {
      console.log(err);
    }
  };
  // Upload creatives image
  const handleImageUpload = async (width, height, file) => {
    try {
      setLoading(true);
      const _res = await api.uploadImage();

      await fetch(_res.payload.url, {
        method: 'PUT',
        headers: {
          Expires: _res.payload.headers.Expires[0],
          Host: _res.payload.headers.Host[0],
        },

        contentType: 'image/png',
        body: file,
      });

      const data = {
        file_Key: _res.payload.fileKey,
        title: file.name,
        kind: 'banner',
        width: width,
        height: height,
      };

      // console.log(data, "image data")

      const _getId = await api.post('/creatives', data);

      setCreatives([...creatives, _getId.payload.creative.id]);
      // console.log(creatives);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const beforeImageUpload = async (file) => {
    setFiles([...files, file]);
    setSxMessage(null);
    let isValid = false;
    try {
      const { width, height } = await getImageDimns({ file });
      const resolutions = { ...selectedResolutions };
      Object.values(resolutions).forEach((resolution) => {
        let sHeight = resolution.height;
        let sWidth = resolution.width;
        if (height === sHeight && width === sWidth) {
          setSxMessage(true);
          isValid = true;
          setSelectedResolutions({
            ...selectedResolutions,
            [`${width}x${height}`]: { ...resolution, valid: isValid },
          });
          handleImageUpload(width, height, file);
        }
      });
      setSxMessage(isValid);
      if (isValid) {
        return false;
      }
      return Upload.LIST_IGNORE;
    } catch (e) {
      setSxMessage(false);
      return Upload.LIST_IGNORE;
    }
  };

  // Upload video creatives

  const handleVideoUpload = async ({ videoWidth, videoHeight, videoDuration, file }) => {
    try {
      setLoading(true);
      const _res = await api.getUploadUrl({ extension: 'mp4' });

      await fetch(_res.payload.url, {
        method: 'PUT',
        headers: {
          Expires: _res.payload.headers.Expires[0],
          Host: _res.payload.headers.Host[0],
        },

        contentType: 'video/mp4',
        body: file,
      });

      const data = {
        file_Key: _res.payload.fileKey,
        title: file.name,
        kind: 'video',
        width: videoWidth,
        height: videoHeight,
        duration: videoDuration,
      };

      const _getId = await api.post('/creatives', data);

      setCreatives([...creatives, _getId.payload.creative.id]);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const resolutions = { ...selectedResolutions };
    selectedVenues.forEach((venue) => {
      const { height, width } = venue.slot;
      if (resolutions[`${width}x${height}`] === undefined) {
        resolutions[`${width}x${height}`] = { width, height, valid: false, ...venue };
      }
    });
    setSelectedResolutions({ ...resolutions });
  }, [selectedVenues]);

  useEffect(() => {
    if (campaignId && selectedCampaign) {
      setCreatives([...creatives, ...selectedCampaign.creatives]);
    }
  }, [campaignId, selectedCampaign]);

  return (
    <div>
      <div className='uploadimagevid'>
        <h4>Creatives</h4>
        <p>
          Please upload your image (.png or .jpg) / video (.mp4) files to match the resolutions
          required in your selected screens below.
        </p>
        <p>
          One campaign can only have either images or videos uploaded. We do not have the ability to
          split campaigns by ad units and assign images to some ad units and videos to other.
        </p>

        <Box>
          <div className='imagevideo'>
            <Button
              size='small'
              style={{ backgroundColor: '#5E16EB', color: '#FFFFFF' }}
              onClick={() => setDisplay(false)}
            >
              image
            </Button>

            <Button
              size='small'
              style={{ backgroundColor: '#DDDDDD' }}
              onClick={() => setDisplay(true)}
            >
              Video
            </Button>
          </div>
        </Box>

        {display ? (
          // VIDEO ASSETS
          <div>
            <VideoUpload
              selectedResolutions={selectedResolutions}
              setSelectedResolutions={setSelectedResolutions}
              selectedVenues={selectedVenues}
              handleVideoUpload={handleVideoUpload}
              loading={loading}
            ></VideoUpload>
          </div>
        ) : (
          //IMAGE ASSETS

          <div className='creativeorientation'>
            {selectedVenues.length === 0 ? (
              <div>
                <p style={{ fontSize: '12px' }}>Select a screen from the map on the right</p>{' '}
              </div>
            ) : (
              <div
                style={{
                  height: 'fit-content',
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 15,
                }}
              >
                <div>
                  <p style={{ fontFamily: 'ProductSans', fontWeight: 400, fontSize: 12 }}>
                    You&apos;ve selected the following screens and their resolutions
                  </p>
                </div>

                {Object.values(selectedResolutions).map((resolution, index) => (
                  <div
                    style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                    key={`resolution-${index}`}
                  >
                    <div style={{ display: 'flex' }}></div>
                    <p className={'flex items-baseline text-sm text-black font-normal gap-2'}>
                      Resolution {index + 1}:
                      <span className='impspan'>
                        {resolution.width} x {resolution.height}
                      </span>
                      <span>
                        <FontAwesomeIcon
                          icon={resolution.valid ? faCircleCheck : faCircleXmark}
                          className={''}
                          style={{ color: resolution.valid ? '#5E16EB' : '#F44336' }}
                        />
                      </span>
                    </p>
                  </div>
                ))}
              </div>
            )}

            <div className='addcreatives'>
              <Upload.Dragger
                multiple
                listType='picture'
                accept='.png,.jpeg,.jpg,.doc'
                action={null}
                onRemove={(file) => {
                  const index = files.indexOf(file);
                  const newFileList = files.slice();
                  newFileList.splice(index, 1);
                  setFiles(newFileList);
                }}
                beforeUpload={beforeImageUpload}
              >
                Drag files here OR
                <br />
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <Button
                    variant='contained'
                    component='label'
                    loading={loading}
                    style={{
                      color: '#000',
                      background: '#C9B6FA',
                      fontSize: 10,
                      fontWeight: 400,
                    }}
                  >
                    UPLOAD CREATIVES
                  </Button>
                  {sxMessage && (
                    <div
                      style={{
                        color: '#00FF00',
                        fontSize: '10px',
                        fontWeight: 400,
                        fontFamily: 'ProductSans',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Image/Video dimensions valid{' '}
                      <CheckIcon fontSize='small' sx={{ mr: 1, color: '#00FF00' }} />
                    </div>
                  )}

                  {!sxMessage && sxMessage !== null && (
                    <div
                      style={{
                        color: 'red',
                        fontSize: '10px',
                        fontWeight: 400,
                        fontFamily: 'ProductSans',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      className={'pt-2'}
                    >
                      Please upload the correct resolution image(s) for your selected screen(s)
                      <Close fontSize='small' sx={{ mr: 1, color: 'red' }} />
                    </div>
                  )}
                </div>
              </Upload.Dragger>
            </div>
          </div>
        )}

        <p style={{ marginTop: '20px', color: 'red', fontWeight: 400, fontSize: '12px' }}>
          {errMsg}
        </p>

        <div className='backnext'>
          {selectedCampaign.paymentStatus !== 'PAID' && (
            <button onClick={() => setStep(step - 1)} className='crtcmpg2'>
              BACK
            </button>
          )}

          {campaignId ? (
            <button onClick={(e) => updateCamp(e)} className='crtcmpgcamp'>
              UPDATE CAMPAIGN
            </button>
          ) : (
            <button onClick={(e) => createCamp(e)} className='crtcmpgcamp'>
              CREATE CAMPAIGN
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default FifthStep;
