import React, { useContext, useEffect, useState } from 'react';
import 'react-treeview/react-treeview.css';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { CampaignContext } from '../../../state';
import { Collapse } from 'antd';
import venueTaxonomy from '../../../assets/data/specification.json';

const { Panel } = Collapse;

const VenueFormControlLabel = ({ parent, venue, screenvenuetypes, handleInput }) => {
  return (
    <FormControlLabel
      key={venue.enumeration_id}
      label={<span style={{ fontWeight: 400, fontSize: 13 }}>{venue.name}</span>}
      control={
        <Checkbox
          name={parent.name}
          value={venue.enumeration_id}
          style={{ color: '#5E16EB' }}
          checked={screenvenuetypes.includes(venue.enumeration_id.toString())}
          onChange={handleInput}
          sx={{ '& .MuiSvgIcon-root': { fontSize: 14 } }}
        />
      }
    />
  );
};
const ChildrenSubCategories = ({
  venue,
  toggleAll,
  isSelected,
  screenvenuetypes,
  handleInput,
  selectedObj,
}) => {
  return (
    <Collapse ghost>
      <Panel
        collapsible={'header'}
        header={venue.name}
        key={venue.enumeration_id}
        style={{ fontWeight: 400, fontSize: 13, color: '#5E16EB' }}
        className={'sub-venues'}
      >
        <Box style={{ padding: 0 }}>
          <FormGroup>
            <FormControlLabel
              label={
                <span style={{ fontWeight: 400, fontSize: 13 }}>{venue.name} (Check all)</span>
              }
              control={
                <Checkbox
                  checked={!!selectedObj[venue.name]}
                  indeterminate={isSelected(venue)}
                  style={{ color: '#5E16EB' }}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 14, color: '#5E16EB' } }}
                  name={venue.name}
                  value={venue}
                  onChange={(e) => {
                    e.preventDefault();
                    toggleAll(venue);
                  }}
                />
              }
            />
            <>
              {venue.children.map((subVenue) => (
                <VenueFormControlLabel
                  parent={venue}
                  venue={subVenue}
                  key={subVenue.enumeration_id}
                  screenvenuetypes={screenvenuetypes}
                  handleInput={handleInput}
                />
              ))}
            </>
          </FormGroup>
        </Box>
      </Panel>
    </Collapse>
  );
};
const ChildrenCategories = ({
  parent,
  categories,
  screenvenuetypes,
  handleInput,
  toggleAll,
  isSelected,
  selectedObj,
}) => {
  return categories.map((venue) => (
    <div key={venue.enumeration_id}>
      {venue.children ? (
        <>
          <ChildrenSubCategories
            venue={venue}
            isSelected={isSelected}
            toggleAll={toggleAll}
            screenvenuetypes={screenvenuetypes}
            handleInput={handleInput}
            selectedObj={selectedObj}
          />
        </>
      ) : (
        <>
          <VenueFormControlLabel
            parent={parent}
            venue={venue}
            screenvenuetypes={screenvenuetypes}
            handleInput={handleInput}
          />
        </>
      )}
    </div>
  ));
};

export default function ControlledTreeView() {
  const {
    openooh_venue_taxonomy: { specification },
  } = venueTaxonomy;
  const { categories } = specification;

  const { screenvenuetypes, setScreenVenueTypes } = useContext(CampaignContext);

  const [screens, setScreens] = useState([]);
  const [selectedObj, setSelectedObj] = useState({});
  const [selectedParents, setSelectedParents] = useState({});
  const handleInput = (event) => {
    event.preventDefault();
    const copy = [...screens];
    const {
      target: { value },
    } = event;
    const index = screens.indexOf(value);
    if (index === -1) {
      setScreens([...screens, value]);
    } else {
      copy.splice(index, 1);
      setScreens(copy);
    }
  };

  const isSelected = (venue) => {
    const found = screenvenuetypes.find((screen) =>
      screen.toString().startsWith(venue.enumeration_id),
    );
    return found && true;
  };
  const toggleAll = (venue) => {
    const venueIds = [];
    const selCopy = { ...selectedObj };
    const selParentCopy = { ...selectedParents };
    const toggled = selectedObj[venue.name];

    venue.children.forEach((ven) => {
      venueIds.push(ven.enumeration_id.toString());
      if (ven.children) {
        ven.children.forEach((child) => venueIds.push(child.enumeration_id.toString()));
      }
    });
    if (toggled) {
      const filtered = screens.filter((ven) => !venueIds.includes(ven));
      delete selCopy[venue.name];
      delete selParentCopy[venue.enumeration_id];
      venue.children.forEach((ven) => {
        if (ven.children) {
          delete selCopy[ven.name];
          delete selParentCopy[ven.enumeration_id];
        }
      });
      setSelectedObj({ ...selCopy });
      setSelectedParents({ ...selParentCopy });

      setScreens(filtered);
    } else {
      selCopy[venue.name] = true;
      venue.children.forEach((ven) => {
        if (ven.children) {
          selCopy[ven.name] = true;
          selectedParents[ven.enumeration_id] = true;
        }
      });
      setSelectedObj({ ...selCopy });
      setScreens([...screens, ...venueIds]);
      setSelectedParents({ ...selectedParents, [venue.enumeration_id]: true });
    }
  };

  useEffect(() => {
    const allScreens = new Set([...screens, ...Object.keys(selectedParents)]);
    setScreenVenueTypes(Array.from(allScreens));
  }, [screens]);

  return (
    <div className={'screen__categories'}>
      <Collapse defaultActiveKey={['0']} ghost>
        {categories.map((category) => (
          <Panel
            header={category.name}
            key={category.enumeration_id}
            style={{ fontWeight: 400, fontSize: 14, color: '#5E16EB' }}
          >
            <Box className={'ml-4'}>
              <FormGroup>
                <FormControlLabel
                  className={`${category.children.length > 1 ? '' : 'hidden'}`}
                  label={
                    <span style={{ fontWeight: 400, fontSize: 13 }}>
                      {category.name} (Check all)
                    </span>
                  }
                  control={
                    <Checkbox
                      name={category.name}
                      checked={!!selectedObj[category.name]}
                      value={category.enumeration_id}
                      indeterminate={isSelected(category)}
                      style={{ color: '#5E16EB' }}
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 14, color: '#5E16EB' } }}
                      onChange={(e) => {
                        e.preventDefault();
                        toggleAll(category);
                      }}
                    />
                  }
                />
                {
                  <ChildrenCategories
                    parent={category}
                    categories={category.children}
                    toggleAll={toggleAll}
                    screenvenuetypes={screenvenuetypes}
                    handleInput={handleInput}
                    isSelected={isSelected}
                    selectedObj={selectedObj}
                  />
                }
              </FormGroup>
            </Box>
          </Panel>
        ))}
      </Collapse>
    </div>
  );
}
