import React from 'react';
import "./Google.css"
import { useGoogleLogin } from '@react-oauth/google';
import api from "../Api/Indx"


const CustomGoogleSignup = () => {


     
      const signup = useGoogleLogin({
            onSuccess: response =>   handleSubmit(response.access_token)

      });


      const handleSubmit = async (e) =>{


				let data = {
                              gauth_token: e
                        }

                        const res = await api.googleSignup(data)

                        if (res?.msg == "OK") {

                              window.location = "/login"
                        }

      }


      return (
            <div className='Google'>

            <button className="Googlecss text-base" onClick={() => signup()}>
                  <img src='Google.svg' style={{ width: "10%", height: "10%" }} />
                  Sign up with Google
            </button>
      </div>
      );
};

export default CustomGoogleSignup;