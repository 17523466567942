import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React from 'react';
import { history } from '../Helpers/history';
import PropTypes from 'prop-types';

function PrivateRoute({ children }) {
  const { user: authUser } = useSelector((x) => x.auth);

  if (!authUser) {
    // not logged in so redirect to login page with the return url
    return <Navigate to='/login' state={{ from: history.location }} />;
  }

  // authorized so return child components
  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PrivateRoute };
