import React, { useState, useContext } from 'react';
import { PaymentElement, AddressElement } from '@stripe/react-stripe-js';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import './Modal.css';
import { CampaignContext } from '../../../state';

const CheckoutForm = () => {
  const { name, budget } = useContext(CampaignContext);

  const formattedBudget = new Intl.NumberFormat('en-US').format(budget || 0);

  const Showcamplist = () => {
    window.location = '/campaign-list';
  };

  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsProcessing(true);

      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page

          return_url: `${origin}/comfirm-payment`,
        },
      });

      if (error.type === 'card_error' || error.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occured.');
      }

      setIsProcessing(false);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <form id='payment-form' onSubmit={handleSubmit}>
      <h3 className={'text-center font-semibold text-black mb-6'}>Campaign summary</h3>

      <p className='pti'>
        Your campaign has been created.
        <br /> Enter your credit card details to activate and deploy your campaign.
      </p>
      <p className='pti mb-4'>
        Note: it may take up to 12+ hours for your creative to be approved. You&apos;ll get an email
        from us indicating your creative has been approved.
      </p>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
        <div className='modalpop2'>
          <p>
            Campaign name: <span style={{ color: '#5E16EB' }}>{name}</span>
          </p>
        </div>

        {/*<div className="modalpop2">*/}
        {/*<p>Campaign duration: <span style={{ color: "#5E16EB" }}></span></p>*/}
        {/*</div>*/}

        <div className='modalpop2'>
          <p>
            Campaign budget: <span style={{ color: '#5E16EB' }}>${formattedBudget}</span>{' '}
          </p>
        </div>

        {/*<div className="modalpop2">*/}
        {/*  <p>Estimated expected reach:  <span style={{ color: "#5E16EB" }}> people </span></p>*/}
        {/*</div>*/}

        {/*<div className="modalpop2">*/}
        {/*<p>Screen selected: <span style={{ color: "#5E16EB" }}></span></p>*/}
        {/*</div>*/}
      </div>

      <div className='crtbutton'></div>
      <PaymentElement id='payment-element' />
      <AddressElement options={{ mode: 'billing' }} />

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        <button className='saveasproposal' onClick={Showcamplist}>
          Save as proposal
        </button>

        <button type='submit' disabled={isProcessing || !stripe || !elements} className='pay'>
          <span id='button-text'>
            {isProcessing ? 'Processing ... ' : `Pay:   $ ${formattedBudget}`}
          </span>
        </button>
      </div>

      {message && (
        <div id='payment-message' className={'text-red mt-2 font-semibold text-lg'}>
          {message}
        </div>
      )}
    </form>
  );
};

export default CheckoutForm;
