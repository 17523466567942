import React, { useEffect } from 'react';
import api from '../../Api/Indx';
import './Complete.css';

function Completion() {
  const urlParams = new URLSearchParams(window.location.search);
  const param1 = urlParams.get('payment_intent');

  const payid = localStorage.getItem('cmpid');

  const applypay = async () => {
    const payreference = {
      payment_reference: param1,
      processor: 'STRIPE',
    };
    await api.applyPayment(payid, payreference);
  };

  useEffect(() => {
    applypay();

    const timeout = setTimeout(() => {
      window.location = '/campaign-list';
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className='paysccf'>
      <h1>Thank you!</h1>
      <p>You are now being re-directed to the Campaign List page</p>
    </div>
  );
}

export default Completion;
