import React, { useEffect, useState, useContext } from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useNavigate, NavLink, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, reset } from '../../features/auth/authSlice';
import { formatDate } from '../../utils/plugins';
import { CampaignContext } from '../../state';
import api from '../Api/Indx';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Person } from '@mui/icons-material/';
import Storage from '../../utils/storage';
import { DataGrid } from '@mui/x-data-grid';
import Dropdown from '../Dropdown';
function Campaignlist() {
  const [loading, setLoading] = useState(false);

  const { setBudget, setIsConfirmModalOpen, setName, setCmpid, setClientSecret } =
    useContext(CampaignContext);

  const formatBudget = ({ cost, currency }) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(cost);
  };

  // handles Dashboard Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openn = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handles Logout

  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(reset());
    dispatch(logout());

    window.location = '/login';
  };

  const Createset = () => {
    //  navigate('/Set')
    window.location = '/my-profile';
  };

  const navigate = useNavigate();

  const Crecamp = () => {
    window.location = '/create-campaign';
  };
  const userinfo = JSON.parse(Storage.get('user'));

  // fetch campaign list
  const [camps, setCamps] = useState([]);
  const [rows, setRows] = useState([]);

  // Paginate Campaigns render in CardView
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedCamps = camps.slice(startIndex, endIndex);

  const columns = [
    {
      field: 'campaignName',
      headerName: 'CAMPAIGN NAME',
      width: 120,
      editable: false,
    },
    {
      field: 'startDate',
      headerName: 'START DATE',
      width: 200,
      editable: false,
      renderCell: (params) => <p>{formatDate({ date: params.row.startDate })}</p>,
    },
    {
      field: 'paymentStatus',
      headerName: 'PAYMENT STATUS',
      width: 120,
      editable: false,
    },
    {
      field: 'campaignStatus',
      headerName: 'CAMPAIGN STATUS',
      width: 200,
      renderCell: (params) => {
        const [status, date] = params.row.campaignStatus.split(' - ');

        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p className={'text-sm font-bold'}>{status}</p>
            <p className={'text-xs'}>{formatDate({ date })}</p>
          </div>
        );
      },
    },
    {
      field: 'costField',
      headerName: 'Cost',
      width: 110,
      renderCell: (params) => (
        <p>
          {formatBudget({
            cost: params.row.billing?.budget || 0,
            currency: params.row.billing?.budget_currency || 'USD',
          })}
        </p>
      ),
    },
    {
      field: 'spentField',
      headerName: 'Actual $ Spent',
      width: 110,
      renderCell: (params) => (
        <p>
          {formatBudget({
            cost: params.row.billing?.spend || 0,
            currency: params.row.billing?.spend_currency || 'USD',
          })}
        </p>
      ),
    },
    {
      field: 'payment',
      headerName: 'PAYMENT',
      width: 110,
      renderCell: (params) => {
        if (params.row?.paymentStatus !== 'PAID') {
          return (
            <button
              className={params.row?.paymentStatus === 'PAID' ? 'paynowGrey' : 'paynow'}
              disabled={params.row?.paymentStatus === 'PAID'}
              onClick={() => handlepay(params.row.billing?.budget, params.row.id, params.row.name)}
            >
              Pay Now
            </button>
          );
        }
        return <p></p>;
      },
    },
    {
      field: 'viewreport',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <div className={'flex gap-4'}>
          <Dropdown>
            {!params.row.endDate && ['ACTIVE', 'PAUSED'].includes(params.row.status) && (
              <MenuItem
                disableRipple
                onClick={() => toggleCampaign(params.row.id, params.row.status)}
              >
                <button className='text-sm px-2 py-1'>
                  {loading
                    ? 'loading...'
                    : params.row.status === 'PAUSED'
                    ? 'Resume campaign'
                    : 'Pause campaign'}
                </button>
              </MenuItem>
            )}
            <MenuItem
              disableRipple
              onClick={() => navigate(`/campaign/${params.row.id}`, { state: params.row })}
            >
              <button className='text-sm px-2 py-1'>View Report</button>
            </MenuItem>
            <MenuItem
              disableRipple
              className={''}
              onClick={() => navigate(`/campaign/edit/${params.row.id}`)}
            >
              <button className='text-sm px-2 py-1'>Edit Campaign</button>
            </MenuItem>
            <MenuItem disableRipple className={''}>
              <Link
                to={{
                  pathname: `/campaign/${params.row.id}`,
                  state: params.row,
                }}
                className='text-sm px-2 py-1'
              >
                Download invoice
              </Link>
            </MenuItem>
          </Dropdown>
        </div>
      ),
    },
  ];

  const handlepay = async (amount, id, name) => {
    // setSelectedVenues(screens)
    setName(name);
    setBudget(amount);
    setCmpid(id);

    // This is extremely important! dont touch it
    localStorage.setItem('cmpid', id);

    const data = {
      amount: Number(amount),
      description: 'Payment ',
    };

    try {
      const client_Secret = await api.createStripePaymentIntent(data);
      let {
        payload: { clientSecret },
      } = client_Secret;
      setClientSecret(clientSecret);

      clientSecret && setIsConfirmModalOpen(true);
    } catch (e) {
      console.log(e);
    }
  };

  const getCamps = async () => {
    const camp = await api.fetchCampaigns();
    setCamps([...camp.payload.campaigns]);
    console.log([...camp.payload.campaigns]);
    setRows(
      camp.payload.campaigns.map((item) => ({
        id: item.id,
        campaignName: item.name,
        startDate: item.startDate,
        endDate: item.endDate,
        paymentStatus: item.paymentStatus && item.paymentStatus.replace('_', ' '),
        campaignStatus: `${item.status} - ${item.createdAt}`,
        status: `${item.status}`,
        payment: item.billing?.budget,
        viewreport: item.id,
        invoice: item.id,
        billing: item.billing,
        name: item.name,
      })),
    );
  };

  const toggleCampaign = async (id, status) => {
    try {
      setLoading(true);
      if (status === 'PAUSED') {
        await api.resumeCampaign(id);
      } else {
        await api.pauseCampaign(id);
      }
      await getCamps();
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCamps();
  }, []);

  console.log(paginatedCamps);
  return (
    <div className='set1'>
      <div className='navparent'>
        <nav className='nav1'>
          <NavLink to='/dashboard'>
            <img src='diyo.svg' className='navlogo' />
          </NavLink>

          <h3 className='navtitle'>Dashboard</h3>

          <div className='navsidemenu'>
            <span className='navtitleName' onClick={handleClick}>
              {' '}
              {userinfo?.firstName}
            </span>
            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Tooltip title='Account settings'>
                  <IconButton
                    onClick={handleClick}
                    size='small'
                    sx={{ ml: 1 }}
                    aria-controls={openn ? 'account-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={openn ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 29, height: 32 }}>
                      <ExpandMore />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={openn}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 29,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 8,
                      height: 8,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={Createset}>
                  <Person style={{ color: '#5E16EB', fontFamily: 'ProductSans', fontSize: 15 }} />{' '}
                  My Profile
                </MenuItem>

                <MenuItem onClick={onLogout}>
                  <Logout
                    style={{ color: '#5E16EB', fontFamily: 'ProductSans', fontSize: 15 }}
                    fontSize='small'
                  />
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          </div>
        </nav>

        <nav className='nav2'>
          <ul className='navlist'>
            <NavLink
              to='/dashboard'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
            >
              Home
            </NavLink>
            <NavLink
              to='/campaign-list'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
            >
              Campaign List
            </NavLink>
          </ul>

          <button onClick={Crecamp} className='cmplstCmp'>
            Create campaign
          </button>
        </nav>
      </div>

      <div className='cmplistt'>
        <div className='ListTb' style={{ marginTop: 20 }}>
          <div
            style={{
              marginBottom: 30,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            role='presentation'
          >
            <span style={{ fontWeight: 400, color: '#5E16EB' }}>Campaign List</span>
          </div>

          <div className='ListCards'>
            {paginatedCamps.map((item, index) => (
              <div className='ReportCard' key={index}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>
                    Campaign name: <span>{item.name}</span>
                  </p>
                </div>

                <p>
                  Start Date: <span>{formatDate({ date: item.startDate })} </span>
                </p>
                <p>
                  Payment Status: <span>{item.paymentStatus}</span>
                </p>
                <p>
                  Campaign Status: <span>{item.status}</span>
                </p>

                <div className='reportbuttons'>
                  <button
                    className={item?.paymentStatus === 'PAID' ? 'paynowGrey' : 'paynow'}
                    disabled={item?.paymentStatus === 'PAID'}
                    onClick={() =>
                      handlepay(item?.billing?.budget, item?.id, item?.name, item?.targets)
                    }
                  >
                    Pay Now
                  </button>

                  <Dropdown>
                    {!item.endDate && ['ACTIVE', 'PAUSED'].includes(item.status) && (
                      <MenuItem
                        disableRipple
                        className={''}
                        onClick={() => toggleCampaign(item.id, item.status)}
                      >
                        <button className='text-sm'>
                          {loading
                            ? 'loading...'
                            : item.status === 'PAUSED'
                            ? 'Resume campaign'
                            : 'Pause campaign'}
                        </button>
                      </MenuItem>
                    )}
                    <MenuItem
                      disableRipple
                      onClick={() => navigate(`/Campaign/${item.id}`, { state: item })}
                    >
                      <button className={'text-sm'}>View Report</button>
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      className={''}
                      onClick={() => navigate(`/campaign/edit/${item.id}`)}
                    >
                      <button className='text-sm'>Edit Campaign</button>
                    </MenuItem>
                    <MenuItem
                      disableRipple
                      className={''}
                      onClick={() => navigate(`/Campaign/${item.id}`, { state: item })}
                    >
                      <button className='text-sm'>Download invoice</button>
                    </MenuItem>
                  </Dropdown>
                </div>
              </div>
            ))}

            <div className='NextCrdview-buttons'>
              <button onClick={() => setCurrentPage(currentPage - 1)} disabled={startIndex == 0}>
                Prev
              </button>

              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={endIndex >= camps.length}
              >
                Next
              </button>
            </div>

            <div style={{ display: 'flex', marginTop: 10 }}>
              <span>
                {' '}
                {startIndex}/{endIndex}{' '}
              </span>
            </div>
          </div>

          <div className='ListTable' style={{ overflowX: 'hidden' }}>
            <DataGrid
              style={{ fontSize: 12, fontWeight: 400, fontFamily: 'ProductSans' }}
              rows={rows}
              columns={columns}
              pageSize={7}
              rowsPerPageOptions={[7]}
              headerHeight={50}
              headerAlign='center'
              align='center'
              rowHeight={55}
              disableColumnMenu
              disableColumnSelector
              checkboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Campaignlist;
