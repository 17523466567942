import React from "react";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import "mapbox-gl/dist/mapbox-gl.css";
import { NavLink } from "react-router-dom";
import "../Desktop/Campaign.css"



const Accordion = styled((props) => (
      <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
      border: `1px solid ${theme.palette.divider}`,
      '&:not(:last-child)': {
            borderBottom: 0,
      },
      '&:before': {
            display: 'none',
      },
}));

const AccordionSummary = styled((props) => (
      <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
      />
))(({ theme }) => ({
      backgroundColor:
            theme.palette.mode === 'dark'
                  ? 'rgba(255, 255, 255, .05)'
                  : 'rgba(0, 0, 0, .03)',
      flexDirection: 'row-reverse',
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
      },
      '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
      },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
      padding: theme.spacing(2),
      borderTop: '1px solid rgba(0, 0, 0, .125)',
}));




const Faq = () => {




      // handles Dashboard Menu
      const [setAnchorEl] = React.useState(null);

      const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
      };
   






     
      return (



            <div className='faqiii'>

                  <div className="navparent">

                        <nav className="nav1">

                              <NavLink to="/dashboard"><img src="diyo.svg" className='navlogo' /></NavLink>

                              <h3 className="navtitle">FAQ</h3>

                              <div className="navsidemenu">
                                   
                              </div>

                        </nav>


{/* 
                        <nav className="nav2">



                              <ul className="navlist">
                                    <NavLink to="/dashboard" style={{ color: "rgba(68, 68, 68, 0.51)", textDecoration: "none", fontWeight: 400, fontSize: 15 }}>Home</NavLink>
                                    <NavLink to="/create-campaign" style={{ color: "rgba(68, 68, 68, 0.51)", textDecoration: "none", fontWeight: 400, fontSize: 15 }}>Campaigns</NavLink>
                                    
                              </ul>

                              <button
                                    variant="contained"
                                    onClick={Crecamp}
                                    className="cmplstCmp"
                              >Create campaign</button>



                        </nav> */}


                  </div>

                  <div className="faqlist">

                        <div style={{ marginBottom: 30, display: "flex", justifyContent: "space-between", alignItems: "center" }} role="presentation" onClick={handleClick}>
                              <span style={{ fontWeight: 400, color: "#5E16EB" }}>Frequently asked questions</span>

                        </div>

                        <Accordion>
                              <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                              >
                                    <Typography>What does DIYO stand for? What is this app?</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                    <Typography>
                                          DIYO stands for do-it-yourself outdoor advertising. We&apos;ve made it ridiculously easy for you to launch impactful and efficent digital billboard advertising anywhere in the world in less than 60 seconds.</Typography>
                              </AccordionDetails>
                        </Accordion>
                        <Accordion>
                              <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                              >
                                    <Typography>Is OOH advertising cheaper than Google/Instagram/Facebook?</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                    <Typography>
                                          Yes! On average, you&apos;ll pay close to $12 USD per 1000 impressions (CPM) on Google/Instagram/Facebook vs. $6.50 CPM on DIYO!</Typography>
                              </AccordionDetails>
                        </Accordion>
                        <Accordion>
                              <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                              >
                                    <Typography>What is CPM? What is an impression? What is a reach?</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                    <Typography>
                                          CPM stands for cost per 1000 impressions. </Typography>
                                    <Typography>
                                          An impression/reach is a view of your ad.          </Typography>
                                    <Typography>
                                          Out-of-home advertising measures and places a price on a particular billboard based on its location, duration of ad, and proximity to people. A CPM number can be anywhere from $1 to $100 depending on how premium the billboard location/spot is. </Typography>
                              </AccordionDetails>
                        </Accordion>
                        <Accordion>
                              <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                              >
                                    <Typography>DIYO: What is a campaign and how do I launch it?</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                    <Typography>
                                          A DIYO campaign is an advertisement that you can deploy on multiple outdoor billboards anywhere in the world.
                                          Specifically, with DIYO, one unique creative (Image or Video) is uploaded to be deployed on different billboards of your choosing.
                                          It&apos;s important to note that DIYO has a 1:1 relationship between creative and campaign. So you cannot upload different creatives in a single campaign.
                                          However, you can upload a unique creative with different resolutions (to match the ones that your chosen screens require!)  </Typography>
                              </AccordionDetails>
                        </Accordion>
                        <Accordion>
                              <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                              >
                                    <Typography>DIYO: I paid for my campaign but it was rejected?</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                    <Typography>
                                          When you pay for a campaign, your credit card is immediately charged. However, if your uploaded creative is rejected for whatever reason by the approvers of billboards, your campaign will be rejected and the amount of the campaign refunded back to your credit card.
                                          You will, however, receive an email stating the reason why your campaign was rejected to you can fix and re-deploy.  </Typography>
                              </AccordionDetails>
                        </Accordion>
                        <Accordion>
                              <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                              >
                                    <Typography>How do I contact someone for support?</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                    <Typography>
                                          Please email your campaign name, account email and reason for contact to contact @ diyo.ca </Typography>
                              </AccordionDetails>
                        </Accordion>



                  </div>


            </div>



      );
};

export default Faq;