import React, { useContext } from "react";
import { CampaignContext } from "../../../state";
import './ModalBackdrop.css'

function ModalBackdrop() {
    const { isConfirmModalOpen, setIsConfirmModalOpen } = useContext(CampaignContext)

    
	return <div onClick={()=>setIsConfirmModalOpen(false)} className={isConfirmModalOpen ? `backdrop  backdrop__active` : "backdrop"}></div>;
    
}

export default ModalBackdrop;
