import React, { useContext } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
import FourthStep from './FourthStep';
import FifthStep from './FifthStep';
import './VerticalLinearStepper.css';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';

import { CampaignContext } from '../../../state';
import { useParams } from 'react-router-dom';

// Stepper styling
const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '80%',
      alignSelf: 'center',
      marginTop: '80px',
    },

    button: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      alignContent: 'right',
      backgroundColor: '#F44336',
      fontFamily: 'ProductSans',
      color: '#FFF',
    },

    button1: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      alignContent: 'right',
      backgroundColor: '#FFF',
      border: 'solid',
      fontFamily: 'ProductSans',
      color: '#00000',
    },

    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },

    stepIcon: {
      color: 'rgba(68, 68, 68, 0.51)',
      '&$active': {
        color: '#5E16EB',
      },
      '&$completed': {
        color: '#5E16EB',
      },
    },

    active: {},
    completed: {},

    input1: {
      height: 90,
    },
    input2: {
      height: 10,
      fontSize: '3em',
    },
  }),
);

function VerticalLinearStepper() {
  const classes = useStyles();

  const { step, setStep, selectedCampaign } = useContext(CampaignContext);
  const { campaignId } = useParams();

  const changeStep = (e, index) => {
    e.preventDefault();
    if (selectedCampaign.paymentStatus !== 'PAID') {
      setStep(index);
    }
  };

  function GetSteps() {
    return [
      <div
        style={{ display: 'flex', alignItems: 'center', gap: 5 }}
        key={1}
        className={'cursor-pointer'}
        onClick={(e) => changeStep(e, 0)}
      >
        <span style={{ fontFamily: 'ProductSans', fontWeight: 600, fontSize: '16px' }}>
          Name your campaign
        </span>
        <Tooltip
          title='Name your campaign something unique or identifiable. E.g. “Feb5_Superbowl_NYC”'
          placement='top-end'
        >
          <InfoIcon fontSize='small' />
        </Tooltip>
      </div>,

      <div
        style={{ display: 'flex', alignItems: 'center', gap: 5 }}
        key={2}
        className={'cursor-pointer'}
        onClick={(e) => changeStep(e, 1)}
      >
        <span style={{ fontFamily: 'ProductSans', fontWeight: 600, fontSize: '16px' }}>
          How do you want to deploy your campaign?
        </span>
        <Tooltip
          title='If you already know where and what screens/billboards you want to advertise your campaign on, use our map on the right to zoom in to your desired location and Select your screens. '
          placement='top-end'
        >
          <InfoIcon fontSize='small' />
        </Tooltip>
      </div>,

      <div
        style={{ display: 'flex', alignItems: 'center', gap: 5 }}
        key={3}
        className={'cursor-pointer'}
        onClick={(e) => changeStep(e, 2)}
      >
        <span style={{ fontFamily: 'ProductSans', fontWeight: 600, fontSize: '16px' }}>
          Who do you want to target with your ad?
        </span>
        <Tooltip
          title='If you’d like to narrow down the list of screens on the map, filter by categories like “Retail” or “Outdoor” '
          placement='top-end'
        >
          <InfoIcon fontSize='small' />
        </Tooltip>
      </div>,

      <div
        style={{ display: 'flex', alignItems: 'center', gap: 5 }}
        key={4}
        className={'cursor-pointer'}
        onClick={(e) => changeStep(e, 3)}
      >
        <span style={{ fontFamily: 'ProductSans', fontWeight: 600, fontSize: '16px' }}>
          Campaign Style
        </span>
        <Tooltip
          title='If you’re coming in with a specific $ budget in mind to spend on an out-of-home campaign, use the first radio button and the slider to select your budget. If you would rather let DIYO tell you how much your campaign will cost, give us your desired start/end time for the campaign and we’ll calculate the cost and expected reach. '
          placement='top-end'
        >
          <InfoIcon fontSize='small' />
        </Tooltip>
      </div>,

      <div
        style={{ display: 'flex', alignItems: 'center', gap: 5 }}
        key={5}
        className={'cursor-pointer'}
        onClick={(e) => changeStep(e, 4)}
      >
        <span style={{ fontFamily: 'ProductSans', fontWeight: 600, fontSize: '16px' }}>
          Upload your ad asset/creative (image or video)
        </span>
        <Tooltip title='Upload your ad asset/creative (image or video)' placement='top-end'>
          <InfoIcon fontSize='small' />
        </Tooltip>
      </div>,
    ];
  }

  function showstep(step) {
    switch (step) {
      case 0:
        return <FirstStep />;

      case 1:
        return <SecondStep />;

      case 2:
        return <ThirdStep />;

      case 3:
        return <FourthStep />;

      case 4:
        return <FifthStep />;
    }
  }

  const steps = GetSteps();

  return (
    <div className='stepiii'>
      <div className='stepperstyle'>
        <div className='tstpp'>
          <section className={' flex justify-between items-center'}>
            <div>
              <p>{campaignId ? `Edit Campaign` : `Create a Campaign`}</p>
              <span>
                {campaignId
                  ? `Update Campaign Details`
                  : `Step by Step guide for creating your Campaign..`}
              </span>
            </div>
            {campaignId && (
              <span className={'crtcmpgcamp !w-auto'}>
                {selectedCampaign.paymentStatus && selectedCampaign.paymentStatus.replace('_', ' ')}
              </span>
            )}
          </section>

          <Stepper activeStep={step} orientation='vertical'>
            {steps.map((label, index) => (
              <Step key={index}>
                <StepLabel
                  key={index}
                  StepIconProps={{
                    classes: {
                      root: classes.stepIcon,
                      active: classes.active,
                      completed: classes.completed,
                    },
                  }}
                >
                  {label}
                </StepLabel>

                <StepContent>
                  <div className='showstep'>{showstep(index)}</div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    </div>
  );
}

export default VerticalLinearStepper;
