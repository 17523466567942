import React, { useContext } from 'react';
import { CampaignContext } from '../../../state';

function SecondStep() {
  const { step, setStep, setActiveTab } = useContext(CampaignContext);

  const handleNextChange = () => {
    setStep(step + 1);
    localStorage.setItem('step', step + 1);
  };

  const handlePreviousChange = () => {
    setStep(step - 1);
    localStorage.setItem('step', step - 1);
  };

  return (
    <div>
      <div className='dw2'>
        <div className='selectopt'>
          <label className='optstyling'>
            <input
              type='radio'
              value='op1'
              name='options'
              onChange={() => setActiveTab('3')}
              style={{ color: '#5E16EB' }}
            />
            I want to select screens on the map
          </label>
        </div>
        <div className='selectopt'>
          <label className='optstyling'>
            <input
              type='radio'
              value='op2'
              name='options'
              style={{ color: '#5E16EB' }}
              onChange={() => setActiveTab('1')}
            />
            Filter by Screen category
          </label>
        </div>
        <div className='selectopt'>
          <label className='optstyling'>
            <input
              type='radio'
              value='op2'
              name='options'
              style={{ color: '#5E16EB' }}
              onChange={() => setActiveTab('2')}
            />
            Filter by Cost Per 1000 impressions (CPM)
          </label>
        </div>
        {/*<div className='selectopt'>*/}
        {/*  <label className='optstyling'>*/}
        {/*    <input*/}
        {/*      type='radio'*/}
        {/*      value='op2'*/}
        {/*      name='options'*/}
        {/*      style={{ color: '#5E16EB' }}*/}
        {/*      onChange={() => setActiveTab('3')}*/}
        {/*    />*/}
        {/*    Filter by Income*/}
        {/*  </label>*/}
        {/*</div>*/}
        <div className='selectopt'>
          <label className='optstyling'>
            <input
              type='radio'
              value='op2'
              name='options'
              style={{ color: '#5E16EB' }}
              onChange={() => setActiveTab('4')}
            />
            Filter by Screen dimensions
          </label>
        </div>
      </div>

      <div className='backnext'>
        <button onClick={() => handlePreviousChange()} className='crtcmpg2'>
          BACK
        </button>
        <button onClick={() => handleNextChange(step + 1)} className='crtcmpg'>
          NEXT
        </button>
      </div>
    </div>
  );
}

export default SecondStep;
