import React, { Suspense, lazy } from 'react';
import './App.css';
import Landingpage from './Components/Desktop/home';
import { Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import useMediaQuery from './Components/useMediaQuery';
import Dashboard from './Components/Desktop/Dashboard';
import Campaigns from './Components/Desktop/create-campaign';
import EditCampaign from './Components/Desktop/edit-campaign';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Setting from './Components/Settings/my-profile';
import Campaignreport from './Components/Desktop/Campaignreport';
import Campaignlist from './Components/Desktop/campaign-list';
import { PrivateRoute } from './utils/PrivateRoutes';
import { history } from './Helpers/history';
import CampaignContextApi from './state';
import ConfirmPaymentModal from './Components/Desktop/Modall/Modall';
import ModalBackdrop from './Components/Desktop/ModalBackdrop/ModalBackdrop';
import ComfirmOrganizationModal from '../src/Components/Desktop/Orgmodal/ModalOrg';
import OrgDrop from '../src/Components/Desktop/Orgmodal/OrgDrop';
import Completion from './Components/Desktop/Modall/comfirm-payment';
import Register from './Components/create-account';
import Forgotpassword from './Components/forgot-password';
import Confirmpassword from './Components/confirmpassword';
import Panview from './Components/Map/billboard-streetview';
import MobileViewResetPassword from '../src/Components/Mobile/MobileViewResetPassword';
import MobileViewSigUp from '../src/Components/Mobile/MobileViewSignUp';
import Mobileconfirmpassword from '../src/Components/Mobile/Mobileconfirmpassword';
import Spinner from './Components/Spinner';
import Privacy from './Components/Privacy/privacy-policy';
import Modall from './Components/Desktop/Modall/Modall';
import Faq from './Components/FAQ/faq';
import Terms from './Components/FAQ/terms-and-conditions';

const Login = lazy(() => import('./Components/login'));
const MobileViewSignIn = lazy(() => import('../src/Components/Mobile/MobileViewSignIn'));

function App() {
  // const [isLoading, setIsLoading] = useState(true);

  // useEffect(() => {
  // 	setTimeout(() => {
  // 		setIsLoading(false);
  // 	}, 2000);
  // });

  // Campaignlist
  const matches = useMediaQuery('xmd');

  // init custom history object to allow navigation from
  // anywhere in the react app (inside or outside components)
  history.navigate = useNavigate();
  history.location = useLocation();

  return (
    <>
      <CampaignContextApi>
        <div>
          <ConfirmPaymentModal />
          <ModalBackdrop />
          <ComfirmOrganizationModal />
          <OrgDrop />
        </div>
        <div>
          <Suspense fallback={<Spinner />}>
            <Routes>
              <Route
                path='/my-profile'
                element={
                  <PrivateRoute>
                    <Setting />
                  </PrivateRoute>
                }
              >
                {' '}
              </Route>

              <Route
                path='/billboard-streetview'
                element={
                  <PrivateRoute>
                    <Panview />
                  </PrivateRoute>
                }
              >
                {' '}
              </Route>

              <Route path='/privacy-policy' element={<Privacy />}>
                {' '}
              </Route>

              <Route path='/faq' element={<Faq />}>
                {' '}
              </Route>

              <Route path='/terms-and-conditions' element={<Terms />}>
                {' '}
              </Route>

              <Route path='/Modall' element={<Modall />}>
                {' '}
              </Route>

              <Route path='/home' element={<Landingpage />}>
                {' '}
              </Route>

              <Route
                path='/reset-password'
                element={matches ? <Confirmpassword /> : <Mobileconfirmpassword />}
              >
                {' '}
              </Route>

              <Route path='/MobileViewSignIn' element={<MobileViewSignIn />}>
                {' '}
              </Route>

              <Route path='/MobileViewResetPassword' element={<MobileViewResetPassword />}>
                {' '}
              </Route>

              <Route path='/Mobileconfirmpassword' element={<Mobileconfirmpassword />}>
                {' '}
              </Route>

              <Route path='/MobileViewSigUp' element={<MobileViewSigUp />}>
                {' '}
              </Route>

              <Route
                path='/forgot-password'
                element={matches ? <Forgotpassword /> : <MobileViewResetPassword />}
              >
                {' '}
              </Route>

              <Route
                path='/create-account'
                element={matches ? <Register /> : <MobileViewSigUp />}
              ></Route>

              <Route path='/login' element={matches ? <Login /> : <MobileViewSignIn />}></Route>

              <Route
                path='/comfirm-payment'
                element={
                  <PrivateRoute>
                    <Completion />
                  </PrivateRoute>
                }
              >
                {' '}
              </Route>

              <Route
                path='/campaign/:campaignId'
                element={
                  <PrivateRoute>
                    <Campaignreport />
                  </PrivateRoute>
                }
              >
                {' '}
              </Route>

              <Route
                path='/campaign/edit/:campaignId'
                element={
                  <PrivateRoute>
                    <EditCampaign />
                  </PrivateRoute>
                }
              />

              <Route
                path='/campaign-list'
                element={
                  <PrivateRoute>
                    <Campaignlist />
                  </PrivateRoute>
                }
              >
                {' '}
              </Route>

              <Route
                path='/dashboard'
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              >
                {' '}
              </Route>

              <Route path='/' element={<Landingpage />}>
                {' '}
              </Route>

              <Route
                path='/create-campaign'
                element={
                  <PrivateRoute>
                    <Campaigns />
                  </PrivateRoute>
                }
              >
                {' '}
              </Route>

              <Route path='*' element={<Navigate to='/' />}>
                {' '}
              </Route>
            </Routes>
          </Suspense>
        </div>
        <ToastContainer />
      </CampaignContextApi>
    </>
  );
}

export default App;
