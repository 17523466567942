import React, {useContext, useState} from 'react'
import { CampaignContext } from "../../../state";

const CostFilter = () => {
    const { costFilters, setCostFilters } = useContext(CampaignContext);

    const [filters, setFilters] = useState({...costFilters})
    const limits = {minimum:0, maximum:1000}

    const handleInput = ({target}) => {
        const {name, value} = target;
        setFilters({...filters, [name]:validateCosts({name,value})})
    }
    const handleBlur = ({target}) => {
        const {name, value} = target;

        const formattedValue = parseFloat(validateRange({value, name})).toFixed(2) || ''
        setFilters({...filters, [name]:formattedValue})
    }

    const validateCosts = ({name, value}) => {
        if(name === 'minimum'){
            return limits[name] > value ? limits[name]: value
        }
        if(name === 'maximum'){
            return limits[name] < value ? limits[name] : value

        }
    }
    const validateRange = ({name, value}) => {
        if(name === 'minimum'){
            return  parseFloat(value) > parseFloat(filters.maximum) ? filters.maximum : value
        }
        if(name === 'maximum'){
            return  parseFloat(value) > parseFloat(filters.minimum) ? value : filters.minimum

        }
    }
    const handleFilter = () => {
        setCostFilters({...filters})
    }
    const resetForm = () => {
        setFilters({minimum:limits.minimum, maximum:limits.maximum})
        setCostFilters({...filters})
    }

    return (
        <section>
            <form className={'flex flex-col gap-4'}>
                <div className="flex flex-row gap-2 items-center">
                    <label htmlFor="min_amount" className={'text-sm font-medium'}>Min $ CPM</label>
                    <input type="text" id={'min_amount'} name={'minimum'} value={filters.minimum} className={'border h-10 rounded indent-5 w-2/3 lg:w-1/4'} onChange={handleInput} onBlur={handleBlur}/>
                </div>
                <div className="flex flex-row gap-2 items-center">
                    <label htmlFor="max_amount" className={'text-sm font-medium'}>Max $ CPM</label>
                    <input type="text" id={'max_amount'} name={'maximum'} value={filters.maximum} className={'border h-10 rounded indent-5 w-2/3 lg:w-1/4'} onChange={handleInput} onBlur={handleBlur}/>
                </div>
                <div className={'flex flex-row items-center gap-4'}>
                    <button className={'crtcmpg2'} onClick={resetForm} type={'button'}>Clear</button>
                    <button className={'crtcmpg2 !bg-[#5E16EB] !text-white'} onClick={handleFilter} type={'button'}>Filter</button>
                </div>
            </form>
        </section>
    )
}

export default CostFilter