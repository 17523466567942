import React from 'react';
import { Puff } from 'react-loader-spinner';
// import CircularProgress from '@mui/material/CircularProgress';

function MapLoader() {
  return (
    <div className='maploader flex flex-col items-center gap-1 italic'>
      <Puff color='#5E16EB' height={50} width={50} timeout={1000} />
      <p className={'text-lg mt-2 font-bold'}>Loading screens...</p>
    </div>
  );
}

export default MapLoader;
