import { useState, useEffect } from 'react';

const breakPoints = {
  sm: '(min-width: 600px)',
  md: '(min-width: 800px)',
  xmd: '(min-width: 900px)',
  lg: '(min-width: 1200px)',
  xl: '(min-width: 1600px)',

  // xl: '(min-width: 1600px)',
};

const useMediaQuery = (breakpointName) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const value = breakPoints[breakpointName];
    const mq = window.matchMedia(value);

    setMatches(mq.matches);

    const handler = (e) => setMatches(e.matches);
    mq.addListener(handler);

    return () => {
      mq.removeListener(() => console.log(''));
    };
  }, []);
  return matches;
};

export default useMediaQuery;
