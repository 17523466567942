import React, { useContext } from "react";
import "./Modal.css";
import { CampaignContext } from "../../../state";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";


const REACT_APP_STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

const stripePromise = loadStripe(REACT_APP_STRIPE_KEY);



function Modall() {


	const {isConfirmModalOpen,clientSecret} = useContext(CampaignContext);





	return (



		<div className={isConfirmModalOpen ? `modalpop  modal__active` : "modalpop"}>


			{clientSecret && stripePromise && (

				<Elements stripe={stripePromise} options={{ clientSecret }}>

					<CheckoutForm />

				</Elements>


			)}
		</div>








	);
}

export default Modall;
