import React from 'react';
import { Puff } from 'react-loader-spinner';

function Loader() {
  return (
    <div className='puff'>
      <Puff color='#5E16EB' height={50} width={50} timeout={1000} />
    </div>
  );
}

export default Loader;
