import React, { useEffect, useContext, useState } from 'react';
import './Campaign.css';
import Storage from '../../utils/storage';
import useMediaQuery from '../useMediaQuery';
import Loader from '../Loader';
import 'react-accessible-accordion/dist/fancy-example.css';
import CampaignForm from './CampaignForms/VerticalLinearStepper';
import { logout, reset } from '../../features/auth/authSlice';
import { NavLink, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import MainMap from '../Map/MainMap';
import addUnitsApi from '../Api/Indx';

// Material Design Dashoboard Menu
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import { Person } from '@mui/icons-material/';
import { ExpandMore } from '@mui/icons-material';
import { CampaignContext } from '../../state';

// GRID LAYOUT

import '../Map/FloatingAcion.css';
import { FaTimes } from 'react-icons/fa';
import api from '../Api/Indx';

function EditCampaign() {
  const {
    setStep,
    setName,
    personName,
    points,
    setPoints,
    screenvenuetypes,
    setActiveTab,
    selectedVenues,
    setSelectedVenues,
    display,
    setSelectedCampaign,
    _setSelectedVenues,
    setStartTimeRad2,
    setStartDateRad1,
    setStartTimeRad1,
    setBudgetsRad1,
    setStartDateRad2,
    setEndDateRad2,
    setEndTimeRad2,
    setCost2,
  } = useContext(CampaignContext);

  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const matches = useMediaQuery('md');
  const [loading, setLoading] = useState(false);
  const getMapInfo = async () => {
    try {
      const _data = {
        filters: {
          publishers: personName,
          screen_types: screenvenuetypes,
        },
        min: {
          latitude: 8.293134936967334,
          longitude: -137.05575020807603,
        },
        max: {
          latitude: 57.75047620456547,
          longitude: -5.104046203339891,
        },
      };

      const data = await addUnitsApi.getAddUnits(_data);

      setPoints(data.payload?.Positions);

      // setLoading(false);
    } catch (err) {
      // setLoading(false);
      console.log(err);
    }
  };

  // handles Dashboard Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [blockerWarningHidden, setBlockerWarningHidden] = useState(
    !!Storage.get('blockerWarningHidden'),
  );

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handles Logout

  // const { user } = useSelector((state) => state.auth);

  const onLogout = () => {
    dispatch(reset());
    dispatch(logout());

    window.location = '/login';
  };

  const Createset = () => {
    //  navigate('/Set')
    window.location = '/my-profile';
  };

  const userinfo = JSON.parse(Storage.get('user'));
  const hideBlockerWarning = (e) => {
    e.preventDefault();
    setBlockerWarningHidden(true);
    Storage.set('blockerWarningHidden', true);
  };
  // useMediaQuery


  const setCampaignDates = async (response) => {
    const [startDate, startTime] = response.startDate.split('T');
    const startTimes = startTime.split('+');
    if (response.endDate) {
      const [endDate, endTime] = response.endDate.split('T');
      const endTimes = endTime.split('+');
      setStartDateRad2(startDate);
      setStartTimeRad2(startTimes[0]);
      setEndDateRad2(endDate);
      setEndTimeRad2(endTimes[0]);
      setCost2(response.billing.budget);
    } else {
      setStartDateRad1(startDate);
      setStartTimeRad1(startTimes[0]);
      setBudgetsRad1(response.billing.budget);
    }
  };

  const setCampaignData = async (response) => {
    await setSelectedCampaign({ ...response });
    await setName(response.name);
    await getAllAdUnits(response.targets);
    setCampaignDates(response)
    await setActiveTab('4');
    if (response.paymentStatus === 'PAID') {
      setStep(4);
    } else {
      setStep(2);
    }
  };

  const fetchCampaign = async (id) => {
    try {
      setLoading(true);
      const res = await api.getCampaign(id);
      const campaign = res.payload.campaign;
      setCampaignData(campaign);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchLocationInfo = async (id) => {
    try {
      const _res = await api.getLocationInfo(id);
      // console.log(_res, 'setFetchingLocationInfo function');
      return _res.payload.adUnit;
    } catch (err) {
      console.log(err);
    }
  };

  const getAllAdUnits = async (targets) => {
    await Promise.all(targets.map((target) => fetchLocationInfo(target))).then((targetUnits) => {
      setSelectedVenues(targetUnits);
      _setSelectedVenues(targetUnits);
    });
  };

  useEffect(() => {
    getMapInfo();
  }, []);

  useEffect(() => {
    fetchCampaign(campaignId);
  }, [campaignId]);

  return (
    <div className='map1'>
      <div className='navparent'>
        <nav className='nav1'>
          <NavLink to='/dashboard'>
            <img src='/diyo.svg' className='navlogo' alt={'logo'} />
          </NavLink>

          <h3 className='navtitle'>Dashboard</h3>

          <div className='navsidemenu'>
            <span className='navtitleName'> {userinfo?.firstName}</span>

            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Tooltip title='Account settings'>
                  <IconButton
                    onClick={handleClick}
                    size='small'
                    sx={{ ml: 1 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 29, height: 32 }}>
                      <ExpandMore />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 29,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 8,
                      height: 8,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={Createset}>
                  <Person style={{ color: '#5E16EB' }} /> My Profile
                </MenuItem>

                <MenuItem onClick={onLogout}>
                  <Logout style={{ color: '#5E16EB' }} fontSize='small' />
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          </div>
        </nav>

        <nav className='nav2'>
          <ul className='navlist'>
            <NavLink
              to='/dashboard'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
            >
              Home
            </NavLink>
            <NavLink
              to='/campaign-list'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
            >
              Campaign List
            </NavLink>
          </ul>

        </nav>
        {!blockerWarningHidden && (
          <div className={'text-center'}>
            <div className='add-blocker__warning bg-white py-2 px-4 gap-4 rounded inline-flex justify-center border border-gray mx-auto'>
              <p>
                If you don&apos;t see any map markers when you zoom in, please consider disabling
                any ad blockers and refreshing the page.
              </p>
              <button className='nav-btn nav-close-btn' onClick={hideBlockerWarning}>
                <FaTimes style={{ color: '#F44336' }} />
              </button>
            </div>
          </div>
        )}
      </div>

      {matches && (
        <div className='grid-container'>
          <div className='formside'>
            {(loading && <Loader />) || <CampaignForm selectedVenues={selectedVenues} />}
          </div>

          <div className='mapside'>
            {/* <MainMap
							points={points}
							_points={_points}
							setPoints={setPoints}
							selectedVenues={selectedVenues}
							setSelectedVenues={setSelectedVenues}
						/> */}

            <MainMap
              points={points}
              setPoints={setPoints}
              selectedVenues={selectedVenues}
              setSelectedVenues={setSelectedVenues}
            />
          </div>
        </div>
      )}

      {!matches && (
        <div className='grid-container'>
          {display && (
            <>
              <div className='formside' style={{ visibility: 'visible' }}>
                <CampaignForm selectedVenues={selectedVenues} />
              </div>
              {/*
							<div className="FloatingButton">
								<Box sx={{ '& > :not(style)': { m: 1 } }} className="float">
									<Fab variant="extended" sx={{ background: "#FFF" }} onClick={() => setDisplay(!display)}>
										<MapIcon sx={{ mr: 1, color: "#5E16EB" }} />
										Map
									</Fab>

								</Box>
							</div> */}
            </>
          )}

          {!display && (
            <div className='mapside' style={{ visibility: 'visible' }}>
              {/* <MainMap
								points={points}
								_points={_points}
								setPoints={setPoints}
								selectedVenues={selectedVenues}
								setSelectedVenues={setSelectedVenues}
							/> */}

              <MainMap
                points={points}
                setPoints={setPoints}
                selectedVenues={selectedVenues}
                setSelectedVenues={setSelectedVenues}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default EditCampaign;
