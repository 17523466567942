import React, { useContext } from 'react';
import { CampaignContext } from '../../../state';

function FirstStep() {
  const { step, setStep, setName, name } = useContext(CampaignContext);

  const [errorMessage, setErrorMessage] = React.useState(false);

  const handleStepChange = (e) => {
    e.preventDefault();

    if (name.length === 0) {
      setErrorMessage(true);
    } else {
      setStep(step + 1);
      localStorage.setItem('step', step + 1);
    }
  };

  return (
    <div>
      <div>
        <input
          value={name}
          onChange={(e) => setName(e.target.value)}
          className='campname'
          type='text'
          required
          spellCheck
          placeholder='Campaign Name'
        />
      </div>

      <div>
        <button onClick={handleStepChange} className='crtcmpg'>
          NEXT
        </button>
      </div>

      {errorMessage && name.length <= 0 ? (
        <div style={{ color: 'red', fontSize: '12px', fontWeight: 400 }}>Field cannot be empty</div>
      ) : (
        ''
      )}
    </div>
  );
}

export default FirstStep;
