import { DateTime } from 'luxon';

export const formatBudget = ({ cost, currency = 'USD' }) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(cost);
};

export const formatNumber = (amount = 0) => {
  return new Intl.NumberFormat('en-US').format(amount);
};

export const formatDate = ({ date, format = 'fff' }) => {
  return DateTime.fromISO(date).toFormat(format);
};
export default {
  formatNumber,
  formatBudget,
  formatDate,
};
