import Storage from './storage';

const config = {
  authProps: ['token', 'user'],
  pageUrls: {
    login: '',
  },
};
class API_INSTANCE {
  constructor({ timeout = 100000, url } = {}) {
    this.timeout = timeout;
    this.url = url;
  }

  create(instance) {
    const API = instance;

    API.defaults.baseURL = this.url;
    API.defaults.timeout = this.timeout;
    API.defaults.authorization = Storage.get(config.authProps[0]);

    if (window.location.pathname !== config.pageUrls.login || window.location.pathname !== '/')
      API.defaults.headers.common['Authorization'] = `Bearer ${Storage.get(config.authProps[0])}`;

    const handleSessionExpired = (error) => {
      // const isNotAuth = ['authorization', 'auth', 'authorized', 'access forbidden'].find((msg) =>
      //   error?.response?.data?.error?.toLowerCase().includes(msg),
      // );
      // const cb = () => setTimeout((_) => (window.location.href = config.pageUrls.login), 2000);
      //     message = 'Session expired, please login again.';

      if ([401].includes(error?.response?.status)) {
        Storage.remove(config.authProps[0]);
        Storage.remove(config.authProps[1]);
        window.location.replace('/login')
      } else {
        return Promise.reject(error?.response?.data);
      }
    };

    const handleSuccess = (response) => {
      return response;
    };

    API.interceptors.response.use(handleSuccess, handleSessionExpired);

    API.interceptors.request.use(
      (configs) => {
        // const cb = () => setTimeout((_) => (window.location.href = config.pageUrls.login), 2000);
        //     message = 'You are not authorized, please login again';
        if (
          Storage.get(config.authProps[0]) === null &&
          window.location.pathname !== config.pageUrls.login
        ) {
          // console.log("User not authorized, please login again ")
          //     Alert({
          //      type: 'error',
          // //      message,
          // //      cb
          //     });
        }

        return configs;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // API.interceptors.err.use(handleSuccess, handleSessionExpired);

    return API;
  }
}

export default API_INSTANCE;
