import React, { useContext } from "react";
import "../Orgmodal/OrgDrop.css"
import { CampaignContext } from '../../../state';

const OrgDrop = () => {
      
      const { orgmodal, setOrgmodal } = useContext(CampaignContext)

      return <div onClick={()=>setOrgmodal(false)} className={orgmodal ? `backdropS  backdrop__activeS` : "backdropS"}></div>;
};

export default OrgDrop;