import React, { useEffect, useContext } from 'react';
import api from '../../Api/Indx';
import DeleteIcon from '@mui/icons-material/Delete';
import NodeID from './NodeID';
import CostFilter from './CostFilter';
import ScreenDimensionsFilter from './ScreenDimensionsFilter';
// import IncomeFilter from './IncomeFilter';

// TABS
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

// CheckBox
import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { CampaignContext } from '../../../state';

function ThirdStep() {
  const {
    step,
    setStep,
    setActiveTab,
    activeTab,
    display,
    setDisplay,
    _setSelectedVenues,
    setPublishers,
    selectedVenues,
    setSelectedVenues,
  } = useContext(CampaignContext);

  // const [selectedVenues, setSelectedVenues] = useState([]);

  const getPublishers = async () => {
    const _res = await api.getPublishers();
    setPublishers([..._res.payload]);
  };

  useEffect(() => {
    getPublishers();
  }, []);

  const [errorMessage, setErrorMessage] = React.useState(false);

  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setActiveTab(newValue);
  };

  const handleNextChange = () => {
    if (selectedVenues.length === 0) {
      setErrorMessage(true);
    } else {
      setStep(step + 1);
      localStorage.setItem('step', step + 1);
    }
  };

  const handlePreviousChange = () => {
    setStep(step - 1);
    localStorage.setItem('step', step - 1);
  };

  const handleVenueRemove = (adUnit) => {
    const newVenues = [];

    for (let i = 0; i < selectedVenues.length; i++) {
      if (selectedVenues[i].id !== adUnit.id) {
        newVenues.push(selectedVenues[i]);
      }
    }

    setSelectedVenues(newVenues);
    _setSelectedVenues(newVenues);
  };

  const Streetnav = (lat, lng) => {
    window.open('/billboard-streetview', '_blank');
    // window.location = "/Panview"
    localStorage.setItem('streetviewlat', lat);
    localStorage.setItem('streetviewlng', lng);
  };

  return (
    <div className='tabbbii'>
      <TabContext value={activeTab} sx={{ color: '#5E16EB' }}>
        <div>
          <TabList
            onChange={handleTabChange}
            aria-label='lab API tabs example'
            variant='scrollable'
            scrollButtons='auto'
          >
            <Tab
              label='Categories'
              value='1'
              sx={{ color: '#5E16EB', fontFamily: 'ProductSans', fontWeight: 600 }}
            />
            <Tab
              label='Cost Per 1000 impressions (CPM)'
              value='2'
              sx={{ color: '#5E16EB', fontFamily: 'ProductSans', fontWeight: 600 }}
            />
            {/*<Tab*/}
            {/*  label='Income'*/}
            {/*  value='3'*/}
            {/*  sx={{ color: '#5E16EB', fontFamily: 'ProductSans', fontWeight: 600 }}*/}
            {/*/>*/}
            <Tab
              label='Screen Dimensions'
              value='4'
              sx={{ color: '#5E16EB', fontFamily: 'ProductSans', fontWeight: 600 }}
            />
            <Tab
              label='Selected Screens'
              value='5'
              sx={{ color: '#5E16EB', fontFamily: 'ProductSans', fontWeight: 600 }}
            />
          </TabList>
        </div>

        <div>
          <TabPanel value='1'>
            <div>
              <p style={{ fontSize: '13px', fontFamily: 'ProductSans', fontWeight: 400 }}>
                What type of screen do you want to target?
              </p>
            </div>

            <div className='showdrp1'>
              <NodeID />
            </div>
          </TabPanel>
        </div>

        <div>
          <TabPanel value='2'>
            <div>
              <p style={{ fontFamily: 'ProductSans' }} className={'mb-2 text-base font-medium'}>
                Screens on the map will filter based on their listed Cost Per 1000 impressions (CPM)
                number.
              </p>
            </div>

            <div className='showdrp1'>
              <CostFilter />
            </div>
          </TabPanel>
        </div>

        {/*<div>*/}
        {/*  <TabPanel value='3'>*/}
        {/*    <div>*/}
        {/*      <p style={{ fontFamily: 'ProductSans' }} className={'mb-2 text-base font-medium'}>*/}
        {/*        Screens on the map will filter based on their income.*/}
        {/*      </p>*/}
        {/*    </div>*/}

        {/*    <div className='showdrp1'>*/}
        {/*      <IncomeFilter />*/}
        {/*    </div>*/}
        {/*  </TabPanel>*/}
        {/*</div>*/}

        <div>
          <TabPanel value='4'>
            <div>
              <p style={{ fontFamily: 'ProductSans' }} className={'mb-2 text-base font-medium'}>
                Screens on the map will filter based on selected dimensions
              </p>
            </div>

            <div className='showdrp1'>
              <ScreenDimensionsFilter />
            </div>
          </TabPanel>
        </div>

        <div>
          <TabPanel value='5'>
            <div className='screenscroll'>
              {selectedVenues.length === 0 ? (
                <div>
                  <p style={{ fontSize: '12px' }}>Select a screen from the map on the right</p>{' '}
                </div>
              ) : (
                <div className='bordscrn'>
                  {selectedVenues.map((item) => (
                    <div className='screeninfos' key={item.id}>
                      <div className='scrnTab'>
                        {/* Screen Image */}
                        <div className='SdetColImage'>
                          <img
                            className='asstimg'
                            src={item.asset.imageUrl || '/placeholder.svg'}
                            alt={'screen image'}
                          />
                        </div>

                        {/* Screen Details */}

                        <div className='SdetCol'>
                          <div className='sdet'>
                            <p
                              onClick={() =>
                                Streetnav(item.location?.latitude, item.location?.longitude)
                              }
                            >
                              Address: <span className='impspan'>Street View</span>
                            </p>
                          </div>

                          <div className='sdet'>
                            <p>
                              Category: <span className='impspan'> {item.asset.category}</span>
                            </p>
                          </div>

                          <div className='sdet'>
                            <p>
                              Base Rate:{' '}
                              <span className='impspan'>
                                {' '}
                                {item.planning?.baseRate}
                                {item.planning?.currency}
                              </span>
                            </p>
                          </div>

                          <div className='sdet'>
                            <p>
                              Diagonal length (ft):{' '}
                              <span className='impspan'>
                                {' '}
                                {Number(Math.ceil(`${item.asset?.size / 12}`))} ft
                              </span>
                            </p>
                          </div>

                          <div className='sdet'>
                            <p>
                              Weekly reach:{' '}
                              <span className='impspan'>
                                {' '}
                                {Number(Math.ceil(`${item.measurement?.fourWeekImpression / 4}`))}
                              </span>
                            </p>
                          </div>

                          <div className='sdet'>
                            <p>
                              publisher: <span className='impspan'> {item.publisher}</span>
                            </p>
                          </div>

                          <div className='sdet'>
                            <p>
                              Resolution:{' '}
                              <span className='impspan'>
                                {' '}
                                {item.slot.width}w x {item.slot.height}h
                              </span>
                            </p>
                          </div>

                          <div className='sdet'>
                            <p>
                              Ad play length (s):{' '}
                              <span className='impspan'> {item.measurement?.duration} </span>
                            </p>
                          </div>
                        </div>

                        <div className='closebutton'>
                          <DeleteIcon
                            size={5}
                            color='#5E16EB'
                            onClick={() => handleVenueRemove(item)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </TabPanel>
        </div>
      </TabContext>

      <div className='backnext'>
        <button onClick={() => handlePreviousChange()} className='crtcmpg2'>
          BACK
        </button>
        <button onClick={() => handleNextChange()} className='crtcmpg'>
          NEXT
        </button>

        <button onClick={() => setDisplay(!display)} className='FButton'>
          SHOW MAP
        </button>
      </div>
      {errorMessage ? (
        <div style={{ color: 'red', fontSize: '10px', fontWeight: 400 }}>
          Please select at least one screen to proceed to the next step
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default ThirdStep;
