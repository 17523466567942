import React, { useState, useContext, useEffect } from 'react';
import api from '../../Api/Indx';
import Slider from '@mui/material/Slider';
import { DateTime } from 'luxon';
import { formatBudget, formatNumber } from '../../../utils/plugins';
// Form
import FormControlLabel from '@mui/material/FormControlLabel';

// Radio button and checkbox
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

// CHECKBOX
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import { CampaignContext } from '../../../state';
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';

function FourthStep() {
  // Radio button
  const [valuerad, setValueRad] = React.useState();
  const { campaignId } = useParams();

  const [isValidStartFrom, setIsValidStartFrom] = useState(null);
  const [isValidStartDate, setIsValidStartDate] = useState(null);
  const [isValidEndDate, setIsValidEndDate] = useState(null);
  const handleRadChange = (event) => {
    setValueRad(event.target.valuerad);
  };
  const dayPartings = [
    { label: 'Morning (5am to 11am)', value: 'morning' },
    { label: 'Afternoon (12pm to 5pm)', value: 'afternoon' },
    { label: 'Evening (5pm to 11pm)', value: 'evening' },
    { label: 'Overnight (11pm to 5am)', value: 'overnight' },
  ];
  console.log(dayPartings);
  const {
    step,
    setStep,
    setImpressions2,
    impressions2,
    show,
    setShow,
    startDateRad1,
    setStartDateRad1,
    startTimeRad1,
    setStartTimeRad1,
    budgetRad1,
    setBudgetsRad1,
    startTimeRad2,
    setStartTimeRad2,
    endTimeRad2,
    setEndTimeRad2,
    startDateRad2,
    setStartDateRad2,
    endDateRad2,
    setEndDateRad2,
    mamount,
    _selectedVenues,
    estimate,
    setEstimate,
    cost2,
    setCost2,
    personName,
    setImpperdollar,
    selectedTimezone,
    setSelectedTimezone,
    selectedCampaign,
    selectedParting,
    setSelectedParting,
  } = useContext(CampaignContext);

  const [errorMessage, setErrorMessage] = React.useState(false);
  const [sxMessage, setSxMessage] = React.useState(false);
  const [sxMessage2, setSxMessage2] = React.useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [errMsg2, setErrMsg2] = useState('');

  const handleNextChange = () => {
    // budgetRad1

    if (show.styleA && budgetRad1 == 0) {
      setErrorMessage(true);
      return;
    }

    if (show.styleA && startDateRad1 == 0) {
      setErrorMessage(true);
      return;
    }

    if (show.styleA && startTimeRad1 == 0) {
      setErrorMessage(true);
      return;
    }

    setStep(step + 1);
    localStorage.setItem('step', step + 1);
  };

  const handlePreviousChange = () => {
    setStep(step - 1);
    localStorage.setItem('step', step - 1);
  };

  const screenStepper = () => {
    setStep(step - 2);
    localStorage.setItem('step', step - 2);
  };

  // This line of code fetches estimate for campaigns with StateDate only!
  const getEstimate1 = async () => {
    if (isValidStartFrom === false) {
      return;
    }

    if (budgetRad1 == 0 || startDateRad1 == 0 || startTimeRad1 == 0) {
      setErrMsg('Ensure all fields are filled to fetch update');
      return;
    }

    try {
      const targets = [];
      for (let i = 0; i < _selectedVenues.length; i++) {
        targets.push(_selectedVenues[i].unitId);
      }

      const publishers = personName;

      let data = {};

      data = {
        publishers,
        targets,
        budget: Number(budgetRad1),
        start_date: `${startDateRad1}T${startTimeRad1}${selectedTimezone}`,
        end_date: '',
      };

      const res = await api.getExtimate(data);
      // Destructuring
      // setEstimate(res?.payload?.estimate);
      let {
        payload: {
          estimate: { impressionPerDollar },
        },
      } = res;

      if (res?.msg === 'OK') {
        setSxMessage(true);
      }

      setImpperdollar(Number(Math.ceil(impressionPerDollar)));

      let _impressions2 = 0;

      _impressions2 += Number(impressionPerDollar) * Number(Math.ceil(budgetRad1));

      setImpressions2(Math.ceil(_impressions2));
    } catch (err) {
      console.log(err);
    }
  };

  // This line of code fetches estimate for campaigns with endDate and StateDate

  const getEstimate = async () => {
    if (isValidStartDate === false || isValidEndDate === false) {
      return;
    }

    if (startDateRad2 == 0 || endDateRad2 == 0 || startTimeRad2 == 0 || endTimeRad2 == 0) {
      setErrMsg2('Ensure all fields are filled to fetch update');
      return;
    }

    try {
      const targets = [];
      for (let i = 0; i < _selectedVenues.length; i++) {
        targets.push(_selectedVenues[i].unitId);
      }

      const publishers = personName;

      let data = {};
      data = {
        publishers,
        targets,
        start_date: `${startDateRad2}T${startTimeRad2}${selectedTimezone}`,
        end_date: `${endDateRad2}T${endTimeRad2}${selectedTimezone}`,
      };

      const res = await api.getExtimate(data);
      // Destructuring
      // setEstimate(res?.payload?.estimate);
      let {
        payload: {
          estimate: { cost, impressions },
        },
      } = res;

      if (res?.msg === 'OK') {
        setSxMessage2(true);
        setEstimate(Math.ceil(impressions));
        setCost2(Math.ceil(cost));
      }
    } catch (err) {
      console.log(err);
    }
  };

  function handleToggle(e) {
    const { name, tagName } = e.target;

    try {
      if (name === 'styleA' && !show.styleA && tagName === 'INPUT') {
        setShow((state) => ({ ...state, [name]: true, styleB: false }));
      } else if (name === 'styleB' && !show.styleB && tagName === 'INPUT') {
        setShow((state) => ({ ...state, styleA: false, [name]: true }));
      }
    } catch (msg) {
      console.error("Tag doesn't have name");
    }
  }

  const validateStartFrom = () => {
    if (startDateRad1 && startTimeRad1 && selectedTimezone) {
      const currentTime = DateTime.now();
      const dateTimeWithZone = DateTime.fromISO(
        `${startDateRad1}T${startTimeRad1}${selectedTimezone}`,
      );
      const { hours } = dateTimeWithZone.diff(currentTime, ['hours']).toObject();
      const isValid = Math.ceil(hours) >= 12;
      setIsValidStartFrom(isValid);
    }
  };

  const validateStartDate = () => {
    if (startDateRad2 && startTimeRad2 && selectedTimezone) {
      const currentTime = DateTime.now();
      const dateTimeWithZone = DateTime.fromISO(
        `${startDateRad2}T${startTimeRad2}${selectedTimezone}`,
      );
      const { hours } = dateTimeWithZone.diff(currentTime, ['hours']).toObject();
      const isValid = Math.ceil(hours) >= 12;
      setIsValidStartDate(isValid);
    }
  };

  const validateEndDate = () => {
    if (endDateRad2 && endTimeRad2 && selectedTimezone) {
      const dateTimeWithZone = DateTime.fromISO(
        `${startDateRad2}T${startTimeRad2}${selectedTimezone}`,
      );
      const endDateTimeWithZone = DateTime.fromISO(
        `${endDateRad2}T${endTimeRad2}${selectedTimezone}`,
      );
      const isValid = endDateTimeWithZone > dateTimeWithZone;
      setIsValidEndDate(isValid);
    }
  };

  const setCampaignDates = async (response) => {
    const [startDate, startTime] = response.startDate.split('T');
    const startTimes = startTime.split('+');
    if (response.endDate) {
      const [endDate, endTime] = response.endDate.split('T');
      const endTimes = endTime.split('+');
      handleToggle({ target: { name: 'styleB', tagName: 'INPUT' } });
      setStartDateRad2(startDate);
      setStartTimeRad2(startTimes[0]);
      setEndDateRad2(endDate);
      setEndTimeRad2(endTimes[0]);
      setCost2(response.billing.budget);
      await getEstimate();
    } else {
      handleToggle({ target: { name: 'styleA', tagName: 'INPUT' } });
      setStartDateRad1(startDate);
      setStartTimeRad1(startTimes[0]);
      setBudgetsRad1(response.billing.budget);
      await getEstimate1();
    }
  };

  const handleParting = (e) => {
    e.preventDefault();
    const { target } = e;
    const copy = { ...selectedParting };
    if (selectedParting[target.value]) {
      delete copy[target.value];
    } else {
      copy[target.value] = true;
    }
    setSelectedParting({ ...copy });
  };

  useEffect(() => {
    if (campaignId && selectedCampaign) {
      setCampaignDates(selectedCampaign);
    }
  }, [campaignId, selectedCampaign]);

  return (
    <div>
      <div className='datepickercard'>
        <RadioGroup value={valuerad} onChange={handleRadChange}>
          <div>
            <FormControlLabel
              value='I want to run my campaign until my budget $ are used up'
              control={<Radio />}
              name='styleA'
              checked={show.styleA}
              label={
                <span className='ShowstyleARadio'>
                  I have a specific budget in mind for my campaign
                </span>
              }
              sx={{
                background: 'rgba(238, 238, 238, 0.2)',
                borderRadius: 3,
                padding: 0,
                boxShadow: '0 0 0px',
                width: '100%',
              }}
              onClick={handleToggle}
            />
            {show.styleA && (
              <div className='ShowstyleA'>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className='Sliderr'>
                    <p className='putslider'>Please use the slider below to select your budget</p>
                    <Slider
                      size='medium'
                      // defaultValue={mamount}
                      max={10000}
                      min={mamount}
                      aria-label='Small'
                      valueLabelDisplay='auto'
                      value={budgetRad1}
                      sx={{ color: '#5E16EB' }}
                      onChange={(e) => setBudgetsRad1(e.target.value)}
                    />

                    <option
                      className='budgetRad1'
                      label={`Your selected budget is: ${formatBudget({ cost: budgetRad1 })}`}
                    ></option>
                  </div>

                  <div></div>
                </div>

                <div className='startEndDate'>
                  <p>Please select when you&apos;d like your campaign to start: </p>

                  <div className='startEndDateOrientation'>
                    <input
                      type='date'
                      required
                      id='appt'
                      name='appt'
                      value={startDateRad1}
                      onChange={(e) => setStartDateRad1(e.target.value)}
                      onBlur={validateStartFrom}
                    />

                    <input
                      type='time'
                      id='time'
                      step='1'
                      required
                      name='appt2'
                      value={startTimeRad1}
                      onChange={(e) => setStartTimeRad1(e.target.value)}
                      onBlur={validateStartFrom}
                    />
                  </div>
                  {isValidStartFrom === false && (
                    <small className={'text-[#F44336] text-xs'}>
                      Start date must be 12 hours from current time
                    </small>
                  )}
                  <p>Campaign start time zone: </p>

                  {/* <TimezoneSelect
										value={selectedTimezone}
										onChange={(e) => setSelectedTimezone(e.target.value)}
										className="TimezoneSelect"
										placeholder="Time Zone"
									/> */}

                  <select
                    name='timezone'
                    id='timezone'
                    className='TimezoneSelect'
                    value={selectedTimezone}
                    // defaultValue={selectedTimezone}
                    onBlur={validateStartFrom}
                    onChange={(e) => setSelectedTimezone(e.target.value)}
                  >
                    <option value='-05:00'>(UTC-05:00) Eastern Time (US &amp; Canada)</option>
                    <option value='-12:00'>(UTC-12:00) International Date Line West</option>
                    <option value='-11:00'>(UTC-11:00) Coordinated Universal Time-11</option>
                    <option value='-10:00'>(UTC-10:00) Hawaii</option>
                    <option value='-09:00'>(UTC-09:00) Alaska</option>
                    <option value='-08:00'>(UTC-08:00) Baja California</option>
                    <option value='-07:00'>(UTC-07:00) Pacific Time (US &amp; Canada)</option>
                    <option value='-08:00'>(UTC-08:00) Pacific Time (US &amp; Canada)</option>
                    <option value='-07:00'>(UTC-07:00) Arizona</option>
                    <option value='-07:00'>(UTC-07:00) Chihuahua, La Paz, Mazatlan</option>
                    <option value='-07:00'>(UTC-07:00) Mountain Time (US &amp; Canada)</option>
                    <option value='-06:00'>(UTC-06:00) Central America</option>
                    <option value='-06:00'>(UTC-06:00) Central Time (US &amp; Canada)</option>
                    <option value='-06:00'>(UTC-06:00) Guadalajara, Mexico City, Monterrey</option>
                    <option value='-06:00'>(UTC-06:00) Saskatchewan</option>
                    <option value='-05:00'>(UTC-05:00) Bogota, Lima, Quito</option>
                    <option value='-05:00'>(UTC-05:00) Indiana (East)</option>
                    <option value='-04:30'>(UTC-04:30) Caracas</option>
                    <option value='-04:00'>(UTC-04:00) Asuncion</option>
                    <option value='-04:00'>(UTC-04:00) Atlantic Time (Canada)</option>
                    <option value='-04:00'>(UTC-04:00) Cuiaba</option>
                    <option value='-04:00'>(UTC-04:00) Georgetown, La Paz, Manaus, San Juan</option>
                    <option value='-04:00'>(UTC-04:00) Santiago</option>
                    <option value='-03:30'>(UTC-03:30) Newfoundland</option>
                    <option value='-03:30'>(UTC-03:00) Brasilia</option>
                    <option value='-03:30'>(UTC-03:00) Buenos Aires</option>
                    <option value='-03:30'>(UTC-03:00) Cayenne, Fortaleza</option>
                    <option value='-03:30'>(UTC-03:00) Greenland</option>
                    <option value='-03:30'>(UTC-03:00) Montevideo</option>
                    <option value='-03:30'>(UTC-03:00) Salvador</option>
                    <option value='-02:00'>(UTC-02:00) Coordinated Universal Time-02</option>
                    <option value='-02:00'>(UTC-02:00) Mid-Atlantic - Old</option>
                  </select>

                  <div className='day-parting'>
                    <p>Choose which times you’d like your campaign to run</p>
                    <div className={'flex flex-col gap-2'}>
                      {dayPartings.map((dayParting) => (
                        <FormControlLabel
                          key={dayParting.value}
                          label={
                            <span
                              style={{ fontWeight: 400, fontSize: 13 }}
                              className={'capitalize'}
                            >
                              {dayParting.label}
                            </span>
                          }
                          control={
                            <Checkbox
                              name={'dayparting'}
                              checked={!!selectedParting[dayParting.value]}
                              value={dayParting.value}
                              style={{ color: '#5E16EB' }}
                              sx={{ '& .MuiSvgIcon-root': { fontSize: 14, color: '#5E16EB' } }}
                              onChange={handleParting}
                            />
                          }
                        />
                      ))}
                    </div>
                  </div>
                  <div className=''></div>

                  <div
                    className='divUR'
                    style={{ display: 'flex', flexDirection: 'column', fontWeight: 400 }}
                  >
                    <p className='ImPeople'>
                      {' '}
                      Based on your selected screens & budget, we expect your campaign to reach:{' '}
                      <span style={{ color: '#000', fontWeight: 400, fontFamily: 'ProductSans' }}>
                        <b>{formatNumber(impressions2) || '......'} people</b>
                      </span>
                    </p>

                    <div className='updateReachA'>
                      <button className='asm' onClick={getEstimate1}>
                        Update reach
                      </button>

                      <button onClick={() => screenStepper()} className='asm'>
                        Add more screens{' '}
                      </button>
                    </div>
                    {sxMessage ? (
                      <div
                        style={{
                          color: '#00FF00',
                          fontSize: '12px',
                          fontWeight: 400,
                          fontFamily: 'ProductSans',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        Reach updated{' '}
                        <CheckIcon fontSize='small' sx={{ mr: 1, color: '#00FF00' }} />
                      </div>
                    ) : (
                      (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '12px',
                            fontWeight: 400,
                            fontFamily: 'ProductSans',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {errMsg}
                        </div>
                      ) || ''
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div style={{ marginTop: 5 }}>
            <FormControlLabel
              value='I want to run my campaign with a specific start and end date/time'
              control={<Radio />}
              name='styleB'
              checked={show.styleB}
              label={
                <span className='ShowstyleARadio'>
                  I want to run my campaign with a specific start and end date/time
                </span>
              }
              sx={{
                background: 'rgba(238, 238, 238, 0.2)',
                borderRadius: 3,
                padding: 1,
                boxShadow: '0 0 0px',
                width: '100%',
                display: 'flex',
              }}
              onClick={handleToggle}
            />

            {show.styleB && (
              <div className='ShowstyleA'>
                <div className='startEndDate'>
                  <p className='radiolabel'>Start date/time:</p>

                  <div className='startEndDateOrientation'>
                    <input
                      type='date'
                      required
                      id='appt'
                      name='appt'
                      value={startDateRad2}
                      onChange={(e) => setStartDateRad2(e.target.value)}
                      onBlur={validateStartDate}
                    />

                    <input
                      type='time'
                      id='time'
                      step='1'
                      required
                      name='appt2'
                      value={startTimeRad2}
                      onChange={(e) => setStartTimeRad2(e.target.value)}
                      onBlur={validateStartDate}
                    />
                  </div>
                  {isValidStartDate === false && (
                    <small className={'text-[#F44336] text-xs'}>
                      Start date must be 12 hours from current time
                    </small>
                  )}

                  <p className='radiolabel'>End date/time</p>

                  <div className='startEndDateOrientation'>
                    <input
                      type='date'
                      required
                      id='appt'
                      name='appt'
                      value={endDateRad2}
                      onChange={(e) => setEndDateRad2(e.target.value)}
                      onBlur={validateEndDate}
                    />

                    <input
                      type='time'
                      id='time'
                      step='1'
                      required
                      name='appt2'
                      value={endTimeRad2}
                      placeholder='end time'
                      onBlur={validateEndDate}
                      onChange={(e) => setEndTimeRad2(e.target.value)}
                    />
                  </div>
                  {isValidEndDate === false && (
                    <small className={'text-[#F44336] text-xs'}>
                      End date must be after start date
                    </small>
                  )}

                  <div>
                    <p className='radiolabel'>Campaign start time zone</p>
                    {/* <TimezoneSelect
											value={selectedTimezone}
											onChange={setSelectedTimezone}
											className="TimezoneSelect"
											placeholder="Time Zone"
										/> */}

                    <select
                      name='timezone'
                      id='timezone'
                      className='TimezoneSelect'
                      // value={selectedTimezone}
                      defaultValue={selectedTimezone}
                      onBlur={validateStartDate}
                      onChange={(e) => setSelectedTimezone(e.target.value)}
                    >
                      <option value='-05:00'>(UTC-05:00) Eastern Time (US &amp; Canada)</option>
                      <option value='-12:00'>(UTC-12:00) International Date Line West</option>
                      <option value='-11:00'>(UTC-11:00) Coordinated Universal Time-11</option>
                      <option value='-10:00'>(UTC-10:00) Hawaii</option>
                      <option value='-09:00'>(UTC-09:00) Alaska</option>
                      <option value='-08:00'>(UTC-08:00) Baja California</option>
                      <option value='-07:00'>(UTC-07:00) Pacific Time (US &amp; Canada)</option>
                      <option value='-08:00'>(UTC-08:00) Pacific Time (US &amp; Canada)</option>
                      <option value='-07:00'>(UTC-07:00) Arizona</option>
                      <option value='-07:00'>(UTC-07:00) Chihuahua, La Paz, Mazatlan</option>
                      <option value='-07:00'>(UTC-07:00) Mountain Time (US &amp; Canada)</option>
                      <option value='-06:00'>(UTC-06:00) Central America</option>
                      <option value='-06:00'>(UTC-06:00) Central Time (US &amp; Canada)</option>
                      <option value='-06:00'>
                        (UTC-06:00) Guadalajara, Mexico City, Monterrey
                      </option>
                      <option value='-06:00'>(UTC-06:00) Saskatchewan</option>
                      <option value='-05:00'>(UTC-05:00) Bogota, Lima, Quito</option>
                      <option value='-05:00'>(UTC-05:00) Indiana (East)</option>
                      <option value='-04:30'>(UTC-04:30) Caracas</option>
                      <option value='-04:00'>(UTC-04:00) Asuncion</option>
                      <option value='-04:00'>(UTC-04:00) Atlantic Time (Canada)</option>
                      <option value='-04:00'>(UTC-04:00) Cuiaba</option>
                      <option value='-04:00'>
                        (UTC-04:00) Georgetown, La Paz, Manaus, San Juan
                      </option>
                      <option value='-04:00'>(UTC-04:00) Santiago</option>
                      <option value='-03:30'>(UTC-03:30) Newfoundland</option>
                      <option value='-03:30'>(UTC-03:00) Brasilia</option>
                      <option value='-03:30'>(UTC-03:00) Buenos Aires</option>
                      <option value='-03:30'>(UTC-03:00) Cayenne, Fortaleza</option>
                      <option value='-03:30'>(UTC-03:00) Greenland</option>
                      <option value='-03:30'>(UTC-03:00) Montevideo</option>
                      <option value='-03:30'>(UTC-03:00) Salvador</option>
                      <option value='-02:00'>(UTC-02:00) Coordinated Universal Time-02</option>
                      <option value='-02:00'>(UTC-02:00) Mid-Atlantic - Old</option>
                    </select>

                    <p className='' style={{ textAlign: 'center' }}>
                      Based on your selected screens & duration, your campaign will reach
                      approximately{' '}
                      <span style={{ color: '#000', fontWeight: 400, fontFamily: 'ProductSans' }}>
                        {formatNumber(estimate) || '.....'} people
                      </span>
                    </p>

                    <div className='impressioncount'>
                      <p
                        style={{
                          display: 'flex',
                          alignSelf: 'center',
                          marginBottom: '20px',
                          color: '#000',
                        }}
                      >
                        Your campaign will cost{' '}
                        <b className={'ml-1'}>{` ${formatBudget({ cost: cost2 })}`}</b>
                      </p>
                      <div
                        className=''
                        style={{ display: 'flex', justifyContent: 'space-between' }}
                      >
                        <button className='asm' onClick={getEstimate}>
                          Update reach & cost
                        </button>
                        <button onClick={() => screenStepper()} className='asm'>
                          Add more screens{' '}
                        </button>
                      </div>
                      {sxMessage2 ? (
                        <div
                          style={{
                            color: '#00FF00',
                            fontSize: '12px',
                            fontWeight: 400,
                            fontFamily: 'ProductSans',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          Reach updated{' '}
                          <CheckIcon fontSize='small' sx={{ mr: 1, color: '#00FF00' }} />
                        </div>
                      ) : (
                        (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '12px',
                              fontWeight: 400,
                              fontFamily: 'ProductSans',
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            {errMsg2}
                          </div>
                        ) || ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </RadioGroup>
      </div>

      <div className='backnext'>
        <button onClick={() => handlePreviousChange()} className='crtcmpg2'>
          BACK
        </button>
        <button onClick={() => handleNextChange()} className='crtcmpg'>
          NEXT
        </button>
      </div>
      {errorMessage ? (
        <div
          style={{
            color: 'red',
            fontSize: '12px',
            fontWeight: 400,
            fontFamily: 'ProductSans',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          Please fill all details
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default FourthStep;
