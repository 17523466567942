import axios from 'axios'
import Alert from '../../utils/alert';
import BACKEND from '../../utils/backend'

const API_BASE = process.env.REACT_APP_API_URL
const API_URL = `${API_BASE}/accounts/signup`;

// const GOOGLE_LOGIN = `${API_BASE}/accounts/login/social/google`;

const API = new BACKEND()
// const Fetch = (to, useAlert = false) => API.send({ to, useAlert })


// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}

// Login user
const login = async (userData) => {

try{
  // const response = await axios.post(API_URL2, userData)
  const response = await API.send({type: 'post', to: '/accounts/login', payload: userData, useAlert: true})
  // console.log(response);
  Alert({type: "success", message: "Log in successfully"})
  if (response.payload) {
    localStorage.setItem('user', JSON.stringify(response.payload.account))
    localStorage.setItem('token',response.payload.token)
  }
  return response
}catch(err) {
  return err
}

  // return response.data
}


const logingoogle = async (userData) => {

  try{
    // const response = await axios.post(API_URL2, userData)
    const response = await API.send({type: 'post', to: '/accounts/login/social/google', payload: userData, useAlert: true})
    console.log(response, 'logingoogle');
    Alert({type: "success", message: "Log in successfully"})
    if (response.payload) {
      localStorage.setItem('user', JSON.stringify(response.payload.account))
      localStorage.setItem('token',response.payload.token)
    }
    return response
  }catch(err) {
    return err
  }

    // return response.data
  }





// Logout user
const logout = () => {
  localStorage.removeItem('user')
}

const authService = {
  register,
  logout,
  login,
  logingoogle,

}

export default authService
