import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import api from '../Components/Api/Indx';
import './Comfirmpassword.css';
import LazyLoad from 'react-lazy-load';
import { Input } from 'antd';

function Confirmpassword() {
  const userRef = useRef();
  const [email, setEmail] = useState('');
  const [pwd, setPwd] = useState('');
  const [otp, setOtp] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let data = {};

      data = {
        email: email,
        code: otp,
        new_password: pwd,
      };

      await api.confirmReset(data);
      // console.log(res?.data);
      window.location = '/login';
      setEmail('');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LazyLoad>
      <div className='RootContainer'>
        <div className='MainContainer'>
          <div className='form-container'>
            <div className='cmformLeft'>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src='diyo.svg' className='LogoV' />
                <p
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 400,
                    fontFamily: 'ProductSans',
                    marginBottom: '30px',
                  }}
                >
                  Reset Password
                </p>
              </div>

              <form className='regform' onSubmit={handleSubmit}>
                <div>
                  <Input
                    type='email'
                    className='fieldii'
                    placeholder='Enter your email'
                    id='username'
                    ref={userRef}
                    autoComplete='off'
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </div>

                <div>
                  <Input
                    type='text'
                    className='fieldii'
                    placeholder='Enter reset code'
                    id='otp'
                    ref={userRef}
                    autoComplete='off'
                    onChange={(e) => setOtp(e.target.value)}
                    value={otp}
                    required
                  />
                </div>

                <div>
                  <Input.Password
                    type='passwprd'
                    className='fieldii'
                    placeholder='Enter new password'
                    id='username'
                    ref={userRef}
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                  />
                </div>

                <button type='submit' className='submitButtonii'>
                  Reset Password
                </button>
              </form>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <p style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'ProductSans' }}>
                  <NavLink to='/forgot-password' style={{ textDecoration: 'none' }}>
                    <a
                      style={{
                        textDecoration: 'none',
                        color: '#5E16EB',
                        fontWeight: 400,
                        fontFamily: 'ProductSans',
                      }}
                    >
                      Comfirm Email
                    </a>
                  </NavLink>
                </p>
              </div>
            </div>

            <div className='formRight'>
              <img src={'sgg.svg'} />
            </div>
          </div>
        </div>
      </div>
    </LazyLoad>
  );
}

export default Confirmpassword;
