import { Upload } from 'antd';
import Button from '@material-ui/core/Button';
import CheckIcon from '@mui/icons-material/Check';
import { Close } from '@mui/icons-material';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

export default function VideoUpload({
  selectedResolutions,
  setSelectedResolutions,
  selectedVenues,
  handleVideoUpload,
  loading,
}) {
  const [files, setFiles] = useState([]);
  const [sxMessage, setSxMessage] = useState(null);
  const [valMessage, setValMessage] = useState(null);
  const [uploading, setUploading] = useState(loading);
  const beforeVidUpload = async (file) => {
    setValMessage(null);
    setSxMessage(null);
    let isValid = false;
    if (file.size / (1024 * 1024) > 20) {
      setValMessage('Max video size is 20mb.');
      return Upload.LIST_IGNORE;
    }
    setFiles([...files, file]);
    try {
      setUploading(true);
      const url = URL.createObjectURL(file);
      const $video = document.createElement('video');
      $video.src = url;
      return new Promise(function () {
        $video.addEventListener('loadedmetadata', function () {
          const resolutions = { ...selectedResolutions };
          let requestData = {};
          Object.values(resolutions).forEach((resolution) => {
            let sHeight = resolution.height;
            let sWidth = resolution.width;
            let maxDuration = resolution.slot?.max_duration || 5;
            let minDuration = resolution.slot?.min_duration || 0;
            if (
              this.videoHeight === sHeight &&
              this.videoWidth === sWidth &&
              validateDuration({ duration: this.duration, minDuration, maxDuration })
            ) {
              setSxMessage(true);
              isValid = true;
              setSelectedResolutions({
                ...selectedResolutions,
                [`${this.videoWidth}x${this.videoHeight}`]: { ...resolution, valid: isValid },
              });
              requestData = {
                videoWidth: this.videoWidth,
                videoHeight: this.videoHeight,
                file,
                duration: this.duration,
              };
            }
          });
          setSxMessage(isValid);
          if (isValid) {
            handleVideoUpload(requestData);
          }
          return Upload.LIST_IGNORE;
        });
      });
    } catch (e) {
      setSxMessage(false);
      return Upload.LIST_IGNORE;
    } finally {
      setUploading(false);
    }
  };

  const validateDuration = ({ duration, maxDuration, minDuration }) => {
    return duration >= minDuration && duration <= maxDuration;
  };
  return (
    <div className='creativeorientation'>
      {selectedVenues.length === 0 ? (
        <div>
          <p style={{ fontSize: '12px' }}>Select a screen from the map on the right</p>{' '}
        </div>
      ) : (
        <div
          style={{
            height: 'fit-content',
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 15,
          }}
        >
          <div>
            <p style={{ fontFamily: 'ProductSans', fontWeight: 400, fontSize: 12 }}>
              You&apos;ve selected the following screens and their resolutions
            </p>
          </div>

          {Object.values(selectedResolutions).map((resolution, index) => (
            <div
              style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              key={`resolution-${index}`}
            >
              <div style={{ display: 'flex' }}></div>
              <p className={'flex items-baseline text-sm text-black font-normal gap-2'}>
                Video Resolution {index + 1}:
                <span className='impspan'>
                  {resolution.width} x {resolution.height} [Duration:{' '}
                  {resolution.slot?.min_duration}s minimum, {resolution.slot?.max_duration}s
                  maximum]
                </span>
                <span>
                  <FontAwesomeIcon
                    icon={resolution.valid ? faCircleCheck : faCircleXmark}
                    className={''}
                    style={{ color: resolution.valid ? '#5E16EB' : '#F44336' }}
                  />
                </span>
              </p>
            </div>
          ))}
        </div>
      )}
      <div className='addcreatives'>
        <Upload.Dragger
          multiple
          listType='picture'
          accept='.mp4'
          action={null}
          onRemove={(file) => {
            const index = files.indexOf(file);
            const newFileList = files.slice();
            newFileList.splice(index, 1);
            setFiles(newFileList);
          }}
          beforeUpload={(file) => {
            beforeVidUpload(file);
            return false;
          }}
        >
          Drag files here OR
          <br />
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <Button
              variant='contained'
              component='label'
              loading={uploading}
              style={{
                color: '#000',
                background: '#C9B6FA',
                fontSize: 10,
                fontWeight: 400,
              }}
            >
              UPLOAD CREATIVES
            </Button>
            {sxMessage && (
              <div
                style={{
                  color: '#00FF00',
                  fontSize: '10px',
                  fontWeight: 400,
                  fontFamily: 'ProductSans',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Image/Video dimensions valid{' '}
                <CheckIcon fontSize='small' sx={{ mr: 1, color: '#00FF00' }} />
              </div>
            )}
            {loading || uploading ? (
              <>
                <p className='text-[#f44336]'>Uploading...</p>
              </>
            ) : (
              ''
            )}
            {valMessage && (
              <div
                style={{
                  color: 'red',
                  fontSize: '10px',
                  fontWeight: 400,
                  fontFamily: 'ProductSans',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className={'pt-2'}
              >
                {valMessage}
                <Close fontSize='small' sx={{ mr: 1, color: 'red' }} />
              </div>
            )}
            {!sxMessage && sxMessage !== null && (
              <div
                style={{
                  color: 'red',
                  fontSize: '10px',
                  fontWeight: 400,
                  fontFamily: 'ProductSans',
                  display: 'flex',
                  alignItems: 'center',
                }}
                className={'pt-2'}
              >
                Please upload the correct video resolution and duration for your selected screen(s)
                <Close fontSize='small' sx={{ mr: 1, color: 'red' }} />
              </div>
            )}
          </div>
        </Upload.Dragger>
      </div>
    </div>
  );
}
