import React, { useState, useContext, useRef, useEffect } from 'react';

import 'mapbox-gl/dist/mapbox-gl.css';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout, reset } from '../../features/auth/authSlice';
import { Person } from '@mui/icons-material/';
// import useSupercluster from "use-supercluster";
import './Campaign.css';
import Map, { Marker, Popup, GeolocateControl } from 'react-map-gl';

// Material Design Dashoboard Menu
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import ExpandMore from '@mui/icons-material/ExpandMore';
import BACKEND from '../../utils/backend';
import { MdLocationPin } from 'react-icons/md';
import addUnitsApi from '../Api/Indx';

// Material Design Button
import Storage from '../../utils/storage';
import { CampaignContext } from '../../state';
import api from '../Api/Indx';

function Dashboard() {
  const { personName, setRefetchingMapData, screenvenuetypes, points, setPoints, setOrgmodal } =
    useContext(CampaignContext);

  const handlemodal = async () => {
    const res = await api.userProfile();
    let profile = res?.payload.profile;

    if (Object.keys(profile).length === 0) {
      console.log('Object is empty');
      setOrgmodal(true);
    } else {
      console.log('Object is not empty');
    }
  };

  useEffect(() => {
    handlemodal();
  }, []);

  const controller = new AbortController();
  const [fetchingLocationInfo, setFetchingLocationInfo] = useState(false);
  const [adUnit, setAdUint] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [loading, setLoading] = useState(false);

  const getMapInfo = async () => {
    setLoading(true);

    try {
      const _data = {
        filters: {
          publishers: personName,
          screen_types: screenvenuetypes,
        },
        min: {
          latitude: 8.293134936967334,
          longitude: -137.05575020807603,
        },
        max: {
          latitude: 57.75047620456547,
          longitude: -5.104046203339891,
        },
      };

      const data = await addUnitsApi.getAddUnits(_data);

      setPoints(data.payload?.Positions);

      setLoading(false);
    } catch (err) {
      setLoading(loading);
      console.log(err);
    }
  };

  useEffect(() => {
    getMapInfo();
  }, []);

  const [viewport, setViewport] = useState({
    longitude: -78.23618023833734,
    latitude: 43.75922923259958,
    zoom: 3.5,
  });

  const mapRef = useRef();
  // const bounds = mapRef.current ? mapRef.current.getMap().getBounds().toArray().flat() : null;

  // const { supercluster } = useSupercluster({
  //   points,
  //   zoom: viewport.zoom,
  //   bounds,
  //   options: {
  //     radius: 75,
  //     maxZoom: 25,
  //   },
  // });

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  // const handleExpansion = (cluster, latitude, longitude) => {
  //   const expansionZoom = Math.min(
  //     supercluster.getClusterExpansionZoom(cluster.id),
  //     20
  //   );

  //   setViewport({
  //     ...viewport,
  //     latitude,
  //     longitude,
  //     zoom: expansionZoom,
  //     transitionDuration: "auto",
  //   });

  //   handleZoom();
  // };

  const handleZoom = async () => {
    const s = mapRef.current ? mapRef.current.getMap().getBounds() : null;
    // const avialablePoints = [];
    setRefetchingMapData(true);
    try {
      const data = {
        filters: {
          publishers: personName,
          screen_types: screenvenuetypes,
        },

        min: {
          latitude: s._sw.lat,
          longitude: s._sw.lng,
        },
        max: {
          latitude: s._ne.lat,
          longitude: s._ne.lng,
        },
      };

      // console.log(data, "real lat and lng")

      const res = await new BACKEND().send({
        to: '/adunits/count',
        type: 'post',
        payload: data,
      });

      if (res.msg === 'no ad units found') {
        return;
      }

      setPoints(res?.payload?.Positions);

      setRefetchingMapData(false);
    } catch (err) {
      // console.log(err);
      setRefetchingMapData(false);
    }
  };

  function abortFetching() {
    // Abort.
    controller.abort();
  }

  // fetch location details on hover.
  const fetchLocationInfo = async (id) => {
    setFetchingLocationInfo(true);
    try {
      const _res = await api.getLocationInfo(id);
      setAdUint(_res.payload.adUnit);
      // console.log(_res, "setFetchingLocationInfo Dashboard");
      setFetchingLocationInfo(false);
    } catch (err) {
      console.log(err);
      setFetchingLocationInfo(false);
    }
  };

  useEffect(() => {
    handleZoom();
  }, [screenvenuetypes]);

  // const { loading = false } = props;

  const userinfo = JSON.parse(Storage.get('user'));
  //console.log(userinfo)

  // handles Dashboard Menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handles Logout
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(reset());
    dispatch(logout());
    window.location = '/login';
  };

  const Crecamp = () => {
    window.location = '/create-campaign';
  };

  const Createset = () => {
    window.location = '/my-profile';
  };

  return (
    <div className='map1'>
      <div className='navparent'>
        <nav className='nav1'>
          <NavLink to='/dashboard'>
            <img src='diyo.svg' className='navlogo' />
          </NavLink>

          <h3 className='navtitle'>Dashboard</h3>

          <div className='navsidemenu'>
            <span className='navtitleName' onClick={handleClick}>
              {' '}
              {userinfo?.firstName}
            </span>

            <React.Fragment>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              >
                <Tooltip title='Account settings'>
                  <IconButton
                    onClick={handleClick}
                    size='small'
                    sx={{ ml: 1 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar sx={{ width: 29, height: 32 }}>
                      <ExpandMore />
                    </Avatar>
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                anchorEl={anchorEl}
                id='account-menu'
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 29,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 8,
                      height: 8,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={Createset}>
                  <Person style={{ color: '#5E16EB', fontFamily: 'ProductSans' }} /> My Profile
                </MenuItem>

                <MenuItem onClick={onLogout}>
                  <Logout
                    style={{ color: '#5E16EB', fontFamily: 'ProductSans' }}
                    fontSize='small'
                  />
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          </div>
        </nav>

        <div className='nav2'>
          <ul className='navlist'>
            <NavLink
              to='/dashboard'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
                fontFamily: 'ProductSans',
              }}
            >
              Home
            </NavLink>
            <NavLink
              to='/campaign-list'
              style={{
                color: 'rgba(68, 68, 68, 0.51)',
                textDecoration: 'none',
                fontWeight: 400,
                fontSize: 15,
              }}
            >
              Campaign List
            </NavLink>
          </ul>

          <button onClick={Crecamp} className='crtcampbuttonD'>
            Create campaign
          </button>
        </div>
      </div>

      <div className='herosection'>
        <div className='formsidedash'>
          <div className='h2p'>
            <h1 style={{ color: '#5E16EB', marginBottom: 40, fontFamily: 'ProductSans' }}>
              Hello, {userinfo?.firstName}
            </h1>

            <div>
              {' '}
              <p className='pdash1'>Welcome to DIYO (do-it-yourself outdoor!) </p>
            </div>
          </div>
          <p className='p2'>
            {' '}
            Click the <span style={{ fontFamily: 'ProductSans' }}>
              &apos;Create Campaign&apos;
            </span>{' '}
            button below <br />
            to start your first out-of-home campaign.
          </p>

          <div className='btndashc'>
            <button className='crtcampbuttonD' onClick={Crecamp}>
              Create campaign
            </button>
          </div>
        </div>

        <div className='mapsidedash'>
          <Map
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={viewport}
            onMove={(evt) => {
              setViewport(evt.viewState);
            }}
            style={{ height: '100vh', width: '100vw', zIndex: 9 }}
            mapStyle='mapbox://styles/mapbox/streets-v12'
            ref={mapRef}
            maxZoom={1000}
            onZoomEnd={() => handleZoom()}
            onZoomStart={() => abortFetching()}
          >
            {points.map((item, index) => (
              <Marker key={`crime-${index}`} latitude={item.Point.Lat} longitude={item.Point.Long}>
                {item.Size > 1 ? (
                  <button
                    style={{
                      width: `${10 + item.size * 20}px`,
                      height: `${10 + item.size * 20}px`,
                    }}
                    className='cluster-marker'
                  >
                    {item.Size}
                  </button>
                ) : (
                  <MdLocationPin
                    size={30}
                    className='MDLocation'
                    color='#5E16EB'
                    style={{ height: '20px' }}
                    onMouseEnter={(e) => {
                      e.target.id = adUnit?.id;
                      setIsPopupOpen(true);
                      setSelectedPosition(item);
                      fetchLocationInfo(item.ID);
                    }}
                  />
                )}
              </Marker>
            ))}

            {isPopupOpen ? (
              <Popup
                latitude={selectedPosition.Point.Lat}
                longitude={selectedPosition.Point.Long}
                anchor='bottom'
                maxWidth='100%'
                // anchor="top"
                dynamicPosition={false}
                onClose={() => closePopup()}
                closeButton={false}
                closeOnClick={false}
              >
                <div
                  className='popup'
                  onMouseLeave={() => {
                    setIsPopupOpen(false);
                  }}
                  onMouseEnter={() => {
                    setIsPopupOpen(true);
                  }}
                >
                  {fetchingLocationInfo ? (
                    <div className=''>{/* <MapLoader /> */}</div>
                  ) : (
                    // Popup Card design
                    <div className='PopupCard'>
                      <div className='PopupCardinnercontent'>
                        {/* Logic goes here */}
                        <p className='PopupCardinnercontent-para1'>{adUnit.venue.name}</p>
                        <p className='PopupCardinnercontent-para1'>
                          Category ~{' '}
                          {adUnit.venue.openooh_category_display
                            .replace('_', ' ')
                            .replace('.', ' ')}
                        </p>

                        {/* <p className="PopupCardinnercontent-para2">{adUnit.venue.address.replaceAll(/[\_\.]/g, ' ')}</p> */}
                        <img className='adunitImage' src={adUnit.asset.imageUrl || 'diyo.svg'} />
                        <div className='ppcrdcnt'>
                          <div className='dimenaspec'>
                            <p>Type</p>
                            <h3>{adUnit.asset.category}</h3>
                          </div>

                          <div className='dimenaspec'>
                            <p>Dimensions</p>
                            <h3>
                              {adUnit.slot.height} by {adUnit.slot.width}
                            </h3>
                          </div>

                          <div className='dimenaspec'>
                            <p>Cost</p>
                            <h3>
                              {adUnit.planning.baseRate}
                              {adUnit.planning.currency}
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </Popup>
            ) : null}

            <GeolocateControl position='top-left' trackUserLocation />
          </Map>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
