import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import api from './Api/Indx';
import './Forgotpassword.css';
import LazyLoad from 'react-lazy-load';
import { Input } from 'antd';

function Forgotpassword() {
  const userRef = useRef();

  const [email, setEmail] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      let data = {};
      data = {
        email: email,
      };

      await api.triggerReset(data);
      window.location = '/reset-password';
      setEmail('');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <LazyLoad>
      <div className='fgRootContainer'>
        <div className='fgform-container'>
          <div className='fgformLeft'>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img src='diyo.svg' className='fgLogoV' />
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontWeight: 400,
                  fontFamily: 'ProductSans',
                  marginBottom: '30px',
                }}
              >
                Forgot your password?
              </p>
            </div>

            <form className='fgregform' onSubmit={handleSubmit}>
              <p style={{ fontSize: '12px', fontWeight: 400, color: '#000', marginBottom: '10px' }}>
                Enter the email you used to register with DIYO
              </p>
              <div>
                <Input
                  type='email'
                  className='fgfieldii'
                  placeholder='Enter your email'
                  id='username'
                  ref={userRef}
                  autoComplete='off'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
              </div>

              <button type='submit' className='fgsubmitButtonii'>
                Send verification email
              </button>
            </form>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p style={{ fontSize: '12px', fontWeight: 400, fontFamily: 'ProductSans' }}>
                <NavLink to='/login' style={{ textDecoration: 'none' }}>
                  <a
                    style={{
                      textDecoration: 'none',
                      color: '#5E16EB',
                      fontWeight: 400,
                      fontFamily: 'ProductSans',
                    }}
                  >
                    No Thanks
                  </a>
                </NavLink>
              </p>
            </div>
          </div>

          <div className='fgformRight'>
            <img src={'sgg.svg'} />
          </div>
        </div>
      </div>
    </LazyLoad>
  );
}

export default Forgotpassword;
