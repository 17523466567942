import BACKEND from '../../utils/backend';
const API_BASE = process.env.REACT_APP_API_URL;

const API = new BACKEND();
const Fetch = (to, useAlert = false) => API.send({ to, useAlert });

// const {cmpid} = useContext(CampaignContext);

export default {
  post: (to, payload, header, useAlert) =>
    API.send({ to, type: 'post', payload, header, useAlert }),
  getAddUnits(data) {
    return API.send({ to: '/adunits/count', type: 'post', payload: data });
  },
  getPublishers() {
    return Fetch('/adunits/publishers');
  },

  getLocationInfo(id) {
    return Fetch(`/adunits/${id}`);
  },

  listAdunits(id) {
    return API.send({ to: '/adunits', type: 'post', header: 'application/json', payload: id });
  },

  createCampaign(data) {
    return API.send({ to: '/campaigns', type: 'post', payload: data, useAlert: true });
  },

  updateCampaign({ id, data }) {
    return API.send({ to: `/campaigns/${id}`, type: 'put', payload: data, useAlert: true });
  },

  signupAccount(data) {
    return API.send({ to: '/accounts/signup', type: 'post', payload: data, useAlert: true });
  },

  LoginAccount(data) {
    return API.send({ to: '/accounts/login', type: 'post', payload: data });
  },

  // Gets impressions for radio option 2
  getExtimate(data) {
    return API.send({ to: '/campaigns/estimate', type: 'post', payload: data, useAlert: false });
  },

  fetchCampaigns() {
    return Fetch('/campaigns?page=1&limit=100');
  },

  triggerReset(data) {
    return API.send({
      to: '/accounts/password/reset/trigger',
      type: 'post',
      payload: data,
      useAlert: false,
    });
  },

  confirmReset(data) {
    return API.send({
      to: '/accounts/password/reset/confirm',
      type: 'post',
      payload: data,
      useAlert: false,
    });
  },

  getCampaign(id2) {
    return API.send({ to: `/campaigns/${id2}`, type: 'get' });
  },

  pauseCampaign(id) {
    return API.send({ to: `/campaigns/${id}/pause`, type: 'put' });
  },

  resumeCampaign(id) {
    return API.send({ to: `/campaigns/${id}/resume`, type: 'put' });
  },

  userProfile() {
    return Fetch(`/accounts/me`);
  },

  getReport(id, report) {
    return API.send({
      to: `/campaigns/${id}/report`,
      type: 'post',
      payload: report,
      useAlert: false,
    });
  },

  createStripePaymentIntent(data) {
    return API.send({ to: '/payments/stripe-payment-intent', type: 'post', payload: data });
  },

  applyPayment(cmpid, payref) {
    return API.send({ to: `/campaigns/${cmpid}/payments/apply`, type: 'post', payload: payref });
  },

  uploadImage() {
    return Fetch('/creatives/upload-url?kind=banner&extension=png');
  },

  getUploadUrl({ kind = 'banner', extension = 'png' } = {}) {
    return Fetch(`/creatives/upload-url?kind=${kind}&extension=${extension}`);
  },

  updateEmail(data) {
    return API.send({
      to: `/accounts/security/email/change-request`,
      type: 'put',
      header: 'application/json',
      payload: data,
      useAlert: false,
    });
  },

  confirmEmailupdate(data) {
    return API.send({
      to: `/accounts/security/email/change-confirm`,
      type: 'post',
      payload: data,
      useAlert: false,
    });
  },

  accountpasswordupdate(data) {
    return API.send({
      to: `/accounts/security/password/change`,
      type: 'put',
      header: 'application/json',
      payload: data,
      useAlert: false,
    });
  },

  onboard(data) {
    return API.send({
      to: `/accounts/onboard`,
      type: 'put',
      header: 'application/json',
      payload: data,
      useAlert: false,
    });
  },

  googleLogin(data) {
    return API.send({ to: `/accounts/login/social/google`, type: 'post', payload: data });
  },

  googleSignup(data) {
    return API.send({ to: `/accounts/signup`, type: 'post', payload: data });
  },

  requestRefundCampaign({ id }) {
    return API.send({ to: `/campaigns/${id}/request-refund`, type: 'get' });
  },

  refundCampaign({ id }) {
    return API.send({ to: `/campaigns/${id}/refund`, type: 'post' });
  },

  expendCampaignBudget({ id }) {
    return API.send({ to: `/campaigns/${id}/expend-budget`, type: 'put' });
  },

  getBillingMargin() {
    return fetch(`${API_BASE}/settings/margin`, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-Type': 'application/json',
      },
    });
  },
};
